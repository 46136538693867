import { Button, Card, Col, Image, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./ResponsiveBanner.scss"; // Custom CSS file for styling

const { Title, Paragraph } = Typography;

const ResponsiveBanner = ({
  initialBackgroundColor = "#fa8c16",
  initialHeading = "Special Sale!",
  initialDescription = "Get the best deals today. Limited time offer!",
  initialImages = ["/electronics.jpg", "/electronics.jpg"],
}) => {
  const [backgroundColor, setBackgroundColor] = useState(
    initialBackgroundColor
  );
  const [bannerHeading, setBannerHeading] = useState(initialHeading);
  const [bannerDescription, setBannerDescription] =
    useState(initialDescription);
  const [leftImage, setLeftImage] = useState(initialImages[0]);
  const [rightImage, setRightImage] = useState(initialImages[1]);

  useEffect(() => {
    // Function to randomly change background color
    const changeBackgroundColor = () => {
      const colors = ["#52c41a", "#fa8c16", "#13c2c2"];
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      setBackgroundColor(randomColor);
    };

    // Function to change the text automatically
    const changeText = () => {
      const headings = [
        "Special Sale!",
        "Hurry Up!",
        "Mega Discounts!",
        "Limited Time Offer!",
      ];
      const descriptions = [
        "Get the best deals today. Limited time offer!",
        "Offer ends soon. Don't miss out!",
        "Big savings on your favorite items!",
        "Huge discounts, grab them before they're gone!",
      ];

      const randomHeading =
        headings[Math.floor(Math.random() * headings.length)];
      const randomDescription =
        descriptions[Math.floor(Math.random() * descriptions.length)];

      setBannerHeading(randomHeading);
      setBannerDescription(randomDescription);
    };

    // Function to change images dynamically
    const changeImages = () => {
      const images = [
        "/automotive.jpg",
        "/furniture.jpg",
        "/babykids.jpg",
        "/food.jpg",
        "/beauty.jpg",
        "/electronics.jpg",
      ];
      const leftRandom = images[Math.floor(Math.random() * images.length)];
      const rightRandom = images[Math.floor(Math.random() * images.length)];
      setLeftImage(leftRandom);
      setRightImage(rightRandom);
    };

    // Interval for background color change, text change, and image change
    const colorInterval = setInterval(changeBackgroundColor, 5000); // Change color every 5 seconds
    const textInterval = setInterval(changeText, 6000); // Change text every 6 seconds
    const imageInterval = setInterval(changeImages, 4000); // Change images every 7 seconds

    // Cleanup intervals when the component is unmounted
    return () => {
      clearInterval(colorInterval);
      clearInterval(textInterval);
      clearInterval(imageInterval);
    };
  }, []);

  return (
    <Card
      style={{
        backgroundColor,
        padding: "0px 0",
        textAlign: "center",
        borderRadius: "8px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        position: "relative", // For absolute positioning of images
        backgroundImage: "url(distribution-center.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="responsive-banner"
    >
      <Row justify="center" align="middle">
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ position: "relative", minHeight: 300, overflow: "hidden" }}
        >
          {/* Left image */}
          <div
            style={{
              position: "absolute",
              left: "10px",
              overflow: "hidden",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Image
              src={leftImage}
              alt="Left Banner"
              className="banner-left-image"
            />
          </div>
          <div
            style={{
              padding: "0 20px",
              position: "absolute",
              overflow: "hidden",
              minHeight: "100%",
              width: "100%",
              zIndex: 1000,
            }}
          >
            {/* Banner Text */}
            <Title
              level={1}
              style={{
                color: "#fff",
                fontSize: "3rem",
                fontWeight: "700",
                letterSpacing: "2px",
              }}
            >
              {bannerHeading}
            </Title>
            <Paragraph
              style={{
                color: "#fff",
                fontSize: "1.25rem",
                lineHeight: "1.5",
                backdropFilter: "blur(1.5px)",
                padding: "10px",
                width: "100%",
                maxWidth: "500px",
                margin: "0 auto",
              }}
            >
              {bannerDescription}
            </Paragraph>
            <Button
              type="primary"
              size="large"
              style={{
                position: "absolute",
                bottom: 10,
                left: "50%",
                transform: "translateX(-50%)",
              }}
              onClick={() => {
                // Redirect to shop page
                //scroll to middle of the page
                window.scrollTo({
                  top: window.innerHeight,
                  behavior: "smooth",
                });
              }}
            >
              Shop Now
            </Button>
          </div>

          {/* Right image */}
          <div
            style={{
              position: "absolute",
              overflow: "hidden",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Image
              className="banner-right-image"
              src={rightImage}
              alt="Right Banner"
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default ResponsiveBanner;
