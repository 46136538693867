// src/components/SupplierLogin.js

import { Button, Divider, Form, Input, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { setCookie } from "./cookieUtils";
const SupplierLogin = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/marketplace/suppliers-login",
        values
      );
      setCookie("supplier-token", response.data.token);
      message.success("Login successful");
      window.location.href = "/supplier/dashboard";
    } catch (error) {
      message.error(error.response?.data?.message || "Login failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Logo />
      <Form
        className="login-form"
        name="supplier_login"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <h2>Supplier /Seller Login</h2>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please enter your email" }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Login
          </Button>
        </Form.Item>
        {/* Link to Forgot Password */}
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <Link to="/supplier/password-reset-request">Forgot Password?</Link>
        </div>
        <Divider>Or</Divider>
        <Link to="/supplier/signup">Create an account as a supplier</Link>{" "}
        {/* Link to Supplier Signup */}
        <Divider> or </Divider>
        <Link to="/buyer/login">Login as a buyer</Link>{" "}
        {/* Link to Buyer Login */}
        <Divider> or </Divider>
        <Link to="/">Home</Link> {/* Link to Home */}
      </Form>
    </>
  );
};

export default SupplierLogin;
