// cookieUtils.js

// Function to set a cookie
export function setCookie(name, value, days = 7, path = "/") {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000); // Set expiration time
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=${path};Secure;SameSite=Strict`;
}

// Function to get a cookie value by name
export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    let thedata = parts.pop().split(";").shift();
    if (thedata === "undefined") {
      return null;
    }
    return thedata;
  }
  return null;
}

// Function to delete a cookie
export function deleteCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
}

export function deteleAllCookies() {
  var allCookies = document.cookie.split(";");
  for (var i = 0; i < allCookies.length; i++)
    document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
}
