import React, { useState, useCallback } from "react";
import { Button, Input, Space, message } from "antd";
import { getCookie, setCookie, deleteCookie } from "./cookieUtils";

const CartButton = ({ product, showQuantity }) => {
  const [quantity, setQuantity] = useState(1); // Default quantity
  const [isAdding, setIsAdding] = useState(false); // State to track if adding is in progress

  const handleAddToCart = useCallback(() => {
    if (isAdding) return; // Prevent adding if already in progress
    if (quantity <= 0) {
      message.error("Quantity must be at least 1.");
      return;
    }

    const addToCart = () => {
      try {
        let cartItems = [];
        try {
          cartItems = JSON.parse(getCookie("cartItems")) || [];
        } catch (error) {
          console.error("Error parsing cart items", error);
          deleteCookie("cartItems");
        }

        const existingCartItems = cartItems.filter(
          (f) => f.product_id === product.product_id
        );
        console.log("existingCartItems", existingCartItems);
        console.log("cartItems", cartItems);

        if (existingCartItems.length > 0) {
          // If product already exists in the cart
          const updatedItems = cartItems.map((item) => {
            if (item.product_id === product.product_id) {
              return { ...item, quantity: item.quantity + quantity };
            }
            return item;
          });
          cartItems = updatedItems;
        } else {
          // delete product.images; // Remove image from product object
          delete product.attributes; // Remove image from product object
          cartItems.push({ ...product, quantity });
        }
        // Save updated cart items to local storage
        setCookie("cartItems", JSON.stringify(cartItems));
        message.success(`${quantity} of ${product.name} added to cart!`);
      } catch (error) {
        console.error("Error adding to cart:", error);
        message.error("Failed to add to cart. Please try again.");
        //if json parse fails, delete the cookie
        if (error.message === "Unexpected token < in JSON at position 0") {
          deleteCookie("cartItems");
        }
      }
    };
    setIsAdding(true); // Set adding state
    // Add product to the cart using context
    addToCart();

    setIsAdding(false); // Reset adding state
  }, [isAdding, quantity, product]);

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  return (
    !getCookie("supplier-token") && (
      <Space>
        {showQuantity && (
          <Space style={{ marginTop: "20px" }}>
            <Button onClick={decreaseQuantity}>-</Button>
            <Input
              type="number"
              min={1}
              value={quantity}
              readOnly
              style={{ width: "60px", textAlign: "center" }}
            />
            <Button onClick={increaseQuantity}>+</Button>
          </Space>
        )}
        <Button
          type="primary"
          onClick={handleAddToCart}
          className="add-to-cart-button"
          disabled={isAdding} // Disable button while adding
        >
          {isAdding ? "Adding..." : "Add to Cart"}
        </Button>
      </Space>
    )
  );
};

export default CartButton;
