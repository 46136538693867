import React from "react";
import { Col, Collapse, Row } from "antd";

const { Panel } = Collapse;

const faqs = [
  {
    question: "What is QikMarket?",
    answer:
      "QikMarket is an online marketplace where buyers can purchase goods from local suppliers and have them delivered by QikTruck vehicles.",
  },
  {
    question: "How do I sign up as a supplier?",
    answer:
      "Click on the 'Sign Up' button on the homepage and select 'Supplier' to create an account. Fill out your details and start listing your products.",
  },
  {
    question: "How do I place an order?",
    answer:
      "Browse products on QikMarket, add items to your cart, and proceed to checkout to place your order.",
  },
  {
    question: "What are the delivery charges?",
    answer:
      "Delivery charges depend on the distance and vehicle type used for delivery. QikMarket calculates this automatically during checkout.",
  },
  {
    question: "Can I track my delivery?",
    answer:
      "Yes, once your order is confirmed, you will receive a tracking link to monitor your delivery in real-time.",
  },
  {
    question: "What payment methods are available?",
    answer:
      "We accept credit/debit cards, mobile payments, and bank transfers.",
  },
  {
    question: "How do I add products to QikMarket?",
    answer:
      "After logging in as a supplier, navigate to the 'Add Product' section, fill out the required details, and upload product images.",
  },
  {
    question: "Are there any listing fees for suppliers?",
    answer:
      "No, listing your products on QikMarket is free. We only charge a commission on each sale.",
  },
  {
    question: "What should I do if my order is delayed?",
    answer:
      "Contact customer support through the 'Help' section or call our support hotline for immediate assistance.",
  },
  {
    question: "Can buyers return or exchange products?",
    answer:
      "Return and exchange policies depend on the supplier. Check the product details for specific policies.",
  },
  {
    question: "How does QikMarket ensure product quality?",
    answer:
      "We vet suppliers thoroughly and encourage buyers to leave reviews to maintain high product standards.",
  },
  {
    question: "What types of vehicles are used for delivery?",
    answer:
      "Deliveries are made using QikTruck vehicles, ranging from minivans to 16-ton trucks, based on the order size.",
  },
  {
    question: "Is same-day delivery available?",
    answer:
      "Yes, same-day delivery is available for orders placed within the designated time and distance limits.",
  },
  {
    question: "How can I contact a supplier directly?",
    answer:
      "QikMarket does not allow direct supplier contact. Use the platform's messaging system for any queries.",
  },
  {
    question: "Can I schedule a delivery for a specific time?",
    answer:
      "Yes, you can choose a preferred delivery time during checkout if the supplier offers this option.",
  },
  {
    question: "How do I update my account details?",
    answer:
      "Log in to your account, navigate to the 'Profile' section, and update your details as needed.",
  },
  {
    question: "Are there bulk discounts available?",
    answer:
      "Some suppliers offer bulk discounts. Check the product details or contact the supplier through QikMarket for more information.",
  },
  {
    question: "How can I promote my products on QikMarket?",
    answer:
      "Suppliers can use promotional tools on the platform to highlight their products and increase visibility.",
  },
  {
    question: "What happens if a product is out of stock?",
    answer:
      "If a product is out of stock, it will be marked as unavailable, and you won’t be able to add it to your cart.",
  },
  {
    question: "How do I leave a review for a product or supplier?",
    answer:
      "After receiving your order, go to the 'Order History' section, select the order, and leave your review.",
  },
];

const FAQSection = () => {
  return (
    <Row justify="center">
      <Col xs={24} md={12}>
        <h1>Frequently Asked Questions</h1>
        <Collapse accordion>
          {faqs.map((faq, index) => (
            <Panel header={faq.question} key={index}>
              <p>{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
      </Col>
    </Row>
  );
};

export default FAQSection;
