// src/components/BuyerPasswordResetRequest.js

import React, { useState } from "react";
import { Form, Input, Button, message, Divider } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import Logo from "./Logo";

const BuyerPasswordResetRequest = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-request-password-reset`,
        values
      );
      message.success(response.data.message, 10);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to send password reset link.";
      message.error(errorMessage);
      setLoading(false);
    }
  };

  return (
    <>
      <Logo />
      <Form
        name="supplier_password_reset_request"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="password-reset-form"
        style={{ maxWidth: "400px", margin: "0 auto", padding: "20px" }}
      >
        <h1>Supplier Password Reset</h1>
        <p>Enter your email address below to receive a password reset link.</p>
        <Form.Item
          label="Supplier Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email address." },
            { type: "email", message: "Please enter a valid email address." },
          ]}
        >
          <Input
            placeholder="Enter your supplier email"
            onChange={() => {
              setLoading(false);
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading} block>
            Send Reset Link
          </Button>
          {loading && (
            <p style={{ textAlign: "center", marginTop: "10px" }}>
              Check your email for the reset link...
            </p>
          )}
        </Form.Item>
        <Divider />
        <Link to="/supplier/login">Back to Login</Link>
      </Form>
    </>
  );
};

export default BuyerPasswordResetRequest;
