// src/components/SupplierSignup.js

import { Button, Card, Col, Divider, Form, Input, message, Row } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useState } from "react";
import AddressAutocomplete from "./AddressAutocomplete";
import Logo from "./Logo";
import { Link } from "react-router-dom";

const SupplierSignup = () => {
  const showsupplierintro = true;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onSelectAddress = ({ address, lat, lng }) => {
    form.setFieldsValue({ address, lat, lng });
  };
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/suppliers-signup`,
        values
      );
      message.success(response.data.message);
      window.location.href = "/supplier/login";
    } catch (error) {
      message.error(error.response?.data?.message || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showsupplierintro && (
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <h3>Sell More on QikMarket</h3>
            <p>
              {/* trademarkcopyright */}
              <b>QikMarket</b> is a free local marketplace that connects you
              with buyers in your area. <b>Start selling today</b> and grow your
              business with us!
            </p>
            <Card>
              <ul className="supplier-benefits-pointers">
                <li>
                  <CheckCircleOutlined
                    style={{ color: "green", marginRight: 10 }}
                  />
                  Reach a wider local audience
                </li>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "green", marginRight: 10 }}
                  />
                  Grow your business
                </li>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "green", marginRight: 10 }}
                  />
                  Get paid instantly
                </li>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "green", marginRight: 10 }}
                  />
                  Local support
                </li>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "green", marginRight: 10 }}
                  />
                  No listing fees
                </li>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "green", marginRight: 10 }}
                  />
                  0% commissions on sales
                </li>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "green", marginRight: 10 }}
                  />
                  Free to join
                </li>
              </ul>
            </Card>
            <br />
            <br />
            <img
              style={{
                width: "100%",
                borderRadius: "8px",
                marginBottom: "20px",
                maxWidth: "600px",
              }}
              src="/distribution-center.jpg"
              alt="Distribution Center"
            />
          </Col>
        </Row>
      )}
      <Logo />
      <Form
        name="supplier_signup"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="login-form"
      >
        <h2>Supplier /Seller Registration</h2>
        <Form.Item
          label="Store Name"
          name="businessName"
          rules={[
            { required: true, message: "Please enter your business name" },
          ]}
        >
          <Input placeholder="Enter your business name" />
        </Form.Item>
        <Form.Item
          label="Contact Person"
          name="contactPerson"
          rules={[
            { required: true, message: "Please enter the contact person name" },
          ]}
        >
          <Input placeholder="Enter contact person name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            { required: true, message: "Please enter your phone number" },
          ]}
        >
          <Input placeholder="Enter your phone number" />
        </Form.Item>
        <Form.Item
          label="Business Address"
          name="address"
          rules={[{ required: true, message: "Please enter your address" }]}
        >
          <AddressAutocomplete onSelectAddress={onSelectAddress} />
        </Form.Item>
        <Form.Item label="Referral code (optional)" name="promocode">
          <Input placeholder="Enter a referral code" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password placeholder="Enter a secure password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Register
          </Button>
        </Form.Item>
        <Divider> or </Divider>
        <Link to="/supplier/login">Login as a supplier</Link>{" "}
        {/* Link to Supplier Login */}
        <Divider> or </Divider>
        <Link to="/buyer/login">Login as a buyer</Link>{" "}
        {/* Link to Buyer Login */}
        <Divider> or </Divider>
        <Link to="/">Home</Link> {/* Link to Home */}
      </Form>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          <h3>Why Sell on QikMarket</h3>
          <p>
            QikMarket is powered by local suppliers or sellers like you. We help
            you reach a wider audience and grow your business.
          </p>
          <Button type="primary" className="orangebg">
            <Link to="/supplier/signup">Sign Up as a Seller</Link>
          </Button>
          <br />
          <br />
          <img
            style={{
              width: "100%",
              borderRadius: "8px",
              marginBottom: "20px",
              maxWidth: "800px",
            }}
            src="/distribution-center2.jpeg"
            alt="Distribution Center"
          />
        </Col>
      </Row>
    </>
  );
};

export default SupplierSignup;
