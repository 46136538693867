import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({
  title = "QikMarket online shopping",
  description = "Buy and Sell Locally",
  keywords = "online shopping, marketplace",
  canonical = "",
  lang = "en",
  meta = [],
}) => {
  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {canonical && <link rel="canonical" href={canonical} />}
      {meta.map(({ name, content }, index) => (
        <meta key={index} name={name} content={content} />
      ))}
    </Helmet>
  );
};

export default SEO;
