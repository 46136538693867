import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddressAutocomplete from "./AddressAutocomplete"; // Import the AddressAutocomplete component
import { getCookie } from "./cookieUtils";

const { Option } = Select;

const SupplierEditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [address, setAddress] = useState({
    address: "",
    lat: "",
    lng: "",
  });
  const [supplierData, setSupplierData] = useState({
    business_name: "",
    contact_person: "",
    email: "",
    phone: "",
    address: "",
    lat: "",
    lng: "",
    business_type: "",
    business_description: "",
    business_logo_url: "",
    business_cover_image_url: "",
    business_registration_number: "",
    expectedmonthly_sales: "",
    vat_number: "",
    website_url: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    otherlink: "",
    delivery_time_estimates: "",
    regions_served: [],
    preferred_payment_methods: [],
    product_catalog: [],
    supplier_id: "",
  });

  useEffect(() => {
    const fetchSupplierProfile = async () => {
      setLoading(true);
      try {
        if (!getCookie("supplier-token")) {
          throw new Error("Supplier token not found.");
        }
        console.log("Fetching supplier profile...");
        const token = getCookie("supplier-token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-profile`,
          {
            headers: { Authorization: token },
          }
        );
        if (!response.data.supplier_id) {
          throw new Error("Supplier ID not found.");
        }
        let data = response.data;
        // delete data.business_registration_date;
        setSupplierData(data);
        setAddress({
          address: data.address,
          lat: data.lat,
          lng: data.lng,
        });
        form.setFieldsValue({ ...data });
      } catch (error) {
        console.error("Error fetching supplier profile:", error);
        message.error("Failed to fetch profile data.");
      } finally {
        setLoading(false);
      }
    };

    fetchSupplierProfile();
  }, [form]);

  const handleUpdateProfile = async (values) => {
    try {
      // console.log("Updating profile with values:", values);
      // console.log({
      //   ...values,
      //   ...address,
      // });
      // return;
      // setLoading(true);
      const token = getCookie("supplier-token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-profile`,
        {
          ...values,
          ...address,
          regions_served: values.regions_served || [], // Ensure it’s an array
          preferred_payment_methods: values.preferred_payment_methods || [], // Ensure it’s an array
          supplier_id: supplierData.supplier_id, // Ensure supplier_id matches
        },
        {
          headers: { Authorization: token },
        }
      );
      message.success(response.data.message);
      // window.location.reload();
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile.");
      // setLoading(false);
    }
  };

  const onSelectAddress = ({ address, lat, lng }) => {
    setAddress({ address, lat, lng });
    form.setFieldsValue({ address, lat, lng });
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div style={{ padding: "5px", maxWidth: "800px", margin: "auto" }}>
      <h2>Edit Supplier Profile</h2>

      <Form
        name="edit-profile"
        form={form}
        layout="vertical"
        onFinish={handleUpdateProfile}
        initialValues={() => {
          console.log("Initial values:", supplierData);
          return {
            ...supplierData,
            regions_served: supplierData.regions_served.map(
              (region) => region.name
            ),
          };
        }}
      >
        {/* Group 1: Business Info */}
        <Card
          bordered
          title="Business Information"
          style={{ marginBottom: "20px" }}
        >
          <Row gutter={16}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label="Business Name"
                name="business_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your business name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label="Business Type"
                name="business_type"
                rules={[
                  {
                    required: false,
                    message: "Please input your business type!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label="Business Registration Number"
                name="business_registration_number"
                rules={[
                  {
                    required: false,
                    message: "Please input your business registration number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label="VAT Number"
                name="vat_number"
                rules={[
                  { required: false, message: "Please input your VAT number!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>{" "}
          {"Current Address: " + supplierData.address}
          <AddressAutocomplete onSelectAddress={onSelectAddress} />
          <br />
          <Form.Item label="Business Description" name="business_description">
            <Input.TextArea />
          </Form.Item>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label="Contact Person"
              name="contact_person"
              rules={[
                {
                  required: true,
                  message: "Please input your contact person!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Form.Item label="Business Logo URL" name="business_logo_url">
            <Input />
          </Form.Item>
          <Form.Item
            label="Business Cover Image URL"
            name="business_cover_image_url"
          >
            <Input />
          </Form.Item>
        </Card>

        {/* Group 2: Contact & Social Media Links */}
        <Card
          bordered
          title="Contact and Social Media"
          style={{ marginBottom: "20px" }}
        >
          <Row gutter={16}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Website URL" name="website_url">
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item label="Facebook" name="facebook">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item label="Twitter" name="twitter">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item label="Instagram" name="instagram">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item label="LinkedIn" name="linkedin">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Other Link" name="otherlink">
            <Input />
          </Form.Item>
        </Card>

        {/* Group 3: Delivery & Payment Info */}
        <Card
          bordered
          title="Delivery and Payment"
          style={{ marginBottom: "20px" }}
        >
          <Form.Item
            label="Expected Monthly Sales"
            name="expectedmonthly_sales"
            rules={[
              {
                required: true,
                message: "Please input your expected monthly sales!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Delivery Time Estimates"
            name="delivery_time_estimates"
          >
            <Select>
              <Option value="1-2 days">1-2 days</Option>
              <Option value="3-5 days">3-5 days</Option>
              <Option value="5-7 days">5-7 days</Option>
              <Option value="7-10 days">7-10 days</Option>
              <Option value="10-14 days">10-14 days</Option>
              <Option value="14-21 days">14-21 days</Option>
              <Option value="21-30 days">21-30 days</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Regions Served" name="regions_served">
            <Select mode="multiple">
              <Option value="gauteng">Gauteng</Option>
              <Option value="western-cape">Western Cape</Option>
              <Option value="eastern-cape">Eastern Cape</Option>
              <Option value="kwazulu-natal">Kwazulu Natal</Option>
              <Option value="mpumalanga">Mpumalanga</Option>
              <Option value="limpopo">Limpopo</Option>
              <Option value="north-west">North West</Option>
              <Option value="free-state">Free State</Option>
              <Option value="northern-cape">Northern Cape</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Preferred Payment Methods"
            name="preferred_payment_methods"
          >
            <Select mode="multiple">
              <Option value="cash-on-delivery">Cash on Delivery</Option>
              <Option value="bank-card-on-delivery">
                Bank Card on Delivery
              </Option>
              <Option value="bank-transfer-on-delivery">
                Bank Transfer on Delivery
              </Option>
              <Option value="bank-transfer-online">Bank Transfer Online</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Describe your business" name="product_catalog">
            <Input.TextArea />
          </Form.Item>
        </Card>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SupplierEditProfile;
