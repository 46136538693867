// BulkUpload.jsx

import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Input,
  message,
  Modal,
  Progress,
  Space,
  Table,
  Typography,
  Upload,
} from "antd";
import axios from "axios";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { getCookie } from "./cookieUtils";

const { Title } = Typography;
const { confirm } = Modal;
const { Panel } = Collapse;

/**
 * Configuration object to define required fields and their data types.
 * This allows flexibility for developers to adjust field requirements.
 */
const FIELD_CONFIG = {
  sku: {
    required: true,
    type: "string",
    description: "The unique identifier for the product.",
  },
  name: {
    required: true,
    type: "string",
    description: "The name of the product.",
  },
  description: {
    required: true,
    type: "string",
    description: "A detailed description of the product.",
  },
  category_name: {
    required: true,
    type: "category",
    description: "The category under which the product is listed.",
  },
  price: {
    required: true,
    type: "number",
    description:
      "The price of the product in the specified currency. Do not include currency symbol. Do not include commas. Do not include spaces.",
  },
  weight: {
    required: true,
    type: "number",
    description:
      "The weight of the product, in kilograms. (KG), e.g., 0.5 for 500g or 0.5kg. 10 for 10kg.",
  },
  length: {
    required: true,
    type: "number",
    description: "The length of the product in centimeters. (cm)",
  },
  width: {
    required: true,
    type: "number",
    description: "The width of the product in centimeters (cm).",
  },
  height: {
    required: true,
    type: "number",
    description: "The height of the product in centimeters (cm).",
  },
  stock: {
    required: true,
    type: "number",
    description: "The quantity of the product available in stock.",
  },
  address: {
    required: true,
    type: "string",
    description:
      "The address of the supplier or warehouse where the product is stored.",
  },
  latitude: {
    required: true,
    type: "number",
    description: "The latitude coordinate of the product's location.",
  },
  longitude: {
    required: true,
    type: "number",
    description: "The longitude coordinate of the product's location.",
  },
  lead_time: {
    required: true,
    type: "number",
    description:
      "The number of days required to prepare the product for delivery.",
  },
  published_status: {
    required: true,
    type: "productstatus",
    description:
      "The publication status of the product (e.g., published, draft).",
  },
  tags: {
    required: false,
    type: "csv",
    description: "Comma-separated tags associated with the product.",
  },
  colorhex: {
    required: false,
    type: "string",
    description:
      "The hexadecimal color code representing the product's color. (e.g., #FF0000) for red. or #000000 for black.",
  },
  currency: {
    required: false,
    type: "string",
    description:
      "The currency code for the product's price (e.g., USD, ZAR). Default is ZAR.",
  },
  sale_price: {
    required: false,
    type: "number",
    description: "The discounted sale price of the product.",
  },
  reorder_point: {
    required: false,
    type: "number",
    description:
      "The stock level at which new orders should be placed to restock.",
  },
  max_delivery_radius: {
    required: false,
    type: "number",
    description: "The maximum delivery radius for the product in kilometers.",
  },
  handling_time: {
    required: false,
    type: "number",
    description:
      "The time required to handle and prepare the product for shipping.",
  },
  local_availability: {
    required: false,
    type: "boolean",
    description: "Indicates if the product is available for local delivery.",
  },

  bulk_discount_quantity: {
    required: false,
    type: "number",
    description:
      "The minimum quantity required to qualify for a bulk discount.",
  },
  bulk_discount_amount: {
    required: false,
    type: "number",
    description: "The discount amount applied for bulk purchases.",
  },
  promo_start: {
    required: false,
    type: "string",
    description: "The start date of a promotional period (ISO date format).",
  },
  promo_end: {
    required: false,
    type: "string",
    description: "The end date of a promotional period (ISO date format).",
  },
  delivery_instructions: {
    required: false,
    type: "string",
    description: "Special instructions for delivering the product.",
  },
};

// List of fields to exclude from the payload
const EXCLUDED_FIELDS = [
  "product_id",
  "date_added",
  "supplier_id",
  "warehouse_id",
];

const BulkUpload = () => {
  const [fileList, setFileList] = useState([]);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [parsingProgress, setParsingProgress] = useState(0);
  const [submissionProgress, setSubmissionProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [allcategories, setAllCategories] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  useEffect(() => {
    fetchCategories();
  }, []);

  // Define the columns for the Ant Design Table
  const columns = [
    {
      title: "Row",
      dataIndex: "key",
      key: "key",
      render: (_, __, index) => index + 1,
    },

    {
      title: "Errors",
      dataIndex: "errors",
      key: "errors",
      render: (_, record) => {
        const recordErrors = errors[record.key];
        return recordErrors ? (
          <ul style={{ paddingLeft: "20px", margin: 0 }}>
            {recordErrors.map((err, idx) => (
              <li key={idx} style={{ color: "red" }}>
                {/* row index */}
                {err}
              </li>
            ))}
          </ul>
        ) : null;
      },
    },
    ...Object.keys(FIELD_CONFIG).map((field) => ({
      title: field.charAt(0).toUpperCase() + field.slice(1).replace("_", " "),
      dataIndex: field,
      key: field,
      render: (text, record) => renderEditableCell(text, record, field),
    })),
  ];

  /**
   * Function to render editable cells using Ant Design's Input component.
   */
  const renderEditableCell = (text, record, field) => {
    const isError = errors[record.key]?.some((err) =>
      err.toLowerCase()?.includes(`#${field}#`)
    );
    return (
      <Input
        value={text}
        onChange={(e) => handleCellEdit(record.key, field, e.target.value)}
        className={"editable-cell " + (isError ? " editable-cell-error" : "")}
        placeholder={`Enter #${field}#`}
      />
    );
  };
  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          "/marketplace/get-categories-with-products"
      );
      setAllCategories(response.data.allcategories);
    } catch (error) {
      console.error("Error fetching categories", error);
    } finally {
      setLoading(false);
    }
  };
  /**
   * Helper function to validate individual fields based on FIELD_CONFIG.
   */
  const validateField = (field, value) => {
    const config = FIELD_CONFIG[field];
    if (!config) return null; // No validation for unspecified fields

    if (config.required) {
      if (value === null || value === undefined || value === "") {
        return `#${field}# is required`;
      }
    }

    if (value !== null && value !== undefined && value !== "") {
      switch (config.type) {
        case "string":
          if (typeof value !== "string") {
            return `#${field}# must be a string`;
          }
          break;
        case "productstatus":
          if (value !== "published" && value !== "draft") {
            return `#${field}# must be a valid product status (published or draft)`;
          }
          break;
        case "category":
          if (!allcategories.find((category) => category.name === value)) {
            return `#${field}# must be a valid category`;
          }
          break;
        case "number":
          let num = value;
          try {
            num = parseFloat(value);
          } catch {
            return `#${field}# must be a number..`;
          }
          if (isNaN(Number(num))) {
            return `#${field}# must be a number`;
          }
          break;
        case "boolean":
          if (
            typeof value !== "boolean" &&
            !(
              typeof value === "string" &&
              (value.toLowerCase() === "true" ||
                value.toLowerCase() === "false")
            )
          ) {
            return `#${field}# must be a boolean`;
          }
          break;
        case "array":
          if (!Array.isArray(value)) {
            return `#${field}# must be an array`;
          }
          break;
        case "csv":
          if (
            !value.split(",").every((v) => {
              // Max length of 255 characters and no commas, and non alphanumeric characters not allowed
              return (
                v.trim().length > 0 &&
                v.trim().length <= 255 &&
                !v.includes(",") &&
                /^[a-zA-Z0-9_.]*$/.test(v)
              );
            })
          ) {
            return `#${field}# must be comma separated values like tagone,tagtwo,tagthree`;
          }
          break;
        case "object":
          try {
            if (typeof value !== "object") {
              JSON.parse(value);
            }
          } catch {
            return `#${field}# must be a valid JSON object`;
          }
          break;
        case "date":
          if (isNaN(Date.parse(value))) {
            return `#${field}# must be a valid date`;
          }
          break;
        default:
          return null;
      }
    }

    return null;
  };

  /**
   * Validate data based on FIELD_CONFIG.
   */
  const validateData = (dataToValidate) => {
    const validationErrors = {};

    dataToValidate.forEach((row) => {
      const rowErrors = [];
      Object.entries(FIELD_CONFIG).forEach(([field, config]) => {
        const value = row[field];
        const error = validateField(field, value);
        if (error) {
          rowErrors.push(error);
        }
      });

      if (rowErrors.length > 0) {
        validationErrors[row.key] = rowErrors;
      }
    });

    setErrors(validationErrors);
  };

  /**
   * Handle cell edits by updating the data and re-validating the row.
   */
  const handleCellEdit = (key, field, value) => {
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      item[field] = value;
      newData.splice(index, 1, { ...item });
      setData(newData);
      validateData(newData);
    }
  };

  /**
   * Handle file upload before it gets added to the upload list.
   */
  const beforeUpload = (file) => {
    const isSupported =
      file.type === "text/csv" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";

    if (!isSupported) {
      message.error("You can only upload CSV or Excel files!");
      return Upload.LIST_IGNORE;
    }

    return false; // Prevent automatic upload
  };

  /**
   * Handle changes in the Upload component.
   */
  const handleChange = (info) => {
    const { file } = info;
    if (file.status === "removed") {
      resetAll();
      return;
    }

    if (
      file.status === "done" ||
      file.status === "uploading" ||
      file.status === "error"
    ) {
      // Do nothing
      return;
    }

    // Process the uploaded file
    processFile(file);
  };

  /**
   * Reset all states to their initial values.
   */
  const resetAll = () => {
    setFileList([]);
    setData([]);
    setErrors({});
    setParsingProgress(0);
    setSubmissionProgress(0);
    setLoading(false);
  };

  /**
   * Process the uploaded file by reading and parsing its content.
   */
  const processFile = (file) => {
    setFileList([file]);
    setLoading(true);
    setData([]);
    setErrors({});
    setParsingProgress(0);
    setUploadError(null);
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const fileExt = file.name.split(".").pop().toLowerCase();

      if (fileExt === "csv") {
        parseCSV(binaryStr);
      } else if (fileExt === "xlsx" || fileExt === "xls") {
        parseExcel(binaryStr);
      } else {
        message.error("Unsupported file format!");
        setLoading(false);
      }
    };

    reader.onerror = () => {
      message.error("Failed to read file!");
      setLoading(false);
    };

    if (file.type === "text/csv") {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };

  /**
   * Parse CSV file using Papa.parse.
   */
  const parseCSV = (data) => {
    Papa.parse(data, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.data.length > 250) {
          message.warning("Maximum of 250 rows can be uploaded.");
          result.data = result.data.slice(0, 250);
        }
        setParsingProgress(100);
        handleParsedData(result.data);
      },
      error: (error) => {
        message.error(`CSV Parsing Error: ${error.message}`);
        setLoading(false);
      },
    });
  };

  /**
   * Parse Excel file using xlsx.
   */
  const parseExcel = (data) => {
    try {
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

      if (jsonData.length > 250) {
        message.warning("Maximum of 250 rows can be uploaded.");
        jsonData.splice(250); // Keep only the first 250 rows
      }

      setParsingProgress(100);
      handleParsedData(jsonData);
    } catch (error) {
      message.error(`Excel Parsing Error: ${error.message}`);
      setLoading(false);
    }
  };

  /**
   * Handle parsed data by assigning unique keys and setting state.
   */
  const handleParsedData = (parsedData) => {
    // Assign unique keys and exclude unwanted fields
    const dataWithKeys = parsedData.map((item, index) => ({
      key: `${item.sku || `row-${index}`}-${index}`,
      ...filterPayloadFields(item),
    }));

    setData(dataWithKeys);
    validateData(dataWithKeys);
    setLoading(false);
  };

  /**
   * Filter out fields that should be excluded from the payload.
   */
  const filterPayloadFields = (item) => {
    const filtered = {};
    Object.keys(item).forEach((key) => {
      if (!EXCLUDED_FIELDS.includes(key.toLowerCase())) {
        filtered[key.toLowerCase()] = item[key];
      }
    });
    return filtered;
  };

  /**
   * Handle data submission to the API.
   */
  const handleSubmit = async () => {
    const validData = data.filter((row) => !errors[row.key]);

    if (validData.length === 0) {
      message.error("No valid data to submit!");
      return;
    }

    console.log(validData);
    const payload = {
      products: validData.map((row) => {
        const filteredRow = {};
        Object.keys(row).forEach((field) => {
          if (!EXCLUDED_FIELDS.includes(field) && FIELD_CONFIG[field]) {
            let value = row[field];
            const config = FIELD_CONFIG[field];
            if (config.type === "number") {
              value = Number(value);
            } else if (config.type === "boolean") {
              value =
                typeof value === "boolean"
                  ? value
                  : value.toLowerCase() === "true"
                  ? true
                  : false;
            } else if (config.type === "csv") {
              value = value.split(",").map((v) => v.trim());
            } else if (config.type === "object") {
              try {
                value = JSON.parse(value);
              } catch (e) {
                value = {};
              }
            }
            filteredRow[field] = value;
          }
        });
        return {
          ...filteredRow,
          category_id: allcategories.find(
            (category) => category.name === row.category_name
          ).category_id,
          dimensions: {
            height: row.height,
            width: row.width,
            length: row.length,
          },
          bulk_discount: {
            quantity: row.bulk_discount_quantity,
            amount: row.bulk_discount_amount,
          },
          attributes: {
            colorhex: row.colorhex,
          },
        };
      }),
    };
    try {
      setLoading(true);
      setSubmissionProgress(0);

      // Make the POST request to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-add-products-bulk`,
        payload,
        {
          headers: {
            Authorization: getCookie("supplier-token"),
            "Content-Type": "application/json",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setSubmissionProgress(percentCompleted);
          },
        }
      );

      if (response.data.status === "success") {
        message.success(response.data.message, 10);
        // Optionally, you can reset the form or update the UI accordingly
        resetAll();
        setTimeout(() => {
          window.location.href = "/supplier/products";
        }, 2000);
      } else {
        message.error(response.data.message || "Failed to upload products.");
        setUploadError(response.data.error);
        console.error("Upload Error:", response.data.error);
      }
    } catch (error) {
      console.error("Upload Error:", error);
      message.error(
        error.response?.data?.message || "An error occurred during upload."
      );
      setUploadError(error.response?.data?.error);
    } finally {
      setLoading(false);
      setSubmissionProgress(0);
    }
  };

  /**
   * Allow re-uploading the file by resetting all states.
   */
  const handleReupload = () => {
    resetAll();
  };

  /**
   * Render the component.
   */
  return (
    <div style={{ padding: "5px", maxWidth: "1400px", margin: "0 auto" }}>
      <Title level={2}>Bulk Product Upload</Title>
      <Card style={{ marginBottom: "20px" }}>
        <p>
          Upload your CSV or Excel file to add multiple products at once. Ensure
          all required fields are present and the file follows the specified
          format.
        </p>
        <p>
          Required fields: <b>Name</b>, <b>Description</b>, <b>SKU</b>,{" "}
          <b>Price</b>, <b>Stock</b>
        </p>
        <p>Note: Maximum of 250 rows can be uploaded at once.</p>
        <Button
          onClick={() => {
            Modal.info({
              title: "Categories",
              content: (
                <div>
                  {allcategories.map((category) => (
                    <p key={category.category_id}>{category.name}</p>
                  ))}
                </div>
              ),
              onOk() {},
            });
          }}
          icon={<DownloadOutlined />}
        >
          View categories
        </Button>
        <br />
        <br />
        <a href="/product_upload_template.csv" download>
          {" "}
          Download Template CSV,
          <br /> Click here to download the template CSV file.
        </a>
      </Card>
      <Card style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Collapse defaultActiveKey={["0"]}>
          <Panel header="Data Guidelines" key="2">
            <Table
              columns={[
                {
                  title: "Field",
                  dataIndex: "field",
                  key: "field",
                },
                {
                  title: "Type",
                  dataIndex: "type",
                  key: "type",
                },
                {
                  title: "Required",
                  dataIndex: "required",
                  key: "required",
                  render: (text) =>
                    text ? <span style={{ color: "red" }}>✅</span> : "❌",
                },
                {
                  title: "Description",
                  dataIndex: "description",
                  key: "description",
                },
              ]}
              dataSource={Object.entries(FIELD_CONFIG).map(
                ([field, config]) => ({
                  field,
                  type: config.type,
                  required: config.required,
                  description: config.description,
                })
              )}
              pagination={false}
              bordered
              scroll={{ x: "max-content" }}
            />
          </Panel>
        </Collapse>
      </Card>
      <Card>
        <Upload
          multiple={false}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          beforeUpload={beforeUpload}
          onChange={handleChange}
          fileList={fileList}
          onRemove={resetAll}
          showUploadList={{ showRemoveIcon: true }}
          listType="text"
        >
          <Button className="orangebg floatright" icon={<UploadOutlined />}>
            Click to Upload
          </Button>
        </Upload>

        {loading && parsingProgress < 100 && (
          <Progress
            percent={parsingProgress}
            status="active"
            style={{ marginTop: "20px" }}
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
          />
        )}
      </Card>

      {Object.keys(errors).length > 0 && (
        <Card style={{ marginTop: "20px" }}>
          <Alert
            message="Validation Errors"
            description={
              <>
                Please fix the errors highlighted in the table below
                <br />
              </>
            }
            type="error"
            showIcon
          />
        </Card>
      )}
      {Object.keys(errors).length === 0 && data.length > 0 && (
        <Card style={{ marginTop: "20px" }}>
          <Alert
            message="Local Validation Successful"
            description={
              <>
                All local data is valid and ready for submission. You can review
                the data below.
                <br />
                Submit the data to upload the products.
              </>
            }
            type="success"
            showIcon
          />
        </Card>
      )}

      {data.length > 0 && (
        <Card style={{ marginTop: "20px" }}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="Data Preview" key="1">
              <Table
                columns={columns}
                dataSource={data}
                pagination={{ pageSize: 30 }}
                bordered
                scroll={{ x: "max-content" }}
              />
            </Panel>
          </Collapse>
          {uploadError && (
            <Card style={{ marginTop: "20px" }}>
              <Alert
                message="Upload Error"
                description={uploadError}
                type="error"
                showIcon
              />
            </Card>
          )}

          <Space
            style={{ padding: "20px" }}
            direction="horizontal"
            size="middle"
          >
            <p>Now you can submit the data to upload the products.</p>
            <Button
              type="primary"
              className="orangebg"
              onClick={() => {
                confirm({
                  title: "Confirm Upload",
                  content: (
                    <>
                      Are you sure you want to upload this data?
                      <br />
                      <Card style={{ marginTop: "20px" }}>
                        <p>
                          You have <strong>{data.length}</strong> products ready
                          for upload.
                        </p>
                      </Card>
                      <br />
                      {/* <Checkbox
                        onChange={(e) => {
                          console.log(e.target.checked);
                          if (e.target.checked) {
                            data.forEach((row) => {
                              row.published_status = "published";
                            });
                          } else {
                            data.forEach((row) => {
                              row.published_status = "draft";
                            });
                          }
                        }}
                      >
                        Mark all products as published
                      </Checkbox> */}
                      <br />
                      <Divider />
                    </>
                  ),
                  onOk: handleSubmit,
                  okText: "Upload, all good!",
                });
              }}
              disabled={Object.keys(errors).length > 0 || data.length === 0}
              loading={loading}
              icon={<ExclamationCircleOutlined />}
            >
              Submit Data
            </Button>
             or 
            <Button
              className="floatright"
              onClick={handleReupload}
              icon={<UploadOutlined />}
            >
              Start Over
            </Button>
          </Space>

          {submissionProgress > 0 && (
            <Progress
              percent={submissionProgress}
              status="active"
              style={{ marginTop: "20px" }}
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
            />
          )}
        </Card>
      )}

      {/* Preview Drawer */}
      <Drawer
        title="Data JSON Preview"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={500}
      >
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          {JSON.stringify(
            data
              .filter((row) => !errors[row.key])
              .map((row) => {
                const filteredRow = {};
                Object.keys(row).forEach((field) => {
                  if (!EXCLUDED_FIELDS.includes(field) && FIELD_CONFIG[field]) {
                    let value = row[field];
                    const config = FIELD_CONFIG[field];
                    if (config.type === "number") {
                      value = Number(value);
                    } else if (config.type === "boolean") {
                      value =
                        typeof value === "boolean"
                          ? value
                          : value.toLowerCase() === "true"
                          ? true
                          : false;
                    } else if (config.type === "array") {
                      value = Array.isArray(value)
                        ? value
                        : value.split(",").map((v) => v.trim());
                    } else if (config.type === "object") {
                      try {
                        value = JSON.parse(value);
                      } catch (e) {
                        value = {};
                      }
                    }
                    filteredRow[field] = value;
                  }
                });
                return filteredRow;
              }),
            null,
            2
          )}
        </pre>
      </Drawer>
    </div>
  );
};

export default BulkUpload;
