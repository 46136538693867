import {
  GlobalOutlined,
  MenuOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";
import {
  deleteCookie,
  deteleAllCookies,
  getCookie,
  setCookie,
} from "./components/cookieUtils";
import { HelmetProvider } from "react-helmet-async";
import {
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  Dropdown,
  Image,
  Input,
  Layout,
  List,
  Menu,
  message,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import axios from "axios";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { sassTrue } from "sass";
import "./App.scss";
import BuyerCheckout from "./components/BuyerCheckout";
import BuyerEditProfile from "./components/BuyerEditProfile";
import BuyerLogin from "./components/BuyerLogin";
import BuyerOrderDetails from "./components/BuyerOrderDetails";
import BuyerOrders from "./components/BuyerOrders";
import BuyerPasswordReset from "./components/BuyerPasswordReset";
import BuyerPasswordResetRequest from "./components/BuyerPasswordResetRequest";
import BuyerSignup from "./components/BuyerSignup";
import CartPage from "./components/CartPage";
import ErrorBoundary from "./components/ErrorBoundary";
import ForgotPassword from "./components/ForgotPassword";
import LandingPage from "./components/LandingPage";
import ViewCategory from "./components/ViewCategory";
import Logo from "./components/Logo";
import MarketplaceLandingPage from "./components/MarketplaceLandingPage";
import NotificationSettings from "./components/NotificationSettings";
import PrivateRoute from "./components/PrivateRoute";
import ProductBulkUpload from "./components/ProductBulkUpload";
import ImageBulkUpload from "./components/ImageBulkUpload";
import ProductCreateSteps from "./components/ProductCreateSteps";
import ProductEditPage from "./components/ProductEditPage";
import ProductListingCard from "./components/ProductListingCard";
import ProductPage from "./components/ProductPage"; // Import the new ProductPage component
import SupplierDashboard from "./components/SupplierDashboard";
import SupplierEditProfile from "./components/SupplierEditProfile";
import SupplierLandingPage from "./components/SupplierLandingPage";
import SupplierLogin from "./components/SupplierLogin";
import SupplierOrders from "./components/SupplierOrders";
import SupplierPasswordReset from "./components/SupplierPasswordReset";
import SupplierPasswordResetRequest from "./components/SupplierPasswordResetRequest";
import SupplierProducts from "./components/SupplierProducts";
import SupplierSignup from "./components/SupplierSignup";
import FAQSection from "./components/FAQSection";

const { Header, Content, Footer } = Layout;
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

const Home = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/marketplace/search-products",
          {
            params: { searchText: "", limit: 500 },
          }
        );
        setProducts(response.data.products);
      } catch (error) {
        console.error("Error fetching featured products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="home-section">
      {/* Hero Section */}
      {/* Features Section with More Relevant Icons */}
      <div className="features-section">
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={12} md={8} lg={6}>
            <Link to="/supplier/signup">
              <Card
                className="feature-card"
                hoverable
                cover={<GlobalOutlined className="feature-icon" />}
              >
                <Card.Meta
                  title="Join as Supplier"
                  description="Expand your reach and connect with buyers."
                />
              </Card>
            </Link>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Link to="/buyer/signup">
              <Card
                className="feature-card"
                hoverable
                cover={<ShoppingCartOutlined className="feature-icon" />}
              >
                <Card.Meta
                  title="Shop with Ease"
                  description="Browse and order from local suppliers."
                />
              </Card>
            </Link>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Link to="/about">
              <Card
                className="feature-card"
                hoverable
                cover={<UserOutlined className="feature-icon" />}
              >
                <Card.Meta
                  title="Fast Delivery"
                  description="Get your goods delivered quickly and securely."
                />
              </Card>
            </Link>
          </Col>
        </Row>
      </div>

      {/* Display Real Featured Products */}
      <div className="popular-products-section">
        {products?.length > 0 && (
          <Title level={3} style={{ textAlign: "center", margin: "30px 0" }}>
            Featured Products
          </Title>
        )}
        <Row gutter={[16, 16]} justify="center">
          {loading && <Skeleton active />}
          {products?.map((product) => (
            <Col key={product.product_id} xs={12} sm={12} md={4} lg={4}>
              <ProductListingCard product={product} showCartButton={sassTrue} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

const About = () => (
  <div
    className="about-section"
    style={{ padding: "10px", backgroundColor: "#f9f9f9" }}
  >
    <Row gutter={[32, 32]}>
      {/* Introduction Section */}
      <Col xs={24} md={12} lg={12}>
        <Title level={2}>About QikTruck & QikMarket</Title>
        <Paragraph>
          QikTruck is revolutionizing the logistics landscape in South Africa by
          seamlessly connecting businesses with a vast network of reliable
          transportation solutions. As the backbone of QikMarket's logistics,
          QikTruck ensures that both Buyers and Sellers have access to an
          extensive fleet of local delivery options, enhancing efficiency and
          reliability in every transaction.
        </Paragraph>
      </Col>
      <Col xs={24} md={12}>
        <Image
          preview={false}
          src="distribution-center.jpg"
          alt="QikTruck Logistics"
          bordered
          style={{ borderRadius: "8px" }}
        />
      </Col>

      {/* Key Features Section */}
      <Col xs={24} lg={12}>
        <Card
          className="about-card"
          hoverable
          cover={<GlobalOutlined className="feature-icon" />}
        >
          <Title level={3}>Why Choose QikTruck?</Title>
          <List
            itemLayout="horizontal"
            dataSource={[
              {
                title: "Extensive Fleet",
                description:
                  "Access to a wide range of vehicles tailored to your delivery needs, from small vans to large trucks.",
              },
              {
                title: "Real-Time Tracking",
                description:
                  "Monitor your shipments in real-time, ensuring transparency and peace of mind.",
              },
              {
                title: "Reliable Partnerships",
                description:
                  "Collaborations with trusted local transporters guarantee timely and safe deliveries.",
              },
              {
                title: "Cost-Effective Solutions",
                description:
                  "Competitive pricing models designed to offer the best value for your logistics requirements.",
              },
              {
                title: "Scalable Services",
                description:
                  "Flexible logistics services that grow with your business, accommodating increased demand seamlessly.",
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={<b>{item.title}</b>}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </Card>
      </Col>

      {/* How It Works Section */}
      <Col xs={24} lg={12}>
        <Card
          className="about-card"
          hoverable
          cover={<GlobalOutlined className="feature-icon" />}
        >
          <Title level={3}>How QikMarket Works</Title>
          <List
            itemLayout="vertical"
            dataSource={[
              {
                step: "1. Register",
                description:
                  "Sign up on QikMarket and set up your QikMarket profile.",
                image: "https://via.placeholder.com/100?text=Register",
              },
              {
                step: "2. Create or Browse Products",
                description:
                  "List your products to sell or browse products to buy.",
                image: "https://via.placeholder.com/100?text=Create+Products",
              },
              {
                step: "3. Select Delivery Options",
                description:
                  "Choose from a variety of delivery vehicles that best fit your shipment size and urgency.",
                image: "https://via.placeholder.com/100?text=Select+Delivery",
              },
              {
                step: "4. Schedule Delivery",
                description:
                  "Book a convenient delivery time that suits your schedule.",
                image: "https://via.placeholder.com/100?text=Schedule+Delivery",
              },
              {
                step: "5. Track Shipment",
                description:
                  "Use our real-time tracking system to monitor your delivery status at any time.",
                image: "https://via.placeholder.com/100?text=Track+Shipment",
              },
              {
                step: "6. Receive Confirmation",
                description:
                  "Get notified upon successful delivery and provide feedback on your experience.",
                image:
                  "https://via.placeholder.com/100?text=Receive+Confirmation",
              },
              {
                step: "7. Pay online or on delivery",
                description:
                  "Choose to pay online or on delivery, whichever is convenient for you.",
                image:
                  "https://via.placeholder.com/100?text=Receive+Confirmation",
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={<b>{item.step}</b>}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </Card>
      </Col>

      {/* Benefits Section */}
      <Col xs={24}>
        <Title level={3}>Benefits for Buyers and Sellers</Title>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card
              className="about-card"
              hoverable
              cover={<GlobalOutlined className="feature-icon" />}
            >
              <Title level={4}>For Buyers</Title>
              <List
                dataSource={[
                  "Fast and reliable deliveries ensuring timely receipt of goods.",
                  "Diverse delivery options catering to different product sizes and urgencies.",
                  "Enhanced transparency with real-time tracking and updates.",
                  "Secure handling of products with trusted transport partners.",
                ]}
                renderItem={(item) => <List.Item>• {item}</List.Item>}
              />
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card
              className="about-card"
              hoverable
              cover={<GlobalOutlined className="feature-icon" />}
            >
              <Title level={4}>For Sellers</Title>
              <List
                dataSource={[
                  "Expanded reach with access to a wide network of local delivery options.",
                  "Improved customer satisfaction through dependable delivery services.",
                  "Efficient logistics management reducing overhead costs.",
                  "Scalable solutions supporting business growth and increased order volumes.",
                ]}
                renderItem={(item) => <List.Item>• {item}</List.Item>}
              />
            </Card>
          </Col>
        </Row>
      </Col>

      {/* Testimonials Section */}
      <Col xs={24}>
        <Card
          className="about-card"
          hoverable
          cover={<GlobalOutlined className="feature-icon" />}
        >
          <Title level={3}>What Our Users Say</Title>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <div className="testimonial">
                <Paragraph>
                  "QikTruck has streamlined our delivery process, making it
                  easier than ever to get products to our customers on time."
                </Paragraph>
                <Paragraph>
                  <b>– Sarah L.</b>
                </Paragraph>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className="testimonial">
                {/* <Image
                  preview={false}
                  src="https://via.placeholder.com/100?text=User+2"
                  alt="User 2"
                  bordered
                  style={{ borderRadius: "50%" }}
                /> */}
                <Paragraph>
                  "The real-time tracking feature gives us peace of mind,
                  knowing exactly where our shipments are at all times."
                </Paragraph>
                <Paragraph>
                  <b>– Michael K.</b>
                </Paragraph>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className="testimonial">
                <Paragraph>
                  "QikTruck’s extensive fleet options have significantly reduced
                  our delivery costs while maintaining high service quality."
                </Paragraph>
                <Paragraph>
                  <b>– Aisha M.</b>
                </Paragraph>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>

      {/* Call to Action Section */}
      <Col xs={24}>
        <Title level={3}>Join QikTruck Today</Title>
        <Paragraph>
          Whether you're a buyer looking for reliable delivery options or a
          seller aiming to expand your reach, QikTruck is your trusted partner
          in logistics. Experience seamless deliveries, exceptional service, and
          the support you need to thrive in the competitive marketplace. Visit
          the QikTruck website <a href="https://qiktruck.com">here</a>
        </Paragraph>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <a href="/">
            <button
              className="cta-button"
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Get Started
            </button>
          </a>
        </div>
      </Col>
    </Row>
    <FAQSection />
  </div>
);

const LoginWithToken = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loginWithToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/login-with-token/${token}`
        );
        if (response.data.suppliertoken) {
          setCookie("supplier-token", response.data.suppliertoken);
          return navigate("/supplier/dashboard");
        }
        if (response.data.buyertoken) {
          setCookie("buyer-token", response.data.buyertoken);
          window.location.href = "/checkout";
          return;
        }
        window.location.href = "/supplier/dashboard";
      } catch {
        navigate("/supplier/login");
      }
    };
    if (token) loginWithToken();
  }, [token, navigate]);

  return <div>Logging you in...</div>;
};
const ScrollToTop = () => {
  const location = useLocation(); // Hook to get the current location

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on location change
  }, [location]); // This effect runs whenever the location changes

  return null; // This component doesn't render anything to the DOM
};
const AppHeader = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const suppliertoken = getCookie("supplier-token");
  const buyertoken = getCookie("buyer-token");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchTextOpen, setSearchTextOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [recentSearches, setRecentSearches] = useState(
    deleteCookie("recentSearches")
      ? JSON.parse(getCookie("recentSearches") || "[]")
      : []
  );
  const [searching, setSearching] = useState(false);
  const [buyerProfile, setBuyerProfile] = useState({});
  const [showFullSearchView, setShowFullSearchView] = useState(false);

  const updateCartItemsCount = () => {
    try {
      const cartItems = JSON.parse(getCookie("cartItems")) || [];
      setCartItemsCount(cartItems.length);
    } catch (error) {}
  };

  const handleLogout = () => {
    setLoading(true);
    deleteCookie("supplier-token");
    deleteCookie("buyer-token");
    deleteCookie("buyerprofile");
    deleteCookie("business_name");
    deleteCookie("cartItems");
    deleteCookie("checkoutDetails");
    deleteCookie("recentSearches");

    deteleAllCookies();
    localStorage.clear();
    message.warning(
      "Please wait while we log you out...",
      5,
      () => {
        window.location.href = "/";
      } // Redirect to home page
    );
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const debouncedFetchSuggestions = debounce(async (text) => {
    if (!text) {
      setSuggestions([]);
      setShowFullSearchView(false);
      return;
    }

    try {
      setSearching(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/marketplace/search-products",
        {
          params: { searchText: text, limit: 10 },
        }
      );
      setSuggestions(response.data.products);
      setShowFullSearchView(true);
    } catch (error) {
      console.error("Error fetching suggestions", error);
    } finally {
      setSearching(false);
    }
  }, 300);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value.length > 2) {
      debouncedFetchSuggestions(value);
      if (!recentSearches.includes(value)) {
        recentSearches.push(value);
        setCookie("recentSearches", JSON.stringify(recentSearches));
        setRecentSearches(recentSearches);
      }
    }

    if (value.length === 0) {
      setSuggestions([]);
      setShowFullSearchView(false);
    }
  };

  const handleSuggestionClick = (product) => {
    const { product_id, name, category_id } = product;
    window.location.assign(
      `/product?term=${encodeURIComponent(
        name
      )}&productId=${product_id}&categoryId=${category_id}`
    );
    setShowFullSearchView(false);
  };
  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const suppliertoken = getCookie("supplier-token");

        if (!suppliertoken || suppliertoken === "undefined") {
          return;
        }
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/marketplace/supplier-profile",
          {
            headers: { Authorization: suppliertoken }, // Added 'Bearer ' prefix
          }
        );
        setCookie("business_name", response.data?.business_name);
      } catch (error) {
        if (error.response?.status === 403) {
          deleteCookie("supplier-token");
          //clear local storage
          deteleAllCookies();
          window.location.href = "/";
        }
        console.error("Error fetching supplier data", error);
      }
    };
    fetchSupplierData();
    const fetchBuyerData = async () => {
      try {
        const buyertoken = getCookie("buyer-token");

        if (!buyertoken || buyertoken === "undefined") {
          return;
        }
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/marketplace/buyer-profile",
          {
            headers: { Authorization: buyertoken }, // Added 'Bearer ' prefix
          }
        );
        setCookie("buyerprofile", JSON.stringify(response.data));
        setBuyerProfile(response.data);
      } catch (error) {
        if (error.response?.status === 403) {
          deleteCookie("buyer-token");
          //clear local storage
          deteleAllCookies();
          window.location.href = "/";
        }
        console.error("Error fetching supplier data", error);
      }
    };
    fetchBuyerData();

    updateCartItemsCount(); // Update count on mount
    const handleStorageChange = () => {
      updateCartItemsCount(); // Update count on local storage change
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("localStorageChange", handleStorageChange);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800); // Toggle based on screen width
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize state on mount

    if (
      getCookie("supplier-token") &&
      isTokenExpired(getCookie("supplier-token"))
    ) {
      // Token has expired
      // Perform actions like redirecting to login
      console.log("Token has expired. Redirecting to login...");
      deleteCookie("supplier-token");
      deteleAllCookies();
      setTimeout(() => {
        window.location.href = "/supplier/login";
      }, 2000);
    }
    if (getCookie("buyer-token") && isTokenExpired(getCookie("buyer-token"))) {
      // Token has expired
      // Perform actions like redirecting to login
      console.log("Token has expired. Redirecting to login...");
      deleteCookie("buyer-token");
      deteleAllCookies();
      setTimeout(() => {
        window.location.href = "/buyer/login";
      }, 2000);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("localStorageChange", handleStorageChange);
    };
  }, []);
  // Function to determine active class
  const getActiveClass = ({ isActive }) => (isActive ? "active-link" : "");
  const menu = (
    <Menu>
      <Menu.ItemGroup title="Accounts">
        <Menu.Item key="1">
          <NavLink to="/supplier/signup" className={getActiveClass}>
            Supplier Signup
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          {" "}
          <NavLink to="/supplier/login" className={getActiveClass}>
            Supplier Login
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title="Benefits">
        <Menu.Item key="7">
          <NavLink to="/supplier-landing" className={getActiveClass}>
            Supplier Benefits
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
  const buyersmenu = (
    <Menu>
      <Menu.ItemGroup title="Accounts">
        <Menu.Item key="1">
          <NavLink to="/buyer/signup" className={getActiveClass}>
            Buyer Signup
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          {" "}
          <NavLink to="/buyer/login" className={getActiveClass}>
            Buyer Login
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title="Benefits">
        <Menu.Item key="7">
          <NavLink to="/About" className={getActiveClass}>
            How it works{" "}
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
  return (
    <Header className={suppliertoken ? "app-header supplier" : "app-header"}>
      <NavLink to="/">
        <Logo />
      </NavLink>{" "}
      <div className="search-bar">
        <Space.Compact>
          {isMobile && !searchTextOpen && (
            <Button
              icon={<SearchOutlined />}
              onClick={setSearchTextOpen}
              className="global-search-button"
            />
          )}
          {isMobile && searchTextOpen && (
            <Input
              placeholder="Search for products..."
              prefix={<SearchOutlined />}
              value={searchText}
              autoFocus
              onChange={handleSearchInputChange}
              onFocus={() => setShowFullSearchView(true)}
              className="global-search-input"
            /> // Add onFocus event
          )}
          {
            !isMobile && (
              <Input
                placeholder="Search for products..."
                prefix={<SearchOutlined />}
                value={searchText}
                onChange={handleSearchInputChange}
                onFocus={() => setShowFullSearchView(true)}
                className="global-search-input"
              />
            ) // Add onFocus event
          }
        </Space.Compact>
        {/* Full Search View Dropdown */}
      </div>
      {showFullSearchView && (
        <div className="full-search-view">
          {/* close icon */}
          <div
            className="close-search-view"
            onClick={
              () => {
                setSearchTextOpen(false); // Close search text input
                setShowFullSearchView(false);
              } // Hide full search view
            }
          >
            <Button type="link">X</Button>
          </div>

          <List
            header={
              <Text strong>
                Suggestions {searching && <Spin size="small" />}
                {searchText && suggestions.length === 0 && !searching && (
                  <Text type="secondary">No results found</Text>
                )}
              </Text>
            }
            dataSource={suggestions}
            locale={{ emptyText: "Type to search for products" }}
            renderItem={(item) => (
              <List.Item onClick={() => handleSuggestionClick(item)}>
                <div className="suggestion-item">
                  {item.default_image && item.images?.[0] && (
                    <img
                      src={(item.default_image || item.images?.[0])?.replace(
                        ".s3.eu-west-1.amazonaws.com",
                        ""
                      )}
                      alt={item.name}
                      className="suggestion-image"
                    />
                  )}
                  <span>{item.name}</span>
                </div>
              </List.Item>
            )}
          />
          {/* <List
                  bordered
                  header={
                    <div>
                      <Text strong>Recent Searches</Text>
                      <Button
                        type="link"
                        icon={<FireOutlined />}
                        onClick={() => {
                          deleteCookie("recentSearches");
                          setRecentSearches([]);
                        }} // Clear recent searches
                      >
                        Clear
                      </Button>
                    </div>
                  }
                  dataSource={recentSearches} // Replace with dynamic data if available
                  renderItem={(item) => (
                    <List.Item
                      onClick={() =>
                        handleSearchInputChange({
                          target: { value: item },
                        })
                      }
                    >
                      <ClockCircleOutlined style={{ marginRight: 8 }} /> {item}
                    </List.Item>
                  )}
                /> */}
        </div>
      )}
      <span
        style={{
          color: "lightgrey",
          fontWeight: "bold",
          fontSize: "14px",
          marginRight: "5px",
          marginLeft: "5px",
        }}
      >
        {getCookie("business_name")}
      </span>
      <div className="desktop-navigation">
        {!suppliertoken && !buyertoken && (
          <>
            <NavLink to="/" className={getActiveClass}>
              Buy
            </NavLink>
            <NavLink to="/home" className={getActiveClass}>
              Explore
            </NavLink>

            <Dropdown overlay={menu} trigger={["hover"]}>
              <a className="menu-item" href="/supplier/login">
                Suppliers/ Sellers <span className="arrow-down">▼</span>
              </a>
            </Dropdown>

            <Dropdown overlay={buyersmenu} trigger={["hover"]}>
              <a className="menu-item" href="/buyer/login">
                Buyers <span className="arrow-down">▼</span>
              </a>
            </Dropdown>
            <Link to="/cart" className="cart-link" onClick={closeDrawer}>
              <Badge count={cartItemsCount} overflowCount={99}>
                <ShoppingCartOutlined
                  style={{
                    fontSize: "24px",
                    marginLeft: "20px",
                    color: "#000",
                  }}
                />
                {buyerProfile?.firstname}
              </Badge>
            </Link>
          </>
        )}
        {suppliertoken && !buyertoken && (
          <>
            <NavLink to="/home" className={getActiveClass}>
              Explore
            </NavLink>
            <NavLink to="/supplier/dashboard" className={getActiveClass}>
              Dashboard
            </NavLink>
            <NavLink to="/supplier/orders" className={getActiveClass}>
              Orders
            </NavLink>
            <NavLink to="/supplier/products" className={getActiveClass}>
              Products
            </NavLink>
            <NavLink to="/supplier/product-create" className={getActiveClass}>
              <Button>Create Product</Button>
            </NavLink>
            <Select value="Profile">
              <Option value="Profile">
                <NavLink to="/supplier/edit-profile" className={getActiveClass}>
                  Profile
                </NavLink>
              </Option>
              <Option value="NotificationSettings">
                <NavLink to="/supplier/settings" className={getActiveClass}>
                  Notification Settings
                </NavLink>
              </Option>
              <Option value="Logout">
                <Button
                  onClick={() => {
                    closeDrawer();
                    handleLogout();
                  }}
                  type="danger"
                >
                  Logout {loading && <Spin size="small" />}
                </Button>
              </Option>
            </Select>
          </>
        )}
        {buyertoken && !suppliertoken && (
          <>
            <NavLink to="/" className={getActiveClass}>
              Buy
            </NavLink>
            <NavLink to="/checkout" className={getActiveClass}>
              Checkout
            </NavLink>
            <NavLink to="/buyer/orders" className={getActiveClass}>
              Orders
            </NavLink>
            <Link to="/cart" className="cart-link" onClick={closeDrawer}>
              <Badge count={cartItemsCount} overflowCount={99}>
                <ShoppingCartOutlined
                  style={{
                    fontSize: "24px",
                    marginLeft: "20px",
                    color: "#000",
                  }}
                />
                {buyerProfile?.firstname}
              </Badge>
            </Link>
            <Select value="Profile">
              <Option value="Profile">
                <NavLink to="/buyer/edit-profile" className={getActiveClass}>
                  Profile
                </NavLink>
              </Option>
              <Option value="NotificationSettings">
                <NavLink to="/supplier/settings" className={getActiveClass}>
                  Notification Settings
                </NavLink>
              </Option>
              <Option value="Logout">
                <Button
                  onClick={() => {
                    closeDrawer();
                    handleLogout();
                  }}
                  type="danger"
                >
                  Logout {loading && <Spin size="small" />}
                </Button>
              </Option>
            </Select>
          </>
        )}
      </div>
      {/* Hamburger Menu Icon for Mobile */}
      <div className="mobile-menu-icon" onClick={showDrawer}>
        <MenuOutlined />
      </div>
      {/* Drawer for Mobile Navigation */}
      <Drawer
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible} // Updated prop
        className="mobile-drawer"
      >
        <NavLink to="/about" className={getActiveClass} onClick={closeDrawer}>
          About
        </NavLink>

        {!suppliertoken && !buyertoken && (
          <>
            <h3 style={{ marginTop: "20px", color: "#ccc" }}>BUYERS</h3>
            <NavLink to="/" className={getActiveClass} onClick={closeDrawer}>
              Buy
            </NavLink>

            <Link to="/cart" onClick={closeDrawer}>
              <Badge count={cartItemsCount} overflowCount={99}>
                <ShoppingCartOutlined
                  style={{
                    fontSize: "24px",
                    marginLeft: "0px",
                    color: "#000",
                  }}
                />{" "}
                {buyerProfile?.firstname} CART
              </Badge>
            </Link>
            <NavLink
              to="/buyer/signup"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Buyer Signup
            </NavLink>
            <NavLink
              to="/buyer/login"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Buyer Login
            </NavLink>

            <NavLink
              to="/home"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Explore
            </NavLink>

            <h3 style={{ marginTop: "20px", color: "#ccc" }}>SELLERS</h3>
            <NavLink
              to="/supplier/signup"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Supplier Signup
            </NavLink>
            <NavLink
              to="/supplier/login"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Supplier Login
            </NavLink>
            <NavLink
              to="/supplier-landing"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Supplier Benefits
            </NavLink>
          </>
        )}

        {suppliertoken && !buyertoken && (
          <>
            <NavLink
              to="/home"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Explore
            </NavLink>
            <NavLink to="/supplier/dashboard" className={getActiveClass}>
              Dashboard
            </NavLink>
            <NavLink
              to="/supplier/orders"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Orders
            </NavLink>
            <NavLink
              to="/supplier/products"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Products
            </NavLink>
            <NavLink
              to="/supplier/product-create"
              className={getActiveClass}
              onClick={closeDrawer}
            >
              Create Product
            </NavLink>
            <NavLink to="/supplier/edit-profile" className={getActiveClass}>
              Profile
            </NavLink>
            <NavLink to="/supplier/settings" className={getActiveClass}>
              Notification Settings
            </NavLink>
            <br />
            <br />
            <br />
            <br />
            <Button
              onClick={() => {
                closeDrawer();
                handleLogout();
              }}
              type="danger"
            >
              Logout {loading && <Spin size="small" />}
            </Button>
          </>
        )}
        {buyertoken &&
          !suppliertoken && ( // Add this line
            <>
              <NavLink to="/" className={getActiveClass} onClick={closeDrawer}>
                Buy
              </NavLink>
              <NavLink
                to="/checkout"
                className={getActiveClass}
                onClick={closeDrawer}
              >
                Checkout
              </NavLink>
              <NavLink
                to="/buyer/orders"
                onClick={closeDrawer}
                className={getActiveClass}
              >
                Orders
              </NavLink>

              <NavLink
                to="/buyer/edit-profile"
                className={getActiveClass}
                onClick={closeDrawer}
              >
                Profile
              </NavLink>
              <NavLink
                to="/supplier/settings"
                className={getActiveClass}
                onClick={closeDrawer}
              >
                Notification Settings
              </NavLink>
              <Link to="/cart" className="cart-link" onClick={closeDrawer}>
                <Badge count={cartItemsCount} overflowCount={99}>
                  <ShoppingCartOutlined
                    style={{
                      fontSize: "24px",
                      marginLeft: "20px",
                      color: "#000",
                    }}
                  />
                  {buyerProfile?.firstname}
                </Badge>
              </Link>
              <br />
              <br />
              <br />
              <br />
              <br />
              <Button
                onClick={() => {
                  closeDrawer();
                  handleLogout();
                }}
                type="danger"
              >
                Logout {loading && <Spin size="small" />}
              </Button>
            </>
          )}
      </Drawer>
    </Header>
  );
};
const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }

  try {
    const decoded = jwtDecode(token);

    if (!decoded.exp) {
      // If no expiration claim, consider token as expired
      return true;
    }

    const currentTime = Date.now() / 1000; // Current time in seconds
    return decoded.exp < currentTime;
  } catch (error) {
    // If token is invalid or cannot be decoded, consider it expired
    console.error("Invalid token:", error);
    return true;
  }
};
function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Router>
          <ScrollToTop /> {/* Scroll to top on route change */}
          <Layout className="layout">
            <AppHeader />

            <Content className="main-content">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/category" element={<ViewCategory />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/supplier/signup" element={<SupplierSignup />} />
                <Route path="/supplier/login" element={<SupplierLogin />} />
                <Route path="/buyer/signup" element={<BuyerSignup />} />
                <Route path="/buyer/login" element={<BuyerLogin />} />
                <Route path="/product" element={<ProductPage />} />
                <Route path="/cart" element={<CartPage />} />
                <Route
                  path="/buyer/reset-password/:token"
                  element={<BuyerPasswordReset />}
                />
                <Route
                  path="/buyer/password-reset-request"
                  element={<BuyerPasswordResetRequest />}
                />
                <Route
                  path="/buyer/reset-password/:token"
                  element={<BuyerPasswordReset />}
                />
                <Route
                  path="/buyer/password-reset-request"
                  element={<BuyerPasswordResetRequest />}
                />
                <Route
                  path="/supplier/reset-password/:token"
                  element={<SupplierPasswordReset />}
                />
                <Route
                  path="/supplier/password-reset-request"
                  element={<SupplierPasswordResetRequest />}
                />

                <Route path="/landing" element={<MarketplaceLandingPage />} />
                <Route
                  path="/supplier-landing"
                  element={<SupplierLandingPage />}
                />
                <Route
                  path="/supplier/login/:token"
                  element={<LoginWithToken />}
                />
                <Route
                  path="/supplier/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route path="/checkout" element={<BuyerCheckout />} />
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/buyer/settings"
                    element={<NotificationSettings />}
                  />

                  <Route
                    path="/supplier/settings"
                    element={<NotificationSettings />}
                  />
                  <Route
                    path="/supplier/dashboard"
                    element={<SupplierDashboard />}
                  />
                  <Route
                    path="/supplier/products"
                    element={<SupplierProducts />}
                  />
                  <Route path="/supplier/orders" element={<SupplierOrders />} />
                  <Route path="/buyer/orders" element={<BuyerOrders />} />
                  <Route
                    path="/buyer/order/:orderId"
                    element={<BuyerOrderDetails />}
                  />
                  <Route
                    path="/supplier/product-create"
                    element={<ProductCreateSteps />}
                  />
                  <Route
                    path="/supplier/product-edit/:productId"
                    element={<ProductEditPage />}
                  />
                  <Route
                    path="/supplier/product-bulkupload"
                    element={<ProductBulkUpload />}
                  />
                  <Route
                    path="/supplier/image-bulkupload"
                    element={<ImageBulkUpload />}
                  />
                  <Route
                    path="/buyer/edit-profile"
                    element={<BuyerEditProfile />}
                  />
                  <Route
                    path="/supplier/edit-profile"
                    element={<SupplierEditProfile />}
                  />
                </Route>
              </Routes>
            </Content>
            <Footer className="app-footer">
              <div className="footer-content">
                <div className="footer-section">
                  <h3>About Us</h3>
                  <p>Connecting buyers and suppliers across South Africa.</p>
                </div>
                <div className="footer-section">
                  <h3>Quick Links</h3>
                  <Link to="/about">About QikTruck</Link>
                  <br />
                  <br />
                  <Link to="/supplier/signup">Become a Supplier</Link>
                  <br />
                  <br />
                  <Link to="/buyer/signup">Become a Buyer</Link>
                  <br />
                  <br />
                  <Link to="/supplier-landing">Supplier Benefits</Link>
                  <br />
                </div>
                <div className="footer-section">
                  <h3>Contact Us</h3>
                  <p>Email: support@qiktruck.co.za</p>
                  <p>WhatsApp: 066 378 4460</p>
                </div>
              </div>
            </Footer>
          </Layout>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;
