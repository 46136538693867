// src/components/NotificationSettings.js
import React, { useEffect, useState } from "react";
import { Table, Switch, Button, message, Spin } from "antd";
import axios from "axios";
import { getCookie } from "./cookieUtils";

const NotificationSettings = () => {
  let userType = getCookie("supplier-token")
    ? "supplier"
    : getCookie("buyer-token")
    ? "buyer"
    : null;

  // userType: 'supplier' or 'buyer'
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  // Fetch notification settings on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `
            ${process.env.REACT_APP_API_URL}/marketplace/${userType}-notification-settings`,
          {
            headers: {
              Authorization:
                getCookie("supplier-token") || getCookie("buyer-token"),
            },
          }
        );
        setNotificationSettings(response.data.notificationSettings);
      } catch (error) {
        console.error("Error fetching notification settings:", error);
        message.error("Failed to load notification settings.");
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [userType]);

  // Handle toggle switch change
  const handleToggle = (typeIndex, channelIndex) => {
    const updatedSettings = [...notificationSettings];
    updatedSettings[typeIndex].channels[channelIndex].enabled =
      !updatedSettings[typeIndex].channels[channelIndex].enabled;
    setNotificationSettings(updatedSettings);
  };

  // Handle save button click
  const handleSave = async () => {
    setSaving(true);
    try {
      await axios.put(
        ` ${process.env.REACT_APP_API_URL}/marketplace/${userType}-notification-settings`,
        { notificationSettings },
        {
          headers: {
            Authorization:
              getCookie("supplier-token") || getCookie("buyer-token"),
          },
        }
      );
      message.success("Notification settings updated successfully.");
    } catch (error) {
      console.error("Error updating notification settings:", error);
      message.error("Failed to update notification settings.");
    } finally {
      setSaving(false);
    }
  };

  // Define columns for the table
  const columns = [
    {
      title: "Notification Type",
      dataIndex: "notificationType",
      key: "notificationType",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Channels",
      dataIndex: "channels",
      key: "channels",
      render: (channels, record, typeIndex) => (
        <div>
          {channels.map((channel, channelIndex) => (
            <div key={channel.channel}>
              <span>{channel.channel}: </span>
              <Switch
                checked={channel.enabled}
                onChange={() => handleToggle(typeIndex, channelIndex)}
              />
            </div>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: "24px" }}>
      <h2>Notification Settings</h2>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          dataSource={notificationSettings}
          columns={columns}
          rowKey="notificationType"
          pagination={false}
        />
      )}
      <div style={{ marginTop: "16px" }}>
        <Button type="primary" onClick={handleSave} loading={saving}>
          Save Settings
        </Button>
      </div>
    </div>
  );
};

export default NotificationSettings;
