import { Button, Col, Form, Input, message, Row, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddressAutocomplete from "./AddressAutocomplete"; // Import the AddressAutocomplete component
import { getCookie } from "./cookieUtils";

const BuyerEditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [addressdata, setAddressData] = useState({
    address: "",
    lat: "",
    lng: "",
  });
  const [buyerData, setBuyerData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    lat: "",
    lng: "",
  });

  useEffect(() => {
    const fetchBuyerProfile = async () => {
      setLoading(true);
      try {
        const token = getCookie("buyer-token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/buyer-profile`,
          {
            headers: { Authorization: token },
          }
        );
        setBuyerData(response.data); // Update the buyer data once fetched
        setAddressData({
          address: response.data.address,
          lat: response.data.default_lat,
          lng: response.data.default_lng,
        });
      } catch (error) {
        console.error("Error fetching buyer profile:", error);
        message.error("Failed to fetch profile data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBuyerProfile();
  }, []);

  const handleUpdateProfile = async (values) => {
    setLoading(true);
    try {
      const token = getCookie("buyer-token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/marketplace/buyer-profile`,
        {
          ...buyerData, // include existing buyer data
          ...values,
          ...addressdata,
          buyerId: buyerData.buyer_id, // include buyerId in the request
        },
        {
          headers: { Authorization: token },
        }
      );
      message.success(response.data.message);
      window.location.reload();
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile.");
      setLoading(false);
    }
  };

  const handleSelectAddress = ({ address, lat, lng }) => {
    setBuyerData((prevState) => ({
      ...prevState,
      address: address,
      lat: lat,
      lng: lng,
    }));
    setAddressData({ address: address, lat: lat, lng: lng });

    console.log("Selected address:", address, lat, lng);
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <h2>Edit Buyer Profile</h2>
      <Form
        name="edit-profile"
        onFinish={handleUpdateProfile}
        initialValues={buyerData} // Form will be prepopulated with this data
        layout="vertical"
        style={{ maxWidth: "600px", margin: "0 auto" }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstname"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastname"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={"Current Address: " + buyerData.address}
          name="address"
          rules={[{ required: true, message: "Please input your address!" }]}
        >
          <AddressAutocomplete onSelectAddress={handleSelectAddress} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BuyerEditProfile;
