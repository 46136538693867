import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Descriptions,
  Drawer,
  Input,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getCookie } from "./cookieUtils";
import ItemUpdateModal from "./ItemUpdateModal"; // Import the modal component
import DeliveryForm from "./DeliveryForm";

const { Title, Text } = Typography;

const SupplierOrderItems = ({
  openOrderItems,
  selectedOrder,
  closeOrderItemsDrawer,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(openOrderItems);
  const [orderItems, setOrderItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [fetchingItems, setFetchingItems] = useState(false);
  const [actionModalVisible, setActionModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [drawerDeliveryVisible, setDeliveryDrawerVisible] = useState(false);

  useEffect(() => {
    if (selectedOrder) {
      fetchOrderItems(selectedOrder.order_id);
    }

    if (!selectedOrder) {
      // get orderid from the url
      const orderId = window.location.pathname.split("/").pop();
      fetchOrderItems(orderId);
    }
  }, [selectedOrder]);

  const closeDrawerHandler = () => {
    setDrawerVisible(false);
    setOrderItems([]);
    closeOrderItemsDrawer(false);
  };

  const fetchOrderItems = async (orderId) => {
    try {
      setFetchingItems(true);
      const token = getCookie("supplier-token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-get-order-items/${orderId}`,
        {
          headers: { Authorization: token },
        }
      );
      setOrderItems(response.data.items);
    } catch (error) {
      console.error("Error fetching order items", error);
    } finally {
      setFetchingItems(false);
    }
  };

  const handleActionModal = (item) => {
    setCurrentItem(item);
    setActionModalVisible(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filteredItems = orderItems.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filteredItems);
  };

  const columns = [
    {
      title: "Order Item",
      dataIndex: "order_item_id",
      key: "order_item_id",
      render: (order_item_id) => order_item_id.split("-")[0],
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",
      render: (product_id) => product_id.split("-")[0],
    },
    {
      title: "Unit Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Stock Status",
      dataIndex: "stock_status",
      key: "stock_status",
    },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Collection Date",
      dataIndex: "collection_date",
      key: "collection_date",
      render: (collection_date) => {
        console.log("Collection Date", collection_date);

        // Check if the collection_date is valid using moment
        const validDate = collection_date
          ? moment(collection_date).isValid()
          : false;

        // If the date is valid, format and return it
        if (validDate) {
          return moment(collection_date).format("YYYY-MM-DD");
        }

        // If the date is invalid, show a fallback message
        return "Invalid Date/Time";
      },
    },
    {
      title: "Item Status",
      dataIndex: "item_status",
      key: "item_status",
      render: (itemStatus) => {
        const colorMap = {
          pending: "yellow",
          delivered: "green",
          cancelled: "red",
          "ready-for-collection": "green",
          processed: "orange",
          returned: "orange",
          lost: "purple",
        };
        return (
          <span style={{ backgroundColor: colorMap[itemStatus] }}>
            {itemStatus}
          </span>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button type="link" onClick={() => handleActionModal(record)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <Drawer
        title={`Order Details - ${selectedOrder?.order_id}`}
        placement="right"
        onClose={closeDrawerHandler}
        open={drawerVisible || openOrderItems}
        width="100%"
        afterOpenChange={() => fetchOrderItems(selectedOrder?.order_id)}
      >
        {selectedOrder ? (
          <div>
            <Row gutter={16}>
              <Col md={12} sm={24} lg={12}>
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="Order ID">
                    {selectedOrder?.order_id?.split("-")[0]}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    {selectedOrder?.status}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Amount">
                    R {selectedOrder?.supplier_total_amount}
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivery Date">
                    {moment(selectedOrder?.delivery_date).format(
                      "YYYY MMM dddd"
                    )}
                    <br />
                    <b>
                      {moment(selectedOrder?.delivery_date).diff(
                        moment(),
                        "days"
                      ) > 0
                        ? `in ${moment(selectedOrder?.delivery_date).diff(
                            moment(),
                            "days"
                          )} days`
                        : `in ${moment(selectedOrder?.delivery_date).diff(
                            moment(),
                            "hours"
                          )} hours`}
                    </b>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col md={12} sm={24} lg={12}>
                {/* Delivery cost breakdown,  */}
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="Delivery Status">
                    {selectedOrder?.delivery_status}
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivery Cost">
                    R {selectedOrder?.delivery_cost}
                  </Descriptions.Item>
                  <Descriptions.Item label="Estimated Collection Date">
                    {selectedOrder?.num_helpers}
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivery Process">
                    <Button
                      type="primary"
                      onClick={() => setDeliveryDrawerVisible(true)}
                    >
                      Open Delivery Quotation
                    </Button>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Title level={4}>Order Items</Title>
            <Text>
              Click on each product to edit its status, notes, and actions.
            </Text>
            <br />
            <br />
            <Input
              placeholder="Search items"
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: "300px", marginBottom: "20px" }}
            />
            <Table
              columns={columns}
              dataSource={searchTerm ? filteredItems : orderItems}
              rowKey={(record, index) => `${record.item_id}-${index}`}
              pagination={{ pageSize: 15 }}
              locale={{
                emptyText: fetchingItems ? (
                  <Spin size="large" />
                ) : (
                  <p>No items</p>
                ),
              }}
            />
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </Drawer>

      <DeliveryForm
        visible={drawerDeliveryVisible}
        onClose={() => setDeliveryDrawerVisible(false)}
        itemsList={orderItems}
        selectedOrder={selectedOrder}
      />
      {/* ItemUpdateModal component for handling item status updates */}
      <ItemUpdateModal
        visible={actionModalVisible}
        item={currentItem}
        onClose={() => setActionModalVisible(false)}
        onUpdate={() => fetchOrderItems(currentItem.order_id)} // Refresh items after update
      />
    </>
  );
};

export default SupplierOrderItems;
