import {
  Button,
  Card,
  Input,
  Row,
  Col,
  Table,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddressAutocomplete from "./AddressAutocomplete"; // Import your AddressAutocomplete component
import { getCookie, setCookie, deleteCookie } from "./cookieUtils";
import TextArea from "antd/es/input/TextArea";

const { Title, Text } = Typography;

const BuyerCheckout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [buyerProfile, setBuyerProfile] = useState({});
  const [deliveryInstructions, setDeliveryInstructions] = useState("");

  useEffect(() => {
    try {
      if (getCookie("buyerprofile")) {
        setBuyerProfile(JSON.parse(getCookie("buyerprofile")));
        setAddress(JSON.parse(getCookie("buyerprofile")).address);
        setLat(JSON.parse(getCookie("buyerprofile")).default_lat);
        setLng(JSON.parse(getCookie("buyerprofile")).default_lng);
      }
    } catch (error) {}
    const savedCartItems = JSON.parse(getCookie("cartItems")) || [];
    setCartItems(savedCartItems);
    const totalAmount = savedCartItems
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);
    setTotal(totalAmount);
  }, []);

  const handleCheckout = async () => {
    const orderDetails = {
      items: cartItems,
      deliveryAddress: address,
      location: { lat, lng }, // Add coordinates
      deliveryDate, // Include delivery date
    };
    if (cartItems.length === 0) {
      return message.error("Your cart is empty.");
    }

    if (!address || !lat || !lng) {
      return message.error("Please select a delivery address");
    }

    if (!deliveryDate) {
      return message.error("Please select a delivery date");
    }

    try {
      //store checkout details in local storage
      setCookie("checkoutDetails", JSON.stringify(orderDetails));
      //verify if user is logged in
      if (!getCookie("buyer-token")) {
        message.error("Please login to checkout");
        window.location.href = "/buyer/login";
        return;
      }
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/marketplace/buyers-checkout",
        { ...orderDetails, deliveryInstructions },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getCookie("buyer-token"),
          },
        }
      );

      if (response.status !== 201) {
        throw new Error("Failed to submit order");
      }

      message.success("Order submitted successfully!");
      deleteCookie("cartItems");
      setCartItems([]);
      window.location.href = "/buyer/order/" + response.data.orderId;
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAddress = ({ address, lat, lng }) => {
    setAddress(address);
    setLat(lat);
    setLng(lng);
  };

  return (
    <div style={{ padding: "5px" }}>
      <Title level={2}>
        Checkout{" "}
        <span
          style={{
            fontSize: "16px",
            color: "#888",
            textTransform: "uppercase",
          }}
        >
          {buyerProfile?.firstname
            ? ` ${buyerProfile.firstname} ${buyerProfile.lastname}`
            : " Guest"}
        </span>
      </Title>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={24} md={8} lg={8}>
              <Card
                style={{ marginBottom: "20px", border: "1px solid #ccc" }}
                className="checkout-card delivery-address"
              >
                <img
                  src="https://img.icons8.com/ios/452/address.png"
                  alt="address"
                  style={{ width: "30px", height: "30px" }}
                />
                <Title level={4}>
                  {/* icon */}
                  Delivery Address
                </Title>
                <Text>
                  Enter your delivery address, then select from the dropdown to
                  autocomplete. This will help us deliver your order to the
                  correct location.
                </Text>
                <br />
                <br />
                {
                  // Display address if it exists
                  buyerProfile?.address && ( // Check if address exists
                    <Text>
                      <strong>Current Address:</strong> {buyerProfile.address}
                    </Text>
                  )
                }
                <br />
                <br />
                <AddressAutocomplete onSelectAddress={handleSelectAddress} />
              </Card>
            </Col>
            {/* Delivery Date Section */}
            <Col xs={24} sm={24} md={8} lg={8}>
              <Card style={{ marginBottom: "20px", border: "1px solid #ccc" }}>
                <img
                  src="https://img.icons8.com/ios/452/calendar.png"
                  alt="calendar"
                  style={{ width: "30px", height: "30px" }}
                />
                <Title level={4}>
                  {/* icon */}
                  Select Delivery Date
                </Title>
                <Text>
                  Choose a delivery date for your order. We offer next-day
                  delivery for orders placed before 3 PM.
                </Text>
                <br />
                <br />
                <Input
                  type="date"
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                  style={{ width: "200px" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <Card style={{ marginBottom: "20px", border: "1px solid #ccc" }}>
                <img
                  src="https://img.icons8.com/ios/452/information.png"
                  alt="calendar"
                  style={{ width: "30px", height: "30px" }}
                />
                <Title level={4}>
                  {/* icon */}
                  Delivery Instructions{" "}
                </Title>
                <Text>
                  Add any special instructions for your order. For example, you
                  can specify entry code or names of the receiver.
                </Text>
                <br />
                <br />
                <TextArea
                  rows={4}
                  onChange={(e) => setDeliveryInstructions(e.target.value)}
                />
              </Card>
            </Col>
          </Row>
          <Title level={4}>Order Summary</Title>
          <Table
            dataSource={cartItems}
            columns={[
              {
                title: "Product Name",
                dataIndex: "name",
                key: "name",
                render: (name, record) => (
                  <Link to={`/product/?productId=${record.product_id}`}>
                    {name}
                  </Link>
                ),
              },
              {
                title: "Quantity",
                dataIndex: "quantity",
                key: "quantity",
              },
              {
                title: "Unit Price",
                dataIndex: "price",
                key: "price",
                render: (price) => `R ${price}`,
              },
              {
                title: "Total Price",
                dataIndex: "price",
                key: "price",
                render: (price, record) => `R ${price * record.quantity}`,
              },
            ]}
            rowKey={(record, index) =>`${record.product_id}-${index}`}
          />
        </>
      )}
      <div className="edit-cart-link">
        <Link to="/cart">{"<<"}Edit Your Cart</Link>
      </div>
      {/* Explanation Sections */}
      <Card
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          border: "1px solid #ccc",
        }}
      >
        <Title level={4}>How Deliveries Work</Title>
        <Text>
          Our logistics partner ensures that your order is delivered quickly.
          For orders placed before 3 PM, we offer next-day delivery.
        </Text>
      </Card>

      <Card style={{ marginBottom: "20px", border: "1px solid #ccc" }}>
        <Title level={4}>Order Approval</Title>
        <Text>
          Once your order is placed, it will be sent to the supplier for
          approval. You'll receive a notification once the order is confirmed.
        </Text>
      </Card>

      <Card style={{ marginBottom: "20px", border: "1px solid #ccc" }}>
        <Title level={4}>Payment on Delivery</Title>
        <ul>
          <li>All products are eligible for payment on delivery.</li>
          <li>
            You can pay directly to the delivery personnel upon receiving your
            order.
          </li>
        </ul>
      </Card>
      <p>
        Delivering to: <strong>{address || "Not provided"} </strong>
        on date: <strong>{deliveryDate || "Not provided"}</strong>
      </p>

      <div style={{ textAlign: "right", marginTop: "20px", fontSize: "20px" }}>
        <Title level={7}>Total: R {total}</Title>
      </div>
      <div style={{ marginTop: "20px", textAlign: "right" }}>
        <Button
          type="primary"
          onClick={handleCheckout}
          loading={loading}
          disabled={loading}
          style={{
            marginBottom: "20px",
            backgroundColor: "#f9a822",
            borderColor: "#f9a822",
            padding: "15px 40px",
            fontSize: "20px",
          }}
        >
          Complete Order
        </Button>
      </div>
      <Card style={{ marginBottom: "20px" }}>
        <Title level={4}>Powered by QikTruck</Title>
        <Text>
          QikTruck is a leading logistics provider in South Africa. We offer
          reliable delivery services for all your orders
        </Text>
      </Card>
    </div>
  );
};

export default BuyerCheckout;
