import {
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  Select,
  TimePicker,
} from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { getCookie } from "./cookieUtils";
import moment from "moment";

const { Option } = Select;

const ItemUpdateModal = ({ visible, item, onClose, onUpdate }) => {
  const [itemStatus, setItemStatus] = useState("");
  const [logisticsNotes, setLogisticsNotes] = useState("");
  const [buyerNotes, setBuyerNotes] = useState("");
  const [deliveryInstructions, setDeliveryNotes] = useState(""); // Add state for deliveryInstructions
  const [actionTaken, setActionTaken] = useState("none");
  const [collectionDate, setCollectionDate] = useState(null);
  const [collectionTime, setCollectionTime] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item) {
      setItemStatus(item.item_status || "");
      setLogisticsNotes(item.logistics_notes || "");
      setBuyerNotes(item.buyer_notes || "");
      setDeliveryNotes(item.delivery_instructions || "");
      setActionTaken(item.action_taken || "none");

      // Set collectionDate without timezone conversion
      setCollectionDate(
        item.collection_date ? moment(item.collection_date, "YYYY-MM-DD") : null
      );

      // Validate and set collectionTime
      setCollectionTime(
        item.collection_time &&
          moment(item.collection_time, "HH:mm:ss", true).isValid()
          ? moment(item.collection_time, "HH:mm:ss")
          : null
      ); // Set to null if invalid
    }
  }, [item]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const token = getCookie("supplier-token");

      // Format collectionDate as 'YYYY-MM-DD' to avoid timezone issues
      const formattedCollectionDate = collectionDate ? collectionDate : null;

      // Format collectionTime as 'HH:mm:ss'
      const formattedCollectionTime = collectionTime
        ? moment(collectionTime).format("HH:mm:ss")
        : null;

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-update-orderitem/${item.order_item_id}`,
        {
          orderItemId: item.order_item_id,
          newStatus: itemStatus,
          logisticsNotes: logisticsNotes,
          buyerNotes: buyerNotes,
          deliveryInstructions: deliveryInstructions, // Include deliveryInstructions in the request
          actionTaken: actionTaken,
          collectionDate: formattedCollectionDate, // Use formatted date
          collectionTime: formattedCollectionTime,
        },
        { headers: { Authorization: token } }
      );

      message.success(response.data.message);
      onUpdate(); // Call the parent component to refresh the data
      onClose();
    } catch (error) {
      console.error("Error updating order item:", error);
      message.error("Failed to update order item.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`Update Item - ${item?.name || ""}`}
      open={visible}
      onCancel={onClose}
      onOk={handleUpdate}
      confirmLoading={loading}
      destroyOnClose
      width={800}
    >
      {!item ? (
        <p>Loading...</p>
      ) : (
        <div>
          <Row gutter={16}>
            <Col span={12}>
              <h3>Logistics</h3>
              <div>
                <label>Item Status:</label>
                <Select
                  value={itemStatus}
                  onChange={setItemStatus}
                  style={{ width: "100%" }}
                >
                  <Option value="pending">Pending</Option>
                  <Option value="processing">Processing</Option>
                  <Option value="out-of-stock">Out of stock</Option>
                  <Option value="ready-for-collection">
                    Ready for Collection
                  </Option>
                </Select>
              </div>
              <br />

              <div>
                <label>Collection Date:</label>
                <DatePicker
                  value={moment(collectionDate, "YYYY-MM-DD")}
                  onChange={(date) => {
                    console.log("Date:", date);
                    if (date && typeof date === "object" && date.isValid()) {
                      const formattedDate = date.format("YYYY-MM-DD");
                      console.log("Selected Date:", formattedDate);
                      setCollectionDate(formattedDate);
                    }
                  }}
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD"
                />
              </div>
              <div>
                <label>Collection Time:</label>
                <TimePicker
                  value={collectionTime}
                  onChange={setCollectionTime}
                  style={{ width: "100%" }}
                />
              </div>
              <br />
              <div>
                <label>Logistics Notes:</label>
                <Input.TextArea
                  value={logisticsNotes}
                  onChange={(e) => setLogisticsNotes(e.target.value)}
                />
              </div>
            </Col>
            <Col span={12}>
              <h3>Customer</h3>
              <div>
                <label>Buyer Notes:</label>
                <Input.TextArea
                  value={buyerNotes}
                  onChange={(e) => setBuyerNotes(e.target.value)}
                />
              </div>
              <br />
              <div>
                <label>Delivery Instructions:</label>
                <Input.TextArea
                  value={deliveryInstructions}
                  onChange={(e) => setDeliveryNotes(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default ItemUpdateModal;
