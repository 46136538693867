import {
  CheckCircleOutlined,
  RocketOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Image, Layout, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const { Content, Footer } = Layout;
const { Title, Paragraph, Text } = Typography;

const MarketplaceLandingPage = () => {
  return (
    <Layout>
      {/* Content Section */}
      <Content style={{ padding: "50px", backgroundColor: "#f0f2f5" }}>
        {/* Hero Section */}
        <div style={{ textAlign: "center", marginBottom: "50px" }}>
          <Title level={1} style={{ color: "#1890ff" }}>
            Your Local Marketplace for Fast, Affordable, and Reliable Delivery
          </Title>
          <Paragraph style={{ fontSize: "18px", color: "#555" }}>
            Shop locally with convenience, or sell to a growing community of
            buyers!
          </Paragraph>
          <Image
            src="https://via.placeholder.com/800x400"
            alt="Marketplace showcase"
            style={{ marginTop: "30px", borderRadius: "8px" }}
            preview={false}
          />
        </div>

        {/* Benefits Section */}
        <Row gutter={24} style={{ marginBottom: "50px" }}>
          <Col span={12}>
            <Title level={3} style={{ color: "#1890ff" }}>
              Benefits for Buyers
            </Title>
            <Card bordered={false} style={{ backgroundColor: "white" }}>
              <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "#52c41a", marginRight: "8px" }}
                  />{" "}
                  Free Delivery on qualifying orders
                </li>
                <li>
                  <RocketOutlined
                    style={{ color: "#52c41a", marginRight: "8px" }}
                  />{" "}
                  Same-Day Local Delivery
                </li>
                <li>
                  <ShoppingCartOutlined
                    style={{ color: "#52c41a", marginRight: "8px" }}
                  />{" "}
                  Wide Product Selection
                </li>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "#52c41a", marginRight: "8px" }}
                  />{" "}
                  Real-Time Tracking
                </li>
              </ul>
            </Card>
          </Col>
          <Col span={12}>
            <Title level={3} style={{ color: "#1890ff" }}>
              Benefits for Sellers
            </Title>
            <Card bordered={false} style={{ backgroundColor: "white" }}>
              <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "#52c41a", marginRight: "8px" }}
                  />{" "}
                  Affordable Delivery Costs
                </li>
                <li>
                  <TeamOutlined
                    style={{ color: "#52c41a", marginRight: "8px" }}
                  />{" "}
                  Large Customer Reach
                </li>
                <li>
                  <CheckCircleOutlined
                    style={{ color: "#52c41a", marginRight: "8px" }}
                  />{" "}
                  Easy Management Tools
                </li>
                <li>
                  <RocketOutlined
                    style={{ color: "#52c41a", marginRight: "8px" }}
                  />{" "}
                  Flexible Shipping Options
                </li>
              </ul>
            </Card>
          </Col>
        </Row>

        {/* How It Works Section */}
        <Title
          level={2}
          style={{
            color: "#1890ff",
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          How It Works
        </Title>
        <Row gutter={24} style={{ textAlign: "center", marginBottom: "50px" }}>
          <Col span={12}>
            <Title level={3} style={{ color: "#555" }}>
              For Buyers
            </Title>
            <Card bordered={false} style={{ backgroundColor: "white" }}>
              <ol style={{ paddingLeft: "20px", textAlign: "left" }}>
                <li>Browse Local Products</li>
                <li>Order and Check Out</li>
                <li>Track Your Delivery</li>
              </ol>
            </Card>
          </Col>
          <Col span={12}>
            <Title level={3} style={{ color: "#555" }}>
              For Sellers
            </Title>
            <Card bordered={false} style={{ backgroundColor: "white" }}>
              <ol style={{ paddingLeft: "20px", textAlign: "left" }}>
                <li>Register and Set Up Shop</li>
                <li>Promote and Sell</li>
                <li>Handle Orders Seamlessly</li>
              </ol>
            </Card>
          </Col>
        </Row>

        {/* Call-to-Action Banner */}
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Title level={2} style={{ color: "#1890ff" }}>
            Ready to Join the Marketplace?
          </Title>
          <Paragraph style={{ color: "#555", fontSize: "16px" }}>
            Experience fast and reliable local delivery with QikTruck.
          </Paragraph>
          <Button type="primary" size="large" style={{ margin: "0 10px" }}>
            <Link to="/supplier/signup">Sign Up as a Seller</Link>
          </Button>
          <Button size="large" style={{ margin: "0 10px" }}>
            <Link to="/">Start Shopping</Link>
          </Button>
        </div>
      </Content>

      {/* Footer */}
      <Footer
        style={{
          textAlign: "center",
          backgroundColor: "#f0f2f5",
          padding: "20px",
        }}
      >
        <Text>© 2024 QikTruck. All rights reserved.</Text>
        <Row justify="center" style={{ marginTop: "10px" }}>
          <Text style={{ margin: "0 15px" }}>Privacy Policy</Text>
          <Text style={{ margin: "0 15px" }}>Terms of Service</Text>
          <Text style={{ margin: "0 15px" }}>Contact Us</Text>
        </Row>
      </Footer>
    </Layout>
  );
};

export default MarketplaceLandingPage;
