import { Button, Spin, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "./cookieUtils";
import moment from "moment";

const BuyerOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const token = getCookie("buyer-token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/buyer-orders`,
          {
            headers: { Authorization: token },
          }
        );
        setOrders(response.data.orders);
      } catch (error) {
        console.error("Error fetching orders", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      render: (orderId) => orderId.split("-")[0],
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (totalAmount) => `R ${totalAmount}`,
    },
    {
      title: "Date Ordered",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt) => moment(createdAt).format("DD MMM YYYY"),
    },
    {
      title: "Date Delivered",
      dataIndex: "delivered_at",
      key: "delivered_at",
      render: (deliveredAt) =>
        deliveredAt
          ? moment(deliveredAt).format("DD MMM YYYY")
          : "Not delivered",
    },
    {
      title: "Items",
      dataIndex: "item_count",
      key: "item_count",
      render: (itemCount) => `${itemCount} items`,
    },
    {
      title: "Delivered",
      dataIndex: "delivered_count",
      key: "delivered_count",
      render: (deliveredCount) => `${deliveredCount} delivered`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => navigate(record.order_link)} // Navigate to the individual order details page
        >
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h1>Your Orders</h1>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          columns={columns}
          dataSource={orders}
          rowKey={(record, index) => `${record.order_id}-${index}`}
          pagination={{ pageSize: 10 }}
        />
      )}
    </div>
  );
};

export default BuyerOrders;
