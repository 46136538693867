import { ExclamationCircleOutlined } from "@ant-design/icons"; // Import the icon for the confirmation modal
import {
  Button,
  Card,
  Col,
  Drawer,
  Modal,
  Row,
  Spin,
  Table,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCookie } from "./cookieUtils";

const OrderDetails = () => {
  const { orderId } = useParams(); // Get the order ID from the URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Start as loading
  const [error, setError] = useState(null); // State for error handling
  const [drawerVisible, setDrawerVisible] = useState(false); // State for drawer visibility
  const [vehicleData, setVehicleData] = useState(null); // Vehicle data from the API
  const navigate = useNavigate();
  const [deliveryInstructions, setDeliveryInstructions] = useState("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true); // Set loading to true at the start of the request
      setError(null); // Reset error state
      try {
        const token = getCookie("buyer-token");

        if (!token) {
          message.error("You need to be logged in to view this page.");
          navigate("/login");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/buyer-orders/${orderId}`,
          {
            headers: { Authorization: token },
          }
        );
        setOrderDetails(response.data.order);
        setDeliveryInstructions(response.data.order.delivery_instructions);
      } catch (error) {
        console.error("Error fetching order details:", error);
        setError("Failed to fetch order details. Please try again later.");
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    fetchOrderDetails();
  }, [orderId, navigate]);

  const fetchVehicleLocation = async () => {
    try {
      const token = getCookie("buyer-token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/vehicle-location/${orderId}`,
        {
          headers: { Authorization: token },
        }
      );
      setVehicleData(response.data); // Set vehicle data for map rendering
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
      message.error("Failed to fetch vehicle data.");
    }
  };

  const openTrackDrawer = async () => {
    setDrawerVisible(true);
    await fetchVehicleLocation(); // Fetch vehicle data when the drawer opens
  };

  const cancelOrder = async () => {
    // Confirm cancellation with a warning
    Modal.confirm({
      title: "Are you sure you want to cancel this order?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Please note that cancelling an order may result in refund processing delays.",
      okText: "Yes, Cancel",
      okType: "danger",
      cancelText: "No, Keep Order",
      onOk: async () => {
        try {
          const token = getCookie("buyer-token");
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/marketplace/buyer-cancel-order/${orderId}`,
            {},
            {
              headers: { Authorization: token },
            }
          );
          message.success(response.data.message);
          window.location.href = "/buyer/orders";
        } catch (error) {
          console.error("Error canceling order:", error);
          message.error("Failed to cancel order.");
        }
      },
      onCancel() {
        console.log("Order cancellation aborted");
      },
    });
  };

  const columns = [
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
      // Render the product name as a link to the product details page
      render: (name, record) => (
        <Link to={`/product/?productId=${record.product_id}`}>{name}</Link>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) =>
        `R ${(price && parseFloat(price).toFixed(2)) || "0.00"}`,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Status",
      dataIndex: "item_status",
      key: "item_status",
      render: (status) => status || "Not delivered",
    },
  ];

  if (loading) {
    return (
      <div style={{ padding: 16 }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: 16 }}>
        <Card
          title="Error"
          bordered={false}
          style={{ width: "100%", backgroundColor: "#ffe6e6" }}
        >
          <p>{error}</p>
        </Card>
      </div>
    );
  }

  return (
    <div style={{ padding: 5 }}>
      <Row gutter={16}>
        <Col md={12} sm={24}>
          <h1>Order ID: {orderId?.split("-")[0]}</h1>
          <Card bordered={false}>
            <strong>Status:</strong> {orderDetails.status}
            {orderDetails.status === "pending" && (
              <p style={{ color: "orange" }}>
                <strong>Details:</strong> Your order is still pending.
              </p>
            )}
            {orderDetails.status === "paid" && (
              <p style={{ color: "green" }}>
                <strong>Paid:</strong> The payment has been processed.
              </p>
            )}
            <p>
              <strong>Delivery Date:</strong>{" "}
              {moment(orderDetails.delivery_date).format("MMMM Do YYYY") ||
                "Not available"}
            </p>
            <p>
              <strong>Total Amount:</strong> R{" "}
              {orderDetails.total_amount
                ? parseFloat(orderDetails.total_amount).toFixed(2)
                : "0.00"}
            </p>
            <p>
              <strong>Items in this Order:</strong>{" "}
              {orderDetails.item_count || 0}
            </p>
            <p>
              <strong>Items Delivered:</strong>{" "}
              {orderDetails.delivered_count || 0}
            </p>
          </Card>
        </Col>
        <Col md={12} sm={24}>
          <h1>Shipping & Processing</h1>
          <Card title="Shipping Address" bordered={false}>
            {orderDetails.delivery_address || "Not available"}
            <br />

            <br />
            <br />
            <Button
              type="primary"
              style={{ marginRight: 8 }}
              onClick={openTrackDrawer}
            >
              Track
            </Button>
            {orderDetails.status !== "delivered" && (
              <Button
                type="danger"
                onClick={cancelOrder}
                style={{ marginLeft: 8 }}
              >
                Cancel Order
              </Button>
            )}
          </Card>
        </Col>

        <Col md={12} sm={24}>
          <h1>Payment</h1>
          <Card bordered={false}>
            <strong>Payment Method:</strong> {orderDetails.payment_method}
            <p>
              <strong>Payment Status:</strong> {orderDetails.payment_status}
            </p>
          </Card>
        </Col>
        <Col md={12} sm={24}>
          <h1>Delivery Instructions</h1>
          <Card bordered={false}>
            <TextArea
              rows={4}
              defaultValue={
                orderDetails?.delivery_instructions ||
                "No delivery instructions"
              }
              onChange={(e) => setDeliveryInstructions(e.target.value)}
            />
            <br />
            {deliveryInstructions !== orderDetails?.delivery_instructions && (
              <Button type="primary" style={{ marginTop: 8 }}>
                Save Instructions
              </Button>
            )}
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} sm={24}>
          <h1> Order Items</h1>
          <Table
            columns={columns}
            dataSource={orderDetails.items || []}
            rowKey={(record, index) =>`${record.order_item_id}-${index}`}
            pagination={false}
          />
        </Col>
      </Row>

      {/* Track Drawer */}
      <Drawer
        title="Track Your Order"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={800}
      >
        {vehicleData ? (
          <div>
            <h3>Vehicle Status: {vehicleData.status}</h3>
            <MapContainer
              center={[
                vehicleData.coordinates.lat,
                vehicleData.coordinates.lng,
              ]}
              zoom={13}
              style={{ height: "400px", width: "100%" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[
                  vehicleData.coordinates.lat,
                  vehicleData.coordinates.lng,
                ]}
              >
                <Popup>{vehicleData.status}</Popup>
              </Marker>
              <Polyline
                positions={[
                  [
                    vehicleData.supplier_coordinates.lat,
                    vehicleData.supplier_coordinates.lng,
                  ],
                  [
                    vehicleData.delivery_coordinates.lat,
                    vehicleData.delivery_coordinates.lng,
                  ],
                ]}
                color="blue"
              />
            </MapContainer>
          </div>
        ) : (
          <Spin size="large" />
        )}
      </Drawer>
    </div>
  );
};

export default OrderDetails;
