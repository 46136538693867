import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Descriptions,
  Input,
  message,
  Space,
  Spin,
  Table,
  Typography,
  Row,
  Col,
} from "antd";
import axios from "axios";
import moment from "moment"; // For date formatting
import React, { useEffect, useState } from "react";
import { getCookie } from "./cookieUtils";
import SupplierOrderItems from "./SupplierOrderItems";

const { Title } = Typography;

const SupplierOrders = () => {
  const [supplierData, setSupplierData] = useState(null);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openOrderItems, setOpenOrderItems] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderSummary, setOrderSummary] = useState({
    totalOrders: 0,
    pendingOrders: 0,
    acceptedOrders: 0,
    totalRevenue: 0,
    averageOrderValue: 0,
    cancelledOrders: 0,
  });

  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const token = getCookie("supplier-token");
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/marketplace/supplier-profile",
          {
            headers: { Authorization: token },
          }
        );
        setSupplierData(response.data);
      } catch (error) {
        console.error("Error fetching supplier data", error);
        message.error("Failed to fetch supplier data.");
      }
    };

    const fetchOrders = async () => {
      setLoading(true);
      try {
        const token = getCookie("supplier-token");
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/marketplace/supplier-get-orders",
          {
            headers: { Authorization: token },
          }
        );
        const fetchedOrders = response.data.orders;
        setOrders(fetchedOrders);
        setFilteredOrders(fetchedOrders); // Initialize filtered orders

        // Calculate order summary
        const totalOrders = fetchedOrders.length;
        const pendingOrders = fetchedOrders.filter(
          (order) => order.status === "pending"
        ).length;
        const acceptedOrders = fetchedOrders.filter(
          (order) => order.status === "accepted"
        ).length;
        const cancelledOrders = fetchedOrders.filter(
          (order) => order.status === "cancelled"
        ).length;
        const totalRevenue = fetchedOrders
          .reduce(
            (total, order) =>
              total + parseFloat(order.supplier_total_amount, 0),
            0
          )
          .toFixed(2);
        const averageOrderValue =
          totalOrders > 0 ? totalRevenue / totalOrders : 0;

        setOrderSummary({
          totalOrders,
          pendingOrders,
          acceptedOrders,
          totalRevenue,
          averageOrderValue,
          cancelledOrders,
        });
      } catch (error) {
        console.error("Error fetching orders", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSupplierData();
    fetchOrders();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = orders.filter(
      (order) =>
        order.order_id.toLowerCase().includes(value.toLowerCase()) ||
        order.status.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  const showDrawer = async (order) => {
    setSelectedOrder(order);
    setOpenOrderItems(true);
    console.log("Selected Order", order);
    console.log("Open Order Items", openOrderItems);
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      render: (orderId) => (
        <div onClick={() => showDrawer({ order_id: orderId })}>
          {orderId?.split("-")[0]}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status === "pending") {
          return <span style={{ color: "orange" }}>{status}</span>;
        } else if (status === "accepted") {
          return <span style={{ color: "green" }}>{status}</span>;
        } else if (status === "declined") {
          return <span style={{ color: "red" }}>{status}</span>;
        } else {
          return <span>{status}</span>;
        }
      },
    },
    {
      title: "Total Amount",
      dataIndex: "supplier_total_amount",
      key: "supplier_total_amount",
      render: (amount) => `${amount}`,
    },
    {
      title: "Date Ordered",
      dataIndex: "date_ordered",
      key: "date_ordered",
      render: (date_ordered) => {
        let date = moment(date_ordered).format("YYYY-MM-DD");
        let timeleft = moment(date).diff(moment(), "days");

        if (timeleft < 0) {
          timeleft = (
            <span style={{ color: "red" }}>
              {moment(date_ordered).diff(moment(), "days") * -1} days ago
            </span>
          );
        } else if (timeleft === 0) {
          timeleft = <span style={{ color: "orange" }}>Today</span>;
        }

        return (
          <>
            {moment(date_ordered).format("YYYY-MM-DD")} -{" "}
            <span style={{ fontSize: "10px" }}>{timeleft}</span>
          </>
        );
      },
    },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (deliveryDate, record) => {
        let date = moment(deliveryDate).format("YYYY-MM-DD");
        let timeleft = moment(date).diff(moment(), "days");

        if (timeleft < 0) {
          timeleft = (
            <p style={{ color: "red" }}>
              {moment(deliveryDate).diff(moment(), "days") * -1} days ago
            </p>
          );
        } else if (timeleft === 0) {
          timeleft = <p style={{ color: "orange" }}>Today</p>;
        } else timeleft = <p style={{ color: "green" }}>{timeleft} days</p>;

        return (
          <>
            {moment(deliveryDate).format("YYYY-MM-DD")} -{" "}
            <span style={{ fontSize: "10px" }}>{timeleft}</span>
          </>
        );
      },
    },
    {
      title: "Number of Items",
      key: "supplier_item_count",
      dataIndex: "supplier_item_count",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => showDrawer(record)}>
            View Details
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div>
        <Title level={3}>Orders Received - {supplierData?.business_name}</Title>
        <Row gutter={16}>
          <Col md={12} sm={24} xs={24}>
            {/* Order Summary Section */}
            <Card className="order-summary">
              <Descriptions bordered column={1}>
                <Descriptions.Item label="Pending Orders">
                  {orderSummary.pendingOrders}
                </Descriptions.Item>
                <Descriptions.Item label="Accepted Orders">
                  {orderSummary.acceptedOrders}
                </Descriptions.Item>
                <Descriptions.Item label="Cancelled Orders">
                  {orderSummary.cancelledOrders}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col md={12} sm={24} xs={24}>
            {/* Order Summary Section */}
            <Card className="order-summary">
              <Descriptions bordered column={1}>
                <Descriptions.Item label="Total Orders">
                  {orderSummary.totalOrders}
                </Descriptions.Item>
                <Descriptions.Item label="Total Revenue">
                  {orderSummary.totalRevenue
                    ? `${orderSummary.totalRevenue}`
                    : "0"}
                </Descriptions.Item>
                <Descriptions.Item label="Average Order Value">
                  {orderSummary.averageOrderValue
                    ? `${orderSummary.averageOrderValue.toFixed(2)}`
                    : "0"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>

        {/* Search Input */}
        <Input
          placeholder="Search orders"
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: "300px", margin: "20px 0" }}
        />

        {/* Orders Table */}
        <Table
          columns={columns}
          dataSource={filteredOrders}
          rowKey={(record, index) => `${record.order_id}-${index}`}
          pagination={{ pageSize: 20 }}
          locale={{
            emptyText: loading ? <Spin size="large" /> : <p>No orders</p>,
          }}
        />
      </div>
      {openOrderItems && (
        <SupplierOrderItems
          openOrderItems={openOrderItems}
          selectedOrder={selectedOrder}
          closeOrderItemsDrawer={() => setOpenOrderItems(false)}
        />
      )}
    </div>
  );
};

export default SupplierOrders;
