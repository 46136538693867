// ProductCreateSteps.jsx

import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Progress,
  Row,
  Select,
  Upload,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddressAutocomplete from "./AddressAutocomplete";
import { getCookie } from "./cookieUtils";

const { Option } = Select;
const { RangePicker } = DatePicker;

const tagSuggestions = [
  "electronics",
  "furniture",
  "clothing",
  "sports",
  "new",
  "popular",
];
const currencyOptions = ["ZAR", "EUR", "GBP"];

const ProductCreateSteps = () => {
  const [imageFiles, setImageFiles] = useState([]);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({}); // State for upload progress

  useEffect(() => {
    // Fetch categories from the backend when the component mounts
    const fetchCategories = async () => {
      try {
        const token = getCookie("supplier-token");
        if (!token) {
          message.error("You must be logged in to fetch categories.");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-get-product-categories`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setCategories(response.data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        const errorMessage =
          error.response?.data?.message || "Failed to load categories";
        message.error(errorMessage);
      }
    };

    fetchCategories();
  }, []);

  // Updated handleImageUpload with MIME type validation
  const handleImageUpload = ({ fileList }) => {
    const validFileTypes = ["image/jpeg", "image/png", "image/gif"]; // Define valid MIME types
    const filteredFiles = fileList.filter((file) =>
      validFileTypes.includes(file.type)
    );

    if (filteredFiles.length + imageFiles.length > 5) {
      message.error("You can only upload a maximum of 5 images.");
      return;
    }

    // Update imageFiles state with validated files
    setImageFiles(filteredFiles.map((file) => file.originFileObj));
  };

  // Updated handleSubmit to use form values directly
  const handleSubmit = async (values) => {
    try {
      // Prepare the payload
      const payload = { ...values };
      console.log("Form Data Before Processing:", payload); // Debugging

      // Handle promo_period if present
      if (payload.promo_period) {
        payload.promo_start = payload.promo_period[0].toISOString();
        payload.promo_end = payload.promo_period[1].toISOString();
        delete payload.promo_period;
      }

      // Handle JSON fields (except dimensions)
      const jsonFields = [
        "bulk_discount",
        "frequently_bought_with",
        "meta",
        "social_links",
        "regulatory_certifications",
      ];

      for (const field of jsonFields) {
        if (payload[field]) {
          try {
            const parsedValue = JSON.stringify(payload[field]);
            payload[field] = JSON.parse(parsedValue);
          } catch {
            message.error(
              `${field.replace(/_/g, " ")} must be a valid JSON string.`
            );
            return;
          }
        }
      }

      // Serialize dimensions as JSON string
      if (payload.dimensions) {
        // Validate that dimensions have height, width, and length
        let { height, width, length } = payload.dimensions;
        if (
          height === undefined ||
          width === undefined ||
          length === undefined
        ) {
          message.error("All dimensions (height, width, length) are required.");
          return;
        }

        try {
          // Validate that height, width, and length are numbers
          height = parseFloat(height);
          width = parseFloat(width);
          length = parseFloat(length);
          if (
            typeof height !== "number" ||
            typeof width !== "number" ||
            typeof length !== "number"
          ) {
            message.error("Dimensions must be numbers.");
            return;
          }
        } catch (error) {}
        // Serialize dimensions
        payload.dimensions = JSON.stringify(payload.dimensions);
      }

      console.log("Payload After Processing:", payload); // Debugging
      setLoading(true);

      // Step 1: Submit the product data
      const productResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-addproduct`,
        {
          ...payload,
          attributes: {
            device: window.navigator.platform,
            browser: window.navigator.userAgent,
            previousPage: document.referrer,
            localStorage: JSON.stringify(localStorage),
            cookies: document.cookie,
          },
        },
        {
          headers: {
            Authorization: getCookie("supplier-token"),
          },
        }
      );
      const productId = productResponse.data.productId;

      // Step 2: Upload images if any
      if (imageFiles.length > 0) {
        // Prepare file names and MIME types
        let fileNames = imageFiles.map((file) => {
          return {
            name: file.name,
            type: file.type,
          };
        });

        // Step 2a: Fetch pre-signed URLs
        const urlResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-get-uploadurls`,
          {
            fileNames,
            supplierId: payload.supplier_id, // Ensure supplier_id is present in formData
          },
          { headers: { Authorization: getCookie("supplier-token") } }
        );

        const { urls } = urlResponse.data;

        if (urls.length !== imageFiles.length) {
          throw new Error("Mismatch in the number of URLs fetched.");
        }

        // Step 2b: Upload images using pre-signed URLs
        const uploadProgressState = {}; // Temporary object to track progress

        await Promise.all(
          urls.map(async ({ uploadUrl, filePath }, index) => {
            const file = imageFiles[index];
            try {
              await axios.put(uploadUrl, file, {
                headers: {
                  "Content-Type": file.type, // Use actual MIME type
                },
                onUploadProgress: (event) => {
                  const percent = Math.round(
                    (event.loaded / event.total) * 100
                  );
                  uploadProgressState[file.name] = percent;
                  setUploadProgress((prev) => ({
                    ...prev,
                    [file.name]: percent,
                  }));
                },
              });

              // Mark upload as complete
              uploadProgressState[file.name] = 100;
              setUploadProgress((prev) => ({
                ...prev,
                [file.name]: 100,
              }));

              // Notify backend about the uploaded image
              await axios.post(
                `${process.env.REACT_APP_API_URL}/marketplace/supplier-productimages/${productId}`,
                {
                  s3ImageUrls: [
                    `https://media.qiktruck.co.za.s3.eu-west-1.amazonaws.com/${filePath}`,
                  ],
                  productId,
                },
                {
                  headers: {
                    Authorization: getCookie("supplier-token"),
                  },
                }
              );
            } catch (error) {
              console.error(`Error uploading ${file.name}:`, error);
              uploadProgressState[file.name] = "Failed";
              setUploadProgress((prev) => ({
                ...prev,
                [file.name]: "Failed",
              }));
              message.error(`Failed to upload ${file.name}`);
              // Optionally, you can throw an error here to halt the process
              // throw new Error(`Failed to upload ${file.name}`);
            }
          })
        );

        message.success("Images uploaded successfully.");
      }

      message.success("Product created successfully");
      form.resetFields();
      setImageFiles([]);
      setUploadProgress({});
      window.scrollTo(0, 0);
      setTimeout(() => {
        window.location.href = `/supplier/product-edit/${productId}`;
      }, 3000);
    } catch (error) {
      console.error("Error creating product or uploading images:", error);
      const errorMessage =
        error.response?.data?.message || "Failed to create product";
      message.error(errorMessage);
      setLoading(false);
    }
  };

  const handleAddressSelect = ({ address, lat, lng }) => {
    form.setFieldsValue({ address, latitude: lat, longitude: lng });
  };

  return (
    <div
      style={{
        maxWidth: 1000,
        margin: "0 auto",
        padding: "0px",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        disabled={loading}
        onFinishFailed={() => {
          message.error("Please fill in all required fields.");
        }}
      >
        <Row>
          <Col
            xs={24}
            md={24}
            lg={24}
            xl={24}
            key="Basic Information"
            style={{ marginBottom: 20 }}
          >
            <Card
              title="Basic Information"
              style={{
                width: "100%",
                padding: 5,
                marginBottom: 20,
                backgroundColor: "#f0f2f5",
              }}
            >
              <p>
                Welcome to the product creation page. Please fill in the details
                below to create a new product.
              </p>
            </Card>
          </Col>
          <Col xs={24} md={12} lg={12} xl={12}>
            <Card
              title="Product Information"
              className="pinfo-card"
            >
              <Form.Item
                label="Product Name"
                name="name"
                rules={[
                  { required: true, message: "Product name is required" },
                ]}
              >
                <Input placeholder="Enter product name" />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: "Description is required" }]}
              >
                <Input.TextArea
                  placeholder="Enter product description"
                  rows={3}
                />
              </Form.Item>
              <Form.Item
                label="SKU"
                name="sku"
                rules={[{ required: true, message: "SKU is required" }]}
              >
                <Input placeholder="Enter SKU" />
              </Form.Item>
              <Form.Item
                label="Category"
                name="category_id"
                tooltip="Select the category that best fits the product"
                rules={[{ required: true, message: "Category is required" }]}
              >
                <Select placeholder="Choose a category">
                  {categories.map((category) => (
                    <Option
                      key={category.category_id}
                      value={category.category_id}
                    >
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Tags"
                name="tags"
                tooltip="Select or add relevant tags for product searchability"
              >
                <Select
                  mode="tags"
                  placeholder="Add tags (e.g., electronics, new)"
                >
                  {tagSuggestions.map((tag) => (
                    <Option key={tag} value={tag}>
                      {tag}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} md={12} lg={12} xl={12} key="Pricing ">
            <Card
              title="Pricing"
              className="pricing-card"
            >
              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: "Price is required" }]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter price"
                />
              </Form.Item>
              <Form.Item label="Sale Price" name="sale_price">
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter sale price"
                />
              </Form.Item>
              <Form.Item
                label="Currency"
                name="currency"
                initialValue="ZAR"
                rules={[{ required: true, message: "Currency is required" }]}
              >
                <Select>
                  {currencyOptions.map((currency) => (
                    <Option key={currency} value={currency}>
                      {currency}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Stock"
                name="stock"
                rules={[
                  { required: true, message: "Stock quantity is required" },
                ]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter stock quantity"
                  defaultValue={0}
                />
              </Form.Item>
              <Form.Item label="Reorder Point" name="reorder_point">
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter reorder point"
                />
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} md={12} lg={12} xl={12} key={"Dimensions & Location"}>
            <Card
              title="Dimensions & Location"
              className="pinfo-card"
            >
              <Form.Item
                label="Weight (kg)"
                name="weight"
                rules={[{ required: true, message: "Weight is required" }]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Weight"
                />
              </Form.Item>

              <div style={{ display: "flex", gap: "10px" }}>
                <Form.Item
                  name={["dimensions", "height"]} // Nested dimensions object
                  style={{ flex: 1 }}
                  rules={[{ required: true, message: "Height is required" }]}
                >
                  <div>
                    <label>H</label>
                    <InputNumber
                      placeholder="Height"
                      min={0}
                      style={{ width: "100%" }}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name={["dimensions", "width"]}
                  style={{ flex: 1 }}
                  rules={[{ required: true, message: "Width is required" }]}
                >
                  <div>
                    <label>W</label>
                    <InputNumber
                      placeholder="Width"
                      min={0}
                      style={{ width: "100%" }}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name={["dimensions", "length"]}
                  style={{ flex: 1 }}
                  rules={[{ required: true, message: "Length is required" }]}
                >
                  <div>
                    <label>L</label>
                    <InputNumber
                      placeholder="Length"
                      min={0}
                      style={{ width: "100%" }}
                    />
                  </div>
                </Form.Item>
              </div>
              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true, message: "Address is required" }]}
              >
                <AddressAutocomplete onSelectAddress={handleAddressSelect} />
              </Form.Item>
              <Form.Item
                label="Delivery Instructions"
                name="delivery_instructions"
              >
                <Input.TextArea
                  placeholder="Enter specific delivery instructions"
                  rows={2}
                />
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} md={12} lg={12} xl={12} key={"Discount"}>
            <Card
              title="Discount"
              className="pricing-card"
            >
              <Form.Item label="Bulk Discount">
                <div style={{ display: "flex", gap: "10px" }}>
                  <Form.Item
                    name={["bulk_discount", "quantity"]}
                    tooltip="Minimum quantity for discount"
                    rules={[
                      {
                        message: "Quantity is required for bulk discount",
                      },
                    ]}
                  >
                    <div>
                      <label>Quantity</label>
                      <InputNumber
                        placeholder="Quantity"
                        min={1}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    name={["bulk_discount", "discount"]}
                    tooltip="Percentage"
                    rules={[
                      {
                        message: "Discount percentage is required",
                      },
                    ]}
                  >
                    <div>
                      <label>Discount (%)</label>
                      <InputNumber
                        placeholder="Discount (%)"
                        min={0}
                        max={100}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item label="Promotion Period" name="promo_period">
                <RangePicker />
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} md={12} lg={12} xl={12} key={"Media"}>
            <Card
              title="Media"
              className="pinfo-card"
            >
              <Form.Item label="Product Images">
                <Upload
                  listType="picture"
                  beforeUpload={() => false}
                  multiple
                  onChange={handleImageUpload}
                  maxCount={5 - imageFiles.length} // Adjust maxCount based on already selected images
                  accept=".jpg,.jpeg,.png,.gif" // Restrict file types in the upload dialog
                >
                  <Button icon={<UploadOutlined />}>
                    Select Images (Max {5 - imageFiles.length})
                  </Button>
                </Upload>
              </Form.Item>
              {/* Display upload progress for each image */}
              {Object.keys(uploadProgress).length > 0 && (
                <div style={{ marginTop: "20px" }}>
                  {imageFiles.map((file) => (
                    <div key={file.name} style={{ marginBottom: "10px" }}>
                      <span>{file.name}: </span>
                      {uploadProgress[file.name] === "Failed" ? (
                        <span style={{ color: "red" }}>Upload Failed</span>
                      ) : (
                        <Progress
                          percent={
                            typeof uploadProgress[file.name] === "number"
                              ? uploadProgress[file.name]
                              : 0
                          }
                          status={
                            uploadProgress[file.name] === "Failed"
                              ? "exception"
                              : uploadProgress[file.name] === 100
                              ? "success"
                              : "active"
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <div style={{ marginTop: 24, textAlign: "center" }}>
              <Button
                type="primary"
                loading={loading}
                disabled={loading}
                htmlType="submit"
                className="orangebg"
              >
                Create Product
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ProductCreateSteps;
