import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Progress,
  Row,
  Select,
  Upload,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddressAutocomplete from "./AddressAutocomplete";
import { getCookie } from "./cookieUtils";

const { Option } = Select;
const { RangePicker } = DatePicker;

const tagSuggestions = [
  "electronics",
  "furniture",
  "clothing",
  "sports",
  "new",
  "popular",
];
const currencyOptions = ["ZAR", "EUR", "GBP"];

const ProductEditPage = () => {
  const { productId } = useParams();
  const [form] = Form.useForm();
  const [productData, setProductData] = useState({});
  const [imageFiles, setImageFiles] = useState([]);
  const [currentImages, setCurrentImages] = useState([]); // Store existing images
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({}); // State for upload progress
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const token = getCookie("supplier-token");
      if (!token) {
        message.error("You must be logged in.");
        setLoading(false); // Stop loading if not logged in
        return;
      }

      try {
        // Fetch product data
        const productResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-getproductbyid/${productId}`,
          { headers: { Authorization: token } }
        );
        const product = productResponse.data.product;
        setProductData(product);
        try {
          let images = product.images || [];
          images = images.map((image) => {
            if (image?.startsWith("http")) {
              return image;
            }
            return `${process.env.REACT_APP_API_URL}/marketplace/supplier-productimage/${productId}/${image}`;
          });
          setCurrentImages(images); // Set current images
        } catch (error) {
          console.error("Error processing images:", error);
        }

        form.setFieldsValue({
          ...product,
          price: product.price || 0,
          sale_price: product.sale_price || null,
          currency: product.currency || "ZAR",
          stock: product.stock || 0,
          reorder_point: product.reorder_point || 0,
          weight: product.weight || 0,
          dimensions: {
            height: product.dimensions?.height || 0,
            width: product.dimensions?.width || 0,
            length: product.dimensions?.length || 0,
          },
          promo_period:
            product.promo_start && product.promo_end
              ? [moment(product.promo_start), moment(product.promo_end)]
              : [],
          local_availability: product.local_availability || false,
          tags: product.tags || [],
        });

        // Fetch categories
        const categoriesResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-get-product-categories`,
          { headers: { Authorization: token } }
        );
        setCategories(categoriesResponse.data.categories);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to load product or categories.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [productId, form]);

  const handleImageUpload = ({ fileList }) => {
    const validFileTypes = ["image/jpeg", "image/png", "image/gif"]; // Define valid MIME types
    const filteredFiles = fileList.filter((file) =>
      validFileTypes.includes(file.type)
    );

    if (filteredFiles.length + currentImages.length > 5) {
      message.error("You can only upload a maximum of 5 images.");
      return;
    }

    // Update imageFiles state with validated files
    setImageFiles(filteredFiles.map((file) => file.originFileObj));
  };

  const deleteImage = async (imageUrl) => {
    try {
      const token = getCookie("supplier-token");
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-productimages`,
        {
          headers: { Authorization: token },
          data: { imageUrl, productId },
        }
      );
      setCurrentImages((prevImages) =>
        prevImages.filter((image) => image !== imageUrl)
      );
      message.success("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error);
      message.error("Failed to delete image");
    }
  };

  const handleSubmit = async () => {
    try {
      const token = getCookie("supplier-token");
      if (!token) {
        message.error("You must be logged in.");
        return;
      }

      setLoading(true);
      const formData = productData;
      if (formData.promo_period && formData.promo_period.length === 2) {
        formData.promo_start = formData.promo_period[0].toISOString();
        formData.promo_end = formData.promo_period[1].toISOString();
        delete formData.promo_period;
      }

      // Step 1: Submit product information
      await axios.put(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-update-product/${productId}`,
        formData,
        { headers: { Authorization: token } }
      );

      message.success("Product information updated successfully");
      message.loading("Uploading images...");
      console.log(imageFiles);
      // Step 2: Upload images if any
      if (imageFiles.length > 0) {
        // Step 2a: Fetch pre-signed URLs
        let fileNames = imageFiles.map((file) => {
          return {
            name: file.name,
            type: file.type,
          };
        });
        const urlResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-get-uploadurls`,
          {
            fileNames,
            supplierId: formData.supplier_id, // Ensure supplier_id is present in formData
          },
          { headers: { Authorization: token } }
        );

        const { urls } = urlResponse.data;

        if (urls.length !== imageFiles.length) {
          throw new Error("Mismatch in the number of URLs fetched.");
        }

        // Step 2b: Upload images using pre-signed URLs
        const uploadProgressState = {}; // Temporary object to track progress

        await Promise.all(
          urls.map(async ({ uploadUrl, filePath }, index) => {
            const file = imageFiles[index];
            try {
              await axios.put(uploadUrl, file, {
                headers: {
                  "Content-Type": file.type, // Use actual MIME type
                },
                onUploadProgress: (event) => {
                  const percent = Math.round(
                    (event.loaded / event.total) * 100
                  );
                  uploadProgressState[file.name] = percent;
                  setUploadProgress((prev) => ({
                    ...prev,
                    [file.name]: percent,
                  }));
                },
              });

              // Mark upload as complete
              uploadProgressState[file.name] = 100;
              setUploadProgress((prev) => ({
                ...prev,
                [file.name]: 100,
              }));
              try {
                await axios.post(
                  `${process.env.REACT_APP_API_URL}/marketplace/supplier-productimages/${productId}`,
                  {
                    s3ImageUrls: [
                      `https://media.qiktruck.co.za.s3.eu-west-1.amazonaws.com/${filePath}`,
                    ],
                    productId,
                  },
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                );
              } catch (error) {
                console.error("Error updating product images:", error);
                message.error("Failed to update product image:: " + file.name);
                throw new Error("Failed to update product image");
              }
            } catch (error) {
              console.error(`Error uploading ${file.name}:`, error);
              uploadProgressState[file.name] = "Failed";
              setUploadProgress((prev) => ({
                ...prev,
                [file.name]: "Failed",
              }));
            }
          })
        );

        // Optionally, you can update currentImages with the new image paths
        const newImageUrls = urls.map(
          ({ filePath }) =>
            `https://media.qiktruck.co.za.s3.eu-west-1.amazonaws.com/${filePath}`
        );
        setCurrentImages((prev) => [...prev, ...newImageUrls]);

        message.success("Images uploaded successfully.");
      }

      // Redirect to the product list
      // navigate("/supplier/products");
    } catch (error) {
      console.error("Error updating product or uploading images:", error);
      message.error("Failed to update product or upload images.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        maxWidth: 1300,
        margin: "0 auto",
        padding: "10px",
      }}
    >
      <div>
        <h2>Edit Product</h2>
        <Button
          type="secondary"
          onClick={() => navigate("/supplier/products")}
          className="floatright"
        >
          Back to Products
        </Button>
      </div>
      <h3>{productData.name}</h3>
      <Form
        onValuesChange={(_, allValues) => {
          // Update productData with the new values
          if (allValues.dimensions) {
            // Update the dimensions object individually if any field changes
            setProductData({
              ...productData,
              dimensions: {
                ...productData.dimensions,
              },
              bulk_discount:
                allValues.bulk_discount || productData.bulk_discount,
              ..._,
            });
          } else {
            setProductData({
              ...productData,
              ...allValues,
              ..._,
            });
          }
        }}
        initialValues={productData}
        disabled={loading}
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={() => {
          message.error("Please fill in all required fields.");
        }}
      >
        <Row>
          <Col xs={24} md={12} lg={12} xl={12} key="Basic Information">
            <Card
              title="Product Information"
              style={{ width: "100%", marginLeft: 0, padding: 5 }}
            >
              <Form.Item
                label="Product Name"
                name="name"
                rules={[
                  { required: true, message: "Product name is required" },
                ]}
              >
                <Input placeholder="Enter product name" />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: "Description is required" }]}
              >
                <Input.TextArea
                  placeholder="Enter product description"
                  rows={3}
                />
              </Form.Item>
              <Form.Item
                label="SKU"
                name="sku"
                rules={[{ required: true, message: "SKU is required" }]}
              >
                <Input placeholder="Enter SKU" />
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} md={12} lg={12} xl={12} key={"Dimensions & Location"}>
            <Card
              title="Dimensions & Location"
              style={{ width: "100%", margin: 0, marginLeft: 10, padding: 5 }}
            >
              <Form.Item
                label="Weight (kg)"
                name="weight"
                rules={[{ required: true, message: "Weight is required" }]}
              >
                <InputNumber
                  min={0.01}
                  style={{ width: "100%" }}
                  placeholder="Weight"
                />
              </Form.Item>
              <Form.Item
                label="Dimensions (cm)"
                className="dimensions"
                rules={[{ required: true, message: "Dimensions are required" }]}
              >
                <div compact>
                  <Form.Item
                    name={["dimensions", "height"]}
                    rules={[{ required: true, message: "Height is required" }]}
                    style={{ display: "inline-block", width: "33%" }}
                  >
                    H
                    <InputNumber
                      placeholder="Height"
                      min={1}
                      style={{ width: "100%" }}
                      defaultValue={productData.dimensions?.height}
                      value={productData.dimensions?.height}
                      onChange={(value) => {
                        setProductData({
                          ...productData,
                          dimensions: {
                            ...productData.dimensions,
                            height: value,
                          },
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={["dimensions", "width"]}
                    rules={[{ required: true, message: "Width is required" }]}
                    style={{ display: "inline-block", width: "33%" }}
                  >
                    W
                    <InputNumber
                      placeholder="Width"
                      min={1}
                      style={{ width: "100%" }}
                      value={productData.dimensions?.width}
                      onChange={(value) => {
                        setProductData({
                          ...productData,
                          dimensions: {
                            ...productData.dimensions,
                            width: value,
                          },
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={["dimensions", "length"]}
                    rules={[{ required: true, message: "Length is required" }]}
                    style={{ display: "inline-block", width: "33%" }}
                  >
                    L
                    <InputNumber
                      placeholder="Length"
                      min={1}
                      style={{ width: "100%" }}
                      value={productData.dimensions?.length}
                      onChange={(value) => {
                        setProductData({
                          ...productData,
                          dimensions: {
                            ...productData.dimensions,
                            length: value,
                          },
                        });
                      }}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                label={
                  productData.address ? productData.address + " (Current)" : ""
                }
                name="address"
                rules={[{ required: true, message: "Address is required" }]}
              >
                <AddressAutocomplete
                  placeholder="Enter product location"
                  onSelectAddress={(value) => {
                    setProductData({ ...productData, ...value });
                  }}
                />
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} md={12} lg={12} xl={12} key="Pricing ">
            <Card
              title="Pricing"
              style={{ width: "100%", marginTop: 10, padding: 5 }}
            >
              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: "Price is required" }]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter price"
                />
              </Form.Item>
              <Form.Item label="Sale Price" name="sale_price">
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter sale price"
                />
              </Form.Item>
              <Form.Item
                label="Currency"
                name="currency"
                initialValue="ZAR"
                rules={[{ required: true, message: "Currency is required" }]}
              >
                <Select>
                  {currencyOptions.map((currency) => (
                    <Option key={currency} value={currency}>
                      {currency}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} md={12} lg={12} xl={12} key="Pricing & Stock">
            <Card
              title="Stock"
              style={{
                width: "100%",
                marginTop: 10,
                marginLeft: 10,
                padding: 5,
              }}
            >
              <Form.Item
                label="Stock"
                name="stock"
                rules={[
                  { required: true, message: "Stock quantity is required" },
                ]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter stock quantity"
                />
              </Form.Item>

              <Form.Item
                label="Lead Time (days)"
                name="lead_time"
                rules={[{ required: true, message: "Lead time is required" }]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter lead time in days"
                />
              </Form.Item>
              <Form.Item label="Reorder Point" name="reorder_point">
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter reorder point"
                />
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} md={12} lg={12} xl={12} key="Category">
            <Card
              title="Category"
              style={{ width: "100%", marginTop: 10, padding: 5 }}
            >
              <Form.Item
                label="Category"
                name="category_id"
                rules={[{ required: true, message: "Category is required" }]}
              >
                <Select placeholder="Choose a category">
                  {categories.map((category) => (
                    <Option
                      key={category.category_id}
                      value={category.category_id}
                    >
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Tags" name="tags">
                <Select
                  mode="tags"
                  placeholder="Add tags (e.g., electronics, new)"
                >
                  {tagSuggestions.map((tag) => (
                    <Option key={tag} value={tag}>
                      {tag}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} md={12} lg={12} xl={12} key={"Discount"}>
            <Card
              title="Discount"
              style={{
                width: "100%",
                marginTop: 10,
                marginLeft: 10,
                padding: 5,
              }}
            >
              <Form.Item label="Bulk Discount">
                <div compact>
                  <Form.Item name={["bulk_discount", "quantity"]}>
                    <InputNumber
                      placeholder="Quantity"
                      min={1}
                      style={{ width: "50%" }}
                    />
                  </Form.Item>
                  <Form.Item name={["bulk_discount", "discount"]}>
                    <InputNumber
                      placeholder="Discount (%)"
                      min={0}
                      max={100}
                      style={{ width: "50%" }}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item label="Promotion Period" name="promo_period">
                <RangePicker />
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} md={12} lg={12} xl={12} key={"Delivery"}>
            <Card
              title="Delivery"
              style={{ width: "100%", marginTop: 10, padding: 5 }}
            >
              <Form.Item
                label="Delivery Instructions"
                name="delivery_instructions"
              >
                <Input.TextArea
                  placeholder="Enter specific delivery instructions"
                  rows={2}
                />
              </Form.Item>
              <Form.Item
                label="Max Delivery Radius (km)"
                name="max_delivery_radius"
                rules={[
                  {
                    required: true,
                    message: "Max delivery radius is required",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter max delivery radius in km"
                />
              </Form.Item>
              <Form.Item
                label="Handling Time (days)"
                name="handling_time"
                rules={[
                  { required: true, message: "Handling time is required" },
                ]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Enter handling time in days"
                />
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} md={12} lg={12} xl={12} key={"Media"}>
            <Card
              title="Media"
              style={{
                width: "100%",
                marginTop: 10,
                marginLeft: 10,
                padding: 5,
              }}
            >
              <Form.Item label="Current Images">
                <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  {currentImages.map((imageUrl, index) => (
                    <div
                      key={imageUrl + index}
                      style={{ position: "relative" }}
                    >
                      <img
                        src={imageUrl?.replace(
                          ".s3.eu-west-1.amazonaws.com",
                          ""
                        )}
                        alt="Product"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                      <Button
                        icon={<DeleteOutlined />}
                        size="small"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          background: "red",
                          color: "white",
                        }}
                        onClick={() => deleteImage(imageUrl)}
                      />
                    </div>
                  ))}
                </div>
              </Form.Item>
              <Form.Item label="Upload New Images">
                <Upload
                  listType="picture"
                  beforeUpload={() => false}
                  multiple
                  onChange={handleImageUpload}
                  maxCount={5 - currentImages.length}
                  accept=".jpg,.jpeg,.png,.gif" // Restrict file types in the upload dialog
                >
                  <Button icon={<UploadOutlined />}>
                    Select Images (Max {5 - currentImages.length})
                  </Button>
                </Upload>
              </Form.Item>
              {/* Display upload progress for each image */}
              {Object.keys(uploadProgress).length > 0 && (
                <div style={{ marginTop: "20px" }}>
                  {imageFiles.map((file) => (
                    <div key={file.name} style={{ marginBottom: "10px" }}>
                      <span>{file.name}: </span>
                      {uploadProgress[file.name] === "Failed" ? (
                        <span style={{ color: "red" }}>Upload Failed</span>
                      ) : (
                        <Progress
                          percent={
                            typeof uploadProgress[file.name] === "number"
                              ? uploadProgress[file.name]
                              : 0
                          }
                          status={
                            uploadProgress[file.name] === "Failed"
                              ? "exception"
                              : uploadProgress[file.name] === 100
                              ? "success"
                              : "active"
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Col>
        </Row>
        <div style={{ marginTop: 24, textAlign: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            className="orangebg"
          >
            Submit
          </Button>
          {
            //show all errors
            form.getFieldsError().map((error) => {
              return (
                <div key={error.name}>
                  {error.errors.map((err) => (
                    <p key={err}>{err}</p>
                  ))}
                </div>
              );
            })
          }
        </div>
      </Form>
    </div>
  );
};

export default ProductEditPage;
