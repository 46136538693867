// ErrorBoundary.jsx
import React from "react";
import { Result, Button } from "antd";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to display fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error details (can be sent to an error reporting service)
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="error"
          title="Something went wrong."
          extra={
            <>
              <Button type="primary" onClick={this.handleReload}>
                Reload Page
              </Button>
              <p>
                If the problem persists, please contact support. at
                <a href="mailto:support@qiktruck.co.za">Support Email</a>
              </p>
            </>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
