// src/components/ForgotPassword.js

import { Button, Form, Input, message } from "antd";
import axios from "axios";
import React, { useState } from "react";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/send-login-link`,
        values
      );
      message.success(response.data.message);
    } catch (error) {
      message.error(
        error.response?.data?.message || "Unable to send login link"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      name="forgot_password"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      className="login-form"
    >
      <h2>Request Login Link</h2>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, type: "email", message: "Please enter your email" },
        ]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Send Login Link
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ForgotPassword;
