import { Card, Col, Layout, Row, Skeleton, Typography } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./LandingPage.scss";
import ProductListingCard from "./ProductListingCard";
import { getCookie } from "./cookieUtils";
const { Content, Footer } = Layout;
const { Title, Text } = Typography;

const LandingPage = () => {
  const [categories, setCategories] = useState([]);
  const [allcategories, setAllCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(
    "1c22dcaa-5738-4cd3-a170-38a25adccf9b"
  );
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const location = useLocation();
  const [buyerProfile, setBuyerProfile] = useState({});
  const navigate = useNavigate();

  const handleSearch = useCallback(
    async (
      term = searchText,
      categoryId = selectedCategory && selectedCategory === "all"
        ? undefined
        : selectedCategory
    ) => {
      setLoading(true);
      categoryId = categoryId === "all" ? undefined : categoryId;
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/marketplace/search-products",
          {
            params: { searchText: term, categoryId, limit: 50 },
          }
        );
        setFilteredProducts(response.data.products);
      } catch (error) {
        console.error("Error during search", error);
      } finally {
        setLoading(false);
      }
    },
    [searchText, selectedCategory]
  );

  useEffect(() => {
    fetchCategories();
    handleSearch();
    try {
      if (getCookie("buyerprofile")) {
        setBuyerProfile(JSON.parse(getCookie("buyerprofile")));
      }
    } catch {}
  }, [handleSearch]);

  useEffect(() => {
    // Parse query params to set initial search values if present
    const queryParams = new URLSearchParams(location.search);
    const term = queryParams.get("term");
    const categoryId = queryParams.get("categoryId");
    const categoryName = queryParams.get("categoryname");

    if (term) setSearchText(term);
    if (categoryId) setSelectedCategory(categoryId);
    if (categoryName) setSelectedCategoryName(categoryName);
    if (term || categoryId) handleSearch(term, categoryId);
  }, [location.search, handleSearch]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          "/marketplace/get-categories-with-products"
      );
      setCategories(response.data.categories);
      setAllCategories(response.data.allcategories);
    } catch (error) {
      console.error("Error fetching categories", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleCategoryChange = async (value) => {
  //   setSelectedCategory(value);
  //   setLoading(true);
  //   // Trigger product filtering or fetching based on selected category
  //   try {
  //     const response = await axios.get(
  //       process.env.REACT_APP_API_URL + "/marketplace/search-products",
  //       {
  //         params: { searchText: "", categoryId: value, limit: 5 },
  //       }
  //     );
  //     setFilteredProducts(response.data.products);
  //   } catch (error) {
  //     console.error("Error fetching suggestions", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const generateCategoryClass = (categoryName) => {
    // Normalize category name to create valid class names
    return categoryName
      .toLowerCase()
      .replace(/&/g, "and")
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  };
  return (
    <Layout className="landing-page">
      {/* Content */}
      <Content className="landing-content">
        {/* Featured Categories */}
        <Title level={2} style={{ textAlign: "center", margin: "20px 0" }}>
          {loading && <Skeleton size="small" />}
          {buyerProfile && buyerProfile.firstname && (
            <>
              Welcome
              <Text strong>{buyerProfile.firstname}</Text>
            </>
          )}
        </Title>
        {/* Product Listings */}
        <Title level={3} style={{ textAlign: "center", margin: "30px 0" }}>
          Products in{" "}
          <span style={{ textTransform: "capitalize", color: "#1890ff" }}>
            {selectedCategoryName}
          </span>{" "}
          category ({filteredProducts.length})
        </Title>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Skeleton size="large" />
          </div>
        ) : (
          <Row gutter={[16, 16]} justify="center">
            {loading && (
              <div style={{ textAlign: "center" }}>
                <Skeleton size="large" />
              </div>
            )}
            {filteredProducts.map((product) => (
              <Col xs={12} sm={12} md={4} lg={3} key={product?.product_id}>
                <ProductListingCard product={product} showCartButton />
              </Col>
            ))}
          </Row>
        )}
        {allcategories?.length > 0 && (
          <h4 style={{ textAlign: "center", margin: "20px 0" }}>
            {" "}
            All categories{" "}
          </h4>
        )}
        <br />
        <Row gutter={[16, 16]} justify="center">
          {allcategories?.map((category) => (
            <Col
              className={"text-center " + category.name}
              xs={12}
              sm={12}
              md={8}
              lg={3}
              key={category.category_id}
              onClick={() => {
                //update selected category in url
                // window.history.pushState(
                //   {},
                //   "",
                //   `/?categoryId=${category.category_id}`
                // );
                // handleCategoryChange(category.category_id);
                // window.location.href = `/?categoryId=${category.category_id}`;
                navigate(
                  "/category/?categoryId=" +
                    category.category_id +
                    "&categoryname=" +
                    category.name
                );
              }}
            >
              <Card
                hoverable
                className={`category-card ${
                  selectedCategory === category.category_id ? "active" : ""
                } ${generateCategoryClass(category.name)}`}
                style={{
                  cursor: "pointer",
                  background: `url(/images/${category.name
                    ?.replace(/\s/g, "")
                    ?.toLowerCase()}.jpg)`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></Card>
              <Title
                level={5}
              >
                {category.name}
              </Title>
            </Col>
          ))}
        </Row>

        {categories?.length > 0 && (
          <h4 style={{ textAlign: "center", margin: "20px 0" }}>
            Active categories
          </h4>
        )}
        <br />
        <Row gutter={[16, 16]} justify="center">
          {categories.map((category) => (
            <Col
              className={"text-center " + category.name}
              xs={12}
              sm={12}
              md={8}
              lg={3}
              key={category.category_id}
              onClick={() => {
                //update selected category in url
                // window.history.pushState(
                //   {},
                //   "",
                //   `/?categoryId=${category.category_id}`
                // );
                // handleCategoryChange(category.category_id);
                // window.location.href = `/?categoryId=${category.category_id}`;
                navigate(
                  "/category/?categoryId=" +
                    category.category_id +
                    "&categoryname=" +
                    category.name
                );
              }}
            >
              <Card
                hoverable
                className={`category-card ${
                  selectedCategory === category.category_id ? "active" : ""
                } ${generateCategoryClass(category.name)}`}
                style={{
                  cursor: "pointer",
                  background: `url(/images/${category.name
                    ?.replace(/\s/g, "")
                    ?.toLowerCase()}.jpg)`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></Card>
              <Title
                level={5}
              >
                {category.name}
              </Title>
            </Col>
          ))}
        </Row>
      </Content>

      {/* Footer */}
      <Footer className="landing-footer">
        <Text>© 2024 QikTruck Marketplace. All rights reserved.</Text>
      </Footer>
    </Layout>
  );
};

export default LandingPage;
