// src/components/PrivateRoute.js

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "./cookieUtils";

const PrivateRoute = () => {
  const isAuthenticated =
    !!getCookie("supplier-token") ||
    !!getCookie("buyer-token");
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
