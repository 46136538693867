// src/components/ImageBulkUpload.jsx

import {
  DeleteOutlined,
  UploadOutlined
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Divider,
  Image,
  List,
  message,
  Progress,
  Typography,
  Upload
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import { getCookie } from "./cookieUtils";

const { Title } = Typography;


/**
 * ImageBulkUpload Component
 * Allows users to select multiple images, preview them, and upload to S3 via signed URLs.
 */
const ImageBulkUpload = () => {
  const [fileList, setFileList] = useState([]); // List of selected files
  const [uploading, setUploading] = useState(false); // Uploading state
  const [uploadProgress, setUploadProgress] = useState({}); // Progress for each file
  const [uploadedFiles, setUploadedFiles] = useState([]); // List of successfully uploaded files
  const [uploadError, setUploadError] = useState(null); // Upload error
  const [signedUrls, setSignedUrls] = useState([]); // Signed URLs for each file
  const [savingImages, setSavingImages] = useState(false); // Saving images to the backend
  /**
   * Handle file selection
   */
  const handleChange = ({ file, fileList: newFileList }) => {
    console.log("File:", file);
    console.log("newFileList:", newFileList);
    setFileList(newFileList);
  };

  /**
   * Validate file before upload
   */
  const beforeUpload = (file) => {
    const isImage = file.type?.startsWith("image/");
    if (!isImage) {
      message.error(`${file?.name} is not an image file.`);
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error(`${file?.name} is larger than 5MB.`);
    }
    return false;
  };

  /**
   * Handle file removal
   */
  const handleRemove = (file) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
    // Remove progress tracking for the removed file
    setUploadProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[file.uid];
      return newProgress;
    });
  };

  /**
   * Initiate the upload process
   */
  const handleUpload = () => {
    if (fileList.length === 0) {
      message.error("No images selected for upload.");
      return;
    }

    uploadFiles();
  };

  /**
   * Upload files to the server via S3
   */
  const uploadFiles = async () => {
    setUploading(true);
    setUploadProgress({});
    setUploadError(null);
    setUploadedFiles([]);

    try {
      // Step 1: Prepare image metadata (assuming SKU is part of the context or extracted from file name)
      // For this example, we'll assume SKU is provided as part of the file name: sku-uniqueid.extension
      const imagesMetadata = fileList.map((file) => {
        const sku = file?.name;
        const fileType = file.type;
        return { sku, fileType };
      });

      // Step 2: Request signed URLs from the backend
      const signedUrlsResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-generate-signed-urls`,
        { fileNames: imagesMetadata },
        {
          headers: {
            Authorization: getCookie("supplier-token"),
            "Content-Type": "application/json",
          },
        }
      );

      if (signedUrlsResponse.data.status !== "success") {
        throw new Error(
          signedUrlsResponse.data.error || "Failed to get signed URLs."
        );
      }

      const signedUrls = signedUrlsResponse.data.urls; // Array of { sku, filename, uploadUrl, fileUrl }
      setSignedUrls(signedUrls);
    } catch (error) {
      setUploadError(
        error.response?.data?.error || "An error occurred during upload."
      );
      console.error("Upload Error:", error);
      message.error(error.message || "An error occurred during upload.");
    } finally {
      setUploading(false);
      setUploadProgress({});
    }
  };

  const uploadToS3 = async (file, signedUrl) => {
    // Step 3: Upload each image to S3 using the signed URL
    setSavingImages(true);

    const uploadPromises = fileList.map(async (file, index) => {
      console.log(
        "Uploading:",
        signedUrls.find((url) => url.originalFileName === file?.name)
      );
      if (
        !file.originFileObj ||
        !signedUrls.find((url) => url.originalFileName === file?.name)
      ) {
        return;
      }

      const {
        uploadUrl,
        fileUrl,
        originalFileName,
        productId,
        name,
        sku,
      } = signedUrls.find((url) => url.originalFileName === file?.name) || {};

      try {
        console.log("File:", file);
        console.log("signedUrls[index]:", {
          uploadUrl,
          fileUrl,
          originalFileName,
          productId,
          name,
          sku,
        });
        await axios.put(uploadUrl, file.originFileObj, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.uid]: percentCompleted,
            }));
          },
        });

        // Step 4: After successful upload, send the image URL to the backend for storage
        await axios.post(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-store-product-imageurls`,
          {
            images: [
              {
                sku: originalFileName,
                url: fileUrl,
                product_id: productId,
              },
            ],
          },
          {
            headers: {
              Authorization: getCookie("supplier-token"),
              "Content-Type": "application/json",
            },
          }
        );

        // Add to uploaded files list
        setUploadedFiles((prev) => [
          ...prev,
          {
            originalFileName,
            url: fileUrl,
            sku: sku,
            productId,
            name,
          },
        ]);
      } catch (error) {
        console.error(`Error uploading ${file?.name}:`, error);
        setUploadError(error.response?.data?.error || "Upload failed.");
        throw error; // To stop Promise.all
      }
    });

    // Execute all upload promises
    await Promise.all(uploadPromises);

    message.success("All images uploaded successfully!");
    setSavingImages(false);
    // setFileList([]); // Clear the file list after successful upload
  };

  /**
   * Reset the upload state
   */
  const handleReset = () => {
    setFileList([]);
    setUploadedFiles([]);
    setUploadError(null);
    setUploadProgress({});
    setUploading(false);
    setSignedUrls([]);
  };

  return (
    <div style={{ padding: "0px", maxWidth: "900px", margin: "0 auto" }}>
      <Title level={2}>Guidelines for Uploading Images</Title>
      <Card style={{ marginBottom: "20px" }}>
      <div style={{ marginTop: '0px' }}>
        <ol>
          <li>
            <strong>File Size:</strong> Each image must be{' '}
            <strong>less than 5MB</strong>.
          </li>
          <li>
            <strong>File Naming:</strong> Name each image using the product's
            unique SKU.
            <ul>
              <li>
                <code>SKU-0000011.png</code>
              </li>
              <li>
                <code>SKU-00343411.jpg</code>
              </li>
              <li>
                <code>SKU-1141.jpeg</code>
              </li>
              <li>
                For multiple images of the same product, add an underscore and
                number:
                <ul>
                  <li>
                    <code>SKU-0000011_1.png</code>
                  </li>
                  <li>
                    <code>SKU-00343411_2.jpg</code>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <strong>SKU Uniqueness:</strong> Ensure each SKU is unique to
            prevent confusion.
          </li>
          <li>
            <strong>Maximum Images:</strong> Upload no more than{' '}
            <strong>100 images</strong> at once.
          </li>
        </ol>
      </div>
      </Card>

      <Card>
        <Upload
          multiple
          accept="image/*"
          beforeUpload={beforeUpload}
          onChange={handleChange}
          fileList={fileList?.filter(
            (file) =>
              !signedUrls?.find((url) => url?.originalFileName === file?.name)
          )}
          onRemove={handleRemove}
          listType="picture"
          showUploadList={{ showRemoveIcon: true }}
          // list with signed urls
          itemRender={(originNode, file, currFileList) => {
            const signedUrl = signedUrls.find(
              (url) => url?.originalFileName === file?.name
            );
            if (signedUrl) {
              return (
                <List.Item>
                  {originNode}
                  <div className="ant-upload-list-item-info bulkimages">
                    {signedUrl.name} {signedUrl.price} {signedUrl.product_id}
                  </div>
                </List.Item>
              );
            }
            return originNode;
          }}
        >
          {fileList.length >= 100 ? null : (
            <Button icon={<UploadOutlined />}>Select Images</Button>
          )}
        </Upload>

        <div style={{ marginTop: "20px", paddingBottom: 30 }}>
          {signedUrls.length === 0 && (
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              icon={<UploadOutlined />}
            >
              {uploading ? "Uploading" : "Link Image SKUs"}
            </Button>
          )}
          <Button
            onClick={handleReset}
            icon={<DeleteOutlined />}
            className="floatright"
          >
            Reset
          </Button>
        </div>

        {uploading && (
          <Progress
            percent={
              Object.keys(uploadProgress).length > 0
                ? Math.round(
                    Object.values(uploadProgress).reduce((a, b) => a + b, 0) /
                      Object.keys(uploadProgress).length
                  )
                : 0
            }
            status="active"
            style={{ marginTop: "20px" }}
          />
        )}

        {uploadError && (
          <div style={{ marginTop: "20px" }}>
            <Alert
              message="Upload Error"
              description={uploadError}
              type="error"
              showIcon
            />
          </div>
        )}
      </Card>
      {signedUrls.length > 0 && (
        <Card
          style={{ marginTop: "20px", paddingBottom: 30 }}
          title="Verified Images"
        >
          <p>The following images are verified and ready to be uploaded.</p>
          {signedUrls.length > 0 &&
            signedUrls.map((url) => {
              let file = fileList.find(
                (file) => file?.name === url.originalFileName
              );
              const signedUrl = signedUrls.find(
                (url) => url.originalFileName === file?.name
              );
              if (signedUrl) {
                return (
                  <List.Item key={file.uid + url}>
                    <Image
                      src={file.thumbUrl}
                      style={{
                        width: "70px",
                      }}
                    />
                    <div className="ant-upload-list-item-info bulkimages">
                      {signedUrl.originalFileName} <br /> {signedUrl.name}{" "}
                      {signedUrl.price} {signedUrl.product_id}
                    </div>
                    <Divider />
                  </List.Item>
                );
              }
              return <></>;
            })}

          <div style={{ marginTop: "20px" }}>
            {signedUrls.length > 0 && (
              <Button
                type="primary"
                className="orangebg floatright"
                onClick={() => uploadToS3(fileList, signedUrls)}
                disabled={fileList.length === 0}
                loading={uploading || savingImages}
                icon={<UploadOutlined />}
              >
                {uploading ? "Uploading" : "Save verified images"}
              </Button>
            )}
            <p>
              <strong>Note:</strong> Images will be saved to the database after
              successful upload. Do not close the browser during the upload.
            </p>
          </div>

          {uploading && (
            <Progress
              percent={
                Object.keys(uploadProgress).length > 0
                  ? Math.round(
                      Object.values(uploadProgress).reduce((a, b) => a + b, 0) /
                        Object.keys(uploadProgress).length
                    )
                  : 0
              }
              status="active"
              style={{ marginTop: "20px" }}
            />
          )}

          {uploadError && (
            <div style={{ marginTop: "20px" }}>
              <Alert
                message="Upload Error"
                description={uploadError}
                type="error"
                showIcon
              />
            </div>
          )}
        </Card>
      )}

      {uploadedFiles.length > 0 && (
        <Card style={{ marginTop: "20px" }}>
          <Title level={4}>Uploaded Images</Title>
          <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={uploadedFiles}
            renderItem={(item) => (
              <List.Item>
                <Image
                  src={item.url?.replace(".s3.eu-west-1.amazonaws.com", "")}
                  alt={item.filename}
                  width={200}
                  placeholder={<div>Loading...</div>}
                />
                <p>{item.filename}</p>
                <p>
                  <b>SKU:</b> {item.name} {item.originalFileName}
                </p>
              </List.Item>
            )}
          />
        </Card>
      )}
    </div>
  );
};

export default ImageBulkUpload;
