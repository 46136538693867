import { Button, Image, Input, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCookie, setCookie, deleteCookie } from "./cookieUtils";

const { Title } = Typography;

const CartPage = () => {
  const [cartTotal, setCartTotal] = useState(0);
  const [cartItems, setCartItems] = useState([]);

  const removeFromCart = (product_id) => {
    try {
      let cartItems = JSON.parse(getCookie("cartItems")) || [];

      // Filter out the item to be removed
      const updatedCartItems = cartItems.filter(
        (item) => item.product_id !== product_id
      );

      // Save updated cart items to local storage
      setCookie("cartItems", JSON.stringify(updatedCartItems));
      console.log("Updated cart after removal:", updatedCartItems);
    } catch (error) {
      console.error("Error removing from cart:", error);
    }
  };
  const handleQuantityChange = (product_id, newQuantity) => {
    if (newQuantity > 0) {
      const updatedCartItems = JSON.parse(getCookie("cartItems")) || [];
      const updatedProductIndex = updatedCartItems.findIndex(
        (item) => item.product_id === product_id
      );

      if (updatedProductIndex >= 0) {
        // Update the quantity of the existing product
        updatedCartItems[updatedProductIndex].quantity = newQuantity;

        // Update local storage
        setCookie("cartItems", JSON.stringify(updatedCartItems));
      }
    } else {
      removeFromCart(product_id); // Remove product if quantity is set to 0
    }
  };

  const handleIncreaseQuantity = (product_id) => {
    const updatedCartItems = JSON.parse(getCookie("cartItems")) || [];
    const updatedProduct = updatedCartItems.find(
      (item) => item.product_id === product_id
    );

    if (updatedProduct) {
      handleQuantityChange(product_id, updatedProduct.quantity + 1); // Increment by 1
    }
  };

  const handleDecreaseQuantity = (product_id) => {
    const updatedCartItems = JSON.parse(getCookie("cartItems")) || [];
    const updatedProduct = updatedCartItems.find(
      (item) => item.product_id === product_id
    );

    if (updatedProduct && updatedProduct.quantity > 1) {
      handleQuantityChange(product_id, updatedProduct.quantity - 1); // Decrement by 1
    }
  };

  useEffect(() => {
    ///get cart items from local storage
    let cartItems = [];
    try {
      cartItems = JSON.parse(getCookie("cartItems")) || [];
      setCartItems(cartItems);
    } catch (error) {}
    // Calculate total price when cart items change
    const total = cartItems
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);
    setCartTotal(total);
  }, [cartItems]);

  return (
    <div style={{ padding: "5px" }}>
      <Title level={2}>Shopping Cart</Title>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <Table
          dataSource={cartItems}
          columns={[
            {
              title: "Product Image",
              dataIndex: "default_image",
              key: "default_image",
              render: (default_image, record) => (
                <Link to={`/product/?productId=${record.product_id}`}>
                  <Image
                    width={50}
                    src={(default_image
                      ? default_image
                      : record.images[0]
                    )?.replace(".s3.eu-west-1.amazonaws.com", "")}
                  />
                </Link>
              ),
            },
            {
              title: "Product Name",
              dataIndex: "name",
              key: "name",
              render: (name, record) => (
                <Link to={`/product/?productId=${record.product_id}`}>
                  {name}
                </Link>
              ),
            },
            {
              title: "Quantity",
              dataIndex: "quantity",
              key: "quantity",
              render: (quantity, record) => (
                <Space>
                  <Button
                    onClick={() => handleDecreaseQuantity(record.product_id)}
                    className="cart-quantity-btn"
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    min={0}
                    value={quantity}
                    onChange={(e) => {
                      if (e.target.value > 0)
                        handleQuantityChange(
                          record.product_id,
                          Number(e.target.value)
                        );
                    }}
                    style={{ minWidth: "40px", textAlign: "center" }}
                  />
                  <Button
                    onClick={() => handleIncreaseQuantity(record.product_id)}
                    className="cart-quantity-btn"
                  >
                    +
                  </Button>
                </Space>
              ),
            },
            {
              title: "Unit Price",
              dataIndex: "price",
              key: "price",
              render: (price) => `R ${price}`,
            },
            {
              title: "Action",
              key: "action",
              render: (text, record) => (
                <Button
                  onClick={() => removeFromCart(record.product_id)}
                  type="link"
                  danger
                >
                  Remove
                </Button>
              ),
            },
          ]}
          rowKey={(record, index) => `${record.product_id}-${index}`}
        />
      )}

      {getCookie("cartItems") && cartItems.length > 0 ? (
        <>
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <Title level={4}>Total: R {cartTotal}</Title>
            <Button
              type="primary"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (!getCookie("buyer-token")) {
                  window.location.href = "/buyer/login";
                  return;
                } else {
                  window.location.href = "/checkout";
                }
              }}
            >
              Proceed to checkout
            </Button>
          </div>
          <div style={{ textAlign: "left", marginTop: "20px" }}>
            <Button
              type="danger"
              onClick={() => {
                deleteCookie("cartItems");
                setCartItems([]);
              }}
            >
              clear cart
            </Button>
          </div>
        </>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Link to="/">Continue shopping</Link>
        </div>
      )}
    </div>
  );
};

export default CartPage;
