// src/components/BuyerPasswordReset.js

import React, { useState } from "react";
import { Form, Input, Button, message, Divider } from "antd";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import Logo from "./Logo";

const BuyerPasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const { token } = useParams(); // Extract token from URL
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-reset-password/`,
        {
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
          token,
        }
      );
      message.success(response.data.message);
      navigate("/supplier/login");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to reset password.";
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Logo />
      <Form
        name="buyer_password_reset"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="password-reset-form"
        style={{ maxWidth: "400px", margin: "0 auto", padding: "20px" }}
      >
        <h1>Set New Supplier Password</h1>
        <Form.Item
          label="New Supplier Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please enter your new password." },
            { min: 6, message: "Password must be at least 6 characters." },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Enter new password" />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="confirmPassword"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            { required: true, message: "Please confirm your new password." },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords do not match.")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm new password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Reset Supplier Password
          </Button>
        </Form.Item>
        <Divider />
        <Link to="/supplier/login">Back to Login</Link>
      </Form>
    </>
  );
};

export default BuyerPasswordReset;
