// src/components/AddressAutocomplete.js

import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { Input } from "antd";
import React, { useState } from "react";

const libraries = ["places"];

const AddressAutocomplete = ({ onSelectAddress, placeholder }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD2BbvjWvF73TNFX3c0BTBlN6kmNc0Rheg",
    libraries,
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState("");

  const onLoad = (autoC) => {
    setAutocomplete(autoC);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place.geometry?.location;
      if (location) {
        const lat = location.lat();
        const lng = location.lng();
        setAddress(place.formatted_address);
        onSelectAddress({ address: place.formatted_address, lat, lng });
      }
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <Input
        placeholder={placeholder || "Enter your address"}
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
    </Autocomplete>
  );
};

export default AddressAutocomplete;
