import React, { useState, useEffect } from "react";
import { Button } from "antd";

const ScrollButtonWrapper = ({
  children,
  onButtonClick,
  buttonText = "Click Me",
  buttonProps,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {children}
      {isScrolled && (
        <Button
          {...buttonProps}
          style={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 1000,
            ...buttonProps?.style,
          }}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default ScrollButtonWrapper;
