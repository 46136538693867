import {
  CheckCircleOutlined,
  LineChartOutlined,
  RocketOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Layout,
  Row,
  Typography,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import ScrollButtonWrapper from "./ScrollButtonWrapper.js";
import FAQSection from "./FAQSection.js";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const SupplierLandingPage = () => {
  return (
    <Layout className="layout-landing">
      <ScrollButtonWrapper
        buttonText="Register for free"
        buttonProps={{
          style: {
            backgroundColor: "orange",
            color: "white",
            fontWeight: "bold",
          },
        }}
        onButtonClick={() => {
          window.location.href = "/supplier/signup?from=benefits";
        }}
      >
        {/* Content Section */}
        <Content style={{ padding: "5px", backgroundColor: "#f0f2f5" }}>
          {/* Hero Section */}
          <div style={{ textAlign: "center", marginBottom: "50px" }}>
            <Title level={1} className="hero-title">
              Expand Your Reach Locally with QikMarket
            </Title>
            <Paragraph className="hero-paragraph">
              Connect with a network of local buyers and enjoy seamless delivery
              with local trucks and trusted delivery teams.
            </Paragraph>
            <Image
              src="distribution-center2.jpeg"
              alt="Marketplace showcase"
              style={{
                marginTop: "30px",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "800px",
              }}
              preview={false}
            />
          </div>

          {/* Benefits Section */}
          <Row gutter={[24, 24]} style={{ marginBottom: "50px" }}>
            <Col xs={24} md={12}>
              <Title level={3} className="benefit-title">
                Why Sell on QikMarket ?
              </Title>
              <Card bordered={false} className="benefit-card">
                {/* Illustration */}
                <Image
                  src="2sellers.jpg"
                  alt="Local Delivery Illustration"
                  preview={false}
                />
                <ul className="benefit-list">
                  <li>
                    <CheckCircleOutlined className="benefit-icon" />
                    <strong>Affordable Local Delivery</strong> — Tap into
                    QikTruck’s network of local trucks for fast, low-cost
                    delivery options.
                  </li>
                  <Divider />
                  <li>
                    <RocketOutlined className="benefit-icon" />
                    <strong>Same-Day Service</strong> — Provide buyers with
                    same-day delivery, powered by our locally-based delivery
                    teams.
                  </li>
                  <Divider />
                  <li>
                    <LineChartOutlined className="benefit-icon" />
                    <strong>Boost Your Sales</strong> — Reach more local
                    customers ready to buy, with easy access to a growing
                    network of local consumers.
                  </li>
                  <Divider />
                  <li>
                    <TeamOutlined className="benefit-icon" />
                    <strong>Powered by Local Teams</strong> — Partner with local
                    drivers and support teams who know the area, ensuring
                    reliable and timely deliveries.
                  </li>
                </ul>
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Title level={3} className="benefit-title">
                Benefits of Local Access
              </Title>
              <Card bordered={false} className="benefit-card">
                {/* Illustration */}
                <Image
                  src="cart-viewpov.jpg"
                  alt="Local Access Illustration"
                  preview={false}
                />
                <ul className="benefit-list">
                  <li>
                    <CheckCircleOutlined className="benefit-icon" />
                    <strong>Local Reach</strong> — Your products become
                    instantly available to buyers in nearby neighborhoods and
                    communities.
                  </li>
                  <Divider />
                  <li>
                    <RocketOutlined className="benefit-icon" />
                    <strong>Reliable Deliveries</strong> — Work with drivers who
                    are familiar with the routes and can quickly deliver to
                    local buyers.
                  </li>
                  <Divider />
                  <li>
                    <TeamOutlined className="benefit-icon" />
                    <strong>Community Support</strong> — Support local economies
                    by partnering with drivers and teams within the community.
                  </li>
                  <Divider />
                  <li>
                    <LineChartOutlined className="benefit-icon" />
                    <strong>Easy Order Management</strong> — Track and manage
                    all your orders through our platform, from purchase to
                    doorstep.
                  </li>
                </ul>
              </Card>
            </Col>
          </Row>

          {/* How It Works Section */}
          <Title level={2} className="section-title">
            How It Works for Sellers
          </Title>
          <Row
            gutter={[24, 24]}
            style={{ textAlign: "center", marginBottom: "50px" }}
          >
            <Col xs={24} md={12}>
              <Title level={3} className="how-it-works-title">
                Getting Started
              </Title>
              <Card bordered={false} className="how-it-works-card">
                {/* Illustration */}
                <Image
                  src="distribution-center.jpg"
                  alt="Getting Started Illustration"
                  preview={false}
                />
                <ol className="how-it-works-list">
                  <li>Register and Set Up Your Store</li>
                  <Divider />
                  <li>List Products and Set Pricing</li>
                  <Divider />
                  <li>Enable Local Delivery Options</li>
                </ol>
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Title level={3} className="how-it-works-title">
                Manage Your Sales
              </Title>
              <Card bordered={false} className="how-it-works-card">
                {/* Illustration */}
                <Image
                  src="distribution-center2.jpeg"
                  alt="Manage Sales Illustration"
                  preview={false}
                />
                <ol className="how-it-works-list">
                  <li>Track Orders and Shipments</li>
                  <Divider />
                  <li>Respond to Buyer Inquiries</li>
                  <Divider />
                  <li>Get Paid for Every Order</li>
                </ol>
              </Card>
            </Col>
          </Row>

          {/* Call-to-Action Banner */}
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Title level={2} className="section-title">
              Join QikMarket and Grow Locally!
            </Title>
            <Paragraph className="cta-paragraph">
              Become a seller on QikMarket and start reaching more local
              customers with ease.
            </Paragraph>
            <Button type="primary" size="large" style={{ margin: "0 10px" }}>
              <Link to="/supplier/signup">Sign Up as a Seller</Link>
            </Button>
            <Button size="large" style={{ margin: "0 10px" }}>
              <Link to="/">Learn More</Link>
            </Button>
          </div>
          <FAQSection />
        </Content>
      </ScrollButtonWrapper>
    </Layout>
  );
};

export default SupplierLandingPage;
