// src/components/BuyerLogin.js

import { Button, Divider, Form, Input, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { getCookie, setCookie } from "./cookieUtils";

const BuyerLogin = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/buyers-login`,
        values
      );
      setCookie("buyer-token", response.data.token);
      message.success("Login successful");
      if (getCookie("cartItems")) {
        window.location.href = "/checkout";
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      // Handle specific error messages
      const errorMessage = error.response?.data?.message || "Login failed";
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Logo />
      <Form
        name="buyer_login"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="login-form"
      >
        <h1>Buyer Login</h1>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please enter your email" }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Login
          </Button>
        </Form.Item>
        <Divider>Or</Divider>
        <Link to="/buyer/password-reset-request" style={{ color: "orange" }}>
          Password reset
        </Link>
        <Divider>Or</Divider>
        <Link to="/buyer/signup">Create an account as a buyer</Link>
        <Divider>Or</Divider>
        <Link to="/supplier/signup">Create an account as a supplier</Link>{" "}
        {/* Link to Supplier Signup */}
        <Divider> or </Divider>
        <Link to="/supplier/login">Login as a supplier</Link>{" "}
        {/* Link to Supplier Login */}
        <Divider> or </Divider>
        <Link to="/">Home</Link> {/* Link to Home */}
      </Form>
    </>
  );
};

export default BuyerLogin;
