import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Image,
  InputNumber,
  message,
  Modal,
  Row,
  Skeleton,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { getCookie } from "./cookieUtils";

const { Title, Text } = Typography;

const DeliveryForm = ({ visible, onClose, selectedOrder, itemsList }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState(null);
  const [quotationTitle, setQuotationTitle] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [fetchingQuotations, setFetchingQuotations] = useState(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState(null);
  const [numHelpers, setNumHelpers] = useState(1); // New state for number of helpers
  const [quotedItems, setQuotedItems] = useState([]); // New state for quoted items
  // Handle individual item selection
  const handleSelectItem = (item, checked) => {
    if (checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter((i) => i.order_item_id !== item.order_item_id)
      );
    }
  };

  // Handle Select All functionality
  const handleSelectAll = (checked) => {
    setSelectAllChecked(checked);
    if (checked) {
      setSelectedItems(itemsList);
    } else {
      setSelectedItems([]);
    }
  };

  // Fetch delivery options based on provided items and number of helpers
  const fetchDeliveryOptions = async (items, title, helpers) => {
    try {
      if (items.length === 0) {
        message.error("No items selected");
        return;
      }
      setFetchingQuotations(true);
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          "/marketplace/supplier-calculate-order-deliveryprice",
        {
          orderid: selectedOrder.order_id,
          items,
          numHelpers: helpers,
        },
        {
          headers: {
            Authorization: getCookie("supplier-token"),
          },
        }
      );
      setDeliveryOptions(response.data.deliveryOptions);
      setQuotationTitle(title);
      setSelectedDeliveryOption(null); // Reset selected option when new quotes are fetched
      setQuotedItems(items); // Set the quoted items
    } catch (error) {
      message.error("Error fetching delivery options");
    } finally {
      setFetchingQuotations(false);
    }
  };

  // Fetch quotations
  const handleFetchAllItems = () => {
    fetchDeliveryOptions(itemsList, "Quotation for All Items", numHelpers);
  };

  const handleFetchSelectedItems = () => {
    fetchDeliveryOptions(
      selectedItems,
      "Quotation for Selected Items",
      numHelpers
    );
  };

  const handleFetchReadyForCollectionItems = () => {
    const readyItems = itemsList.filter(
      (item) => item.item_status === "ready-for-collection"
    );
    fetchDeliveryOptions(
      readyItems,
      "Quotation for Items Ready for Collection",
      numHelpers
    );
  };

  // Handle delivery option selection
  const handleSelectDeliveryOption = (option) => {
    setSelectedDeliveryOption(option);
  };

  // Confirm the delivery option and save it to marketplace trips
  const confirmDeliveryOption = async () => {
    if (!selectedDeliveryOption) {
      message.error("Please select a delivery option");
      return;
    }

    try {
      await axios.post(
        process.env.REACT_APP_API_URL +
          "/marketplace/supplier-create-trip-schedule",
        {
          order_id: selectedOrder.order_id,
          items: quotedItems,
          deliveryOption: selectedDeliveryOption,
        },
        {
          headers: {
            Authorization: getCookie("supplier-token"),
          },
        }
      );
      message.success("Delivery trip created successfully", 15);
      onClose(); // Close the drawer after confirmation
    } catch (error) {
      message.error("Error creating delivery trip");
    }
  };

  // Table columns
  const columns = [
    {
      title: (
        <Checkbox
          checked={selectAllChecked}
          onChange={(e) => handleSelectAll(e.target.checked)}
        ></Checkbox>
      ),
      render: (text, item) => (
        <Checkbox
          checked={selectedItems.some(
            (selectedItem) => selectedItem.order_item_id === item.order_item_id
          )}
          onChange={(e) => handleSelectItem(item, e.target.checked)}
        />
      ),
    },
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",
      render: (product_id) => product_id.split("-")[0],
    },
    {
      title: "Unit Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Status",
      dataIndex: "item_status",
      key: "item_status",
      render: (itemStatus) => {
        const colorMap = {
          pending: "yellow",
          delivered: "green",
          cancelled: "red",
          "ready-for-collection": "green",
          processed: "orange",
          returned: "orange",
          lost: "purple",
        };
        return (
          <span
            style={{
              backgroundColor: colorMap[itemStatus],
              padding: "2px 8px",
              borderRadius: "4px",
            }}
          >
            {itemStatus}
          </span>
        );
      },
    },
    {
      title: "Collection Date",
      dataIndex: "collection_date",
      key: "collection_date",
      render: (collection_date) => {
        return moment(collection_date).format("YYYY-MMM-ddd DD");
      },
    },
    {
      title: "Weight (kg)",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Length (m)",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "Width (m)",
      dataIndex: "width",
      key: "width",
    },
    {
      title: "Height (m)",
      dataIndex: "height",
      key: "height",
    },
  ];
  const areCollectionDatesUniform = (items) => {
    if (items.length === 0) return false; // No items to compare
    // if any are !isValid return false
    if (items.some((item) => !moment(item.collection_date).isValid())) {
      return false;
    }
    const firstDate = items[0].collection_date;
    return items.every((item) => item.collection_date === firstDate);
  };
  return (
    <Drawer
      title="Delivery Quotation Form"
      placement="left"
      width={"100%"}
      onClose={onClose}
      open={visible}
      afterOpenChange={(open) => {
        if (!open) {
          setSelectedItems([]);
          setSelectAllChecked(false);
          setDeliveryOptions(null);
          setSelectedDeliveryOption(null);
          setNumHelpers(1); // Reset number of helpers
        } else {
          handleFetchReadyForCollectionItems();

          const isUniform = areCollectionDatesUniform(itemsList);

          if (!isUniform) {
            // message.error(
            //   "Selected items have different collection dates. Please ensure all items have the same collection date before proceeding."
            // );
            // show error for 10 seconds
            message.error(
              "ALL items have different collection dates. Please ensure all items have the same collection date before proceeding.",
              10
            );
            return; // Prevent opening the modal
          }
        }
      }}
    >
      <Card style={{ marginBottom: "10px" }}>
        <Title level={4}>Order Summary</Title>
        <p>
          <Text strong>Order ID:</Text> {selectedOrder.order_id}
        </p>
        <p>
          <Text strong>Cart Total:</Text> R{selectedOrder.supplier_total_amount}
        </p>
        <p>
          <Text strong>Items Ready for Collection:</Text> R
          {itemsList
            .filter((item) => item.item_status === "ready-for-collection")
            .reduce(
              (acc, item) => acc + parseFloat(item.price * item.quantity),
              0
            )
            .toFixed(2)}
        </p>

        <div style={{ marginTop: "20px" }}>
          <Text strong>Number of Helpers:</Text>{" "}
          <InputNumber
            min={0}
            max={10}
            value={numHelpers}
            onChange={(value) => setNumHelpers(value)}
          />
          <Button
            type="link"
            onClick={() =>
              fetchDeliveryOptions(
                selectedItems.length > 0 ? selectedItems : itemsList,
                "Updated Quotation",
                numHelpers
              )
            }
          >
            Update Quotation
          </Button>
        </div>

        <p style={{ marginTop: "20px" }}>
          Select the items you want to deliver or choose a predefined option.
        </p>
        <Table
          dataSource={itemsList}
          columns={columns}
          rowKey={(record, index) => `${record.order_item_id}-${index}`}
          pagination={false}
          bordered
        />
      </Card>

      {/* Action buttons for different quotations */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <Button type="primary" onClick={handleFetchAllItems}>
          Get Quotation for All Items
        </Button>
        <Button
          type="primary"
          onClick={handleFetchSelectedItems}
          disabled={selectedItems.length === 0}
        >
          Get Quotation for Selected Items
        </Button>
        <Button type="primary" onClick={handleFetchReadyForCollectionItems}>
          Get Quotation for Ready-for-Collection Items
        </Button>
      </div>

      {/* Display delivery options as side-by-side cards */}
      {deliveryOptions && !fetchingQuotations ? (
        <Card
          title={quotationTitle + " >> " + deliveryOptions[0].totalDistance}
          style={{ marginTop: "20px" }}
        >
          <Row gutter={[16, 16]}>
            {deliveryOptions.map((option, index) => (
              <Col xs={24} sm={12} md={8} lg={6} key={index}>
                <Card
                  hoverable
                  bordered
                  style={{
                    border:
                      selectedDeliveryOption === option
                        ? "2px solid #1890ff"
                        : "1px solid #f0f0f0",
                    position: "relative",
                    height: "100%", // Ensures all cards have equal height
                  }}
                  onClick={() => handleSelectDeliveryOption(option)}
                >
                  <Card.Meta
                    title={
                      <>
                        <Image
                          width={100}
                          src={
                            "https://media.qiktruck.co.za/" +
                            option.vehicleType +
                            ".png"
                          }
                        />
                        {option.vehicleType}
                      </>
                    }
                    description={
                      <>
                        Total Cost: <strong>R{option.totalCost}</strong>
                        <br />
                        Helper Cost: <strong>R{option.helperCost}</strong>
                        <br />
                        Total Amount:{" "}
                        <strong>
                          R
                          {(
                            parseFloat(option.totalCost) +
                            parseFloat(option.helperCost)
                          ).toFixed(2)}
                        </strong>
                        <br />
                        Score: <strong>{option.suitabilityProbability}</strong>
                      </>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
          {/* list all items currently used to generate the qute */}
          <Card style={{ marginTop: "20px", border: "5px solid lightblue" }}>
            <h3 style={{ marginTop: "20px" }} strong>
              Items used to generate the quote:
            </h3>
            {/* {quotedItems.map((item) => (
                <li key={item.order_item_id}>{item.name}</li>
              ))} */}
            <Table
              dataSource={quotedItems}
              //dont show the checkboxes
              columns={columns.slice(1)}
              rowKey={(record, index) => `${record.order_item_id}-${index}`}
              pagination={false}
              bordered
            />
            <div className="floatright" style={{ padding: "20px" }}>
              <strong>Helpers: {numHelpers}</strong>
              <br />
              {/* delivery option summary */}
              <strong>
                {selectedDeliveryOption
                  ? selectedDeliveryOption.vehicleType
                  : ""}
              </strong>
              <br />
              <strong style={{ marginTop: "20px" }}>
                Total Cost: R
                {selectedDeliveryOption ? selectedDeliveryOption.totalCost : 0}
              </strong>
              {!selectedDeliveryOption && (
                <p style={{ color: "red" }}>
                  Please select a delivery option to proceed
                </p>
              )}
              <br />
              <Button
                type="primary"
                style={{ marginTop: "20px" }}
                onClick={() => {
                  // Utility function to check if all collection dates are the same

                  const isUniform = areCollectionDatesUniform(quotedItems);

                  if (!isUniform) {
                    message.error(
                      "Selected items have different collection dates. Please ensure all items have the same collection date before proceeding."
                    );
                    return; // Prevent opening the modal
                  }
                  //  a warning popup to confirm the delivery option
                  Modal.confirm({
                    title: "Confirm Delivery Option",
                    content: (
                      <>
                        <p>
                          You have selected the following delivery option:
                          <br />
                          <strong>{selectedDeliveryOption.vehicleType}</strong>
                        </p>
                        <p>
                          To collect the following items:
                          <br />
                          <ul>
                            {quotedItems.map((item) => (
                              <li key={item.order_item_id}>
                                {item.name} -{" "}
                                {moment(item.collection_date).format(
                                  "YYYY-MMM-ddd DD"
                                )}
                              </li>
                            ))}
                          </ul>
                        </p>
                        <p>
                          Order ID: <strong>{selectedOrder.order_id}</strong>
                        </p>
                        <p>
                          The collection date is set to:{" "}
                          <strong>
                            {moment(quotedItems[0]?.collection_date).format(
                              "YYYY-MMM-ddd DD"
                            )}
                          </strong>
                        </p>
                        <p>
                          <strong>
                            Are you sure you want to proceed with this delivery
                            option?
                          </strong>
                        </p>
                      </>
                    ),
                    onOk: confirmDeliveryOption,
                  });
                }}
                disabled={!selectedDeliveryOption}
                className="orangebg"
              >
                Schedule Delivery{" "}
              </Button>
            </div>
          </Card>
        </Card>
      ) : (
        <>
          {fetchingQuotations && (
            <>
              <p>Loading delivery options...</p>
              <Skeleton active />
            </>
          )}
        </>
      )}
    </Drawer>
  );
};

export default DeliveryForm;
