import { Card, Typography } from "antd";
import React from "react";
import PropTypes from "prop-types";
import CartButton from "./CartButton";
const { Text } = Typography;

function App({ product, showCartButton }) {
  // Navigate to the product page when clicking on the card
  const handleCardClick = () => {
    window.location.href = `/product/?productId=${product.product_id}`;
  };

  // Prevent "Add to Cart" button clicks from triggering the navigation
  const handleCartButtonClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Card
      className="product-card"
      hoverable
      onClick={handleCardClick}
      cover={
        <div
          alt={product.name}
          style={{
            height: "200px",
            width: "100%",
            cursor: "pointer",
            background: `url(${
              product.default_image?.replace(
                ".s3.eu-west-1.amazonaws.com",
                ""
              ) ||
              (product.images.length > 0
                ? product.images[0]?.replace(".s3.eu-west-1.amazonaws.com", "")
                : "https://placehold.co/100")
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      }
    >
      <Card.Meta
        title={
          <>
            <a
              href={`/product/?productId=${product.product_id}`}
              className="product-card-title"
            >
              {product.name}
            </a>
          </>
        }
        description={product.description?.slice(0, 30)}
      />
      <div className="product-card-footer">
        <Text strong>R {product.price}</Text>
      </div>
      {showCartButton && (
        <div onClick={handleCartButtonClick}>
          <CartButton showQuantity={false} product={product} />
        </div>
      )}
    </Card>
  );
}

App.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    default_image: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number.isRequired,
  }).isRequired,
};

export default App;
