import {
  DeleteOutlined,
  OrderedListOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Drawer,
  Image,
  Input,
  List,
  message,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "./cookieUtils";

const { Title } = Typography;
const { confirm } = Modal;

const SupplierDashboard = () => {
  const [supplierData, setSupplierData] = useState(null);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const token = getCookie("supplier-token");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/marketplace/supplier-get-products",
        {
          headers: { Authorization: token },
        }
      );
      setProducts(response.data.products);
      setFilteredProducts(
        response.data.products?.filter((p) => p.published_status !== "deleted")
      );
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const token = getCookie("supplier-token");
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/marketplace/supplier-profile",
          {
            headers: { Authorization: token },
          }
        );
        setSupplierData(response.data);
      } catch (error) {
        console.error("Error fetching supplier data", error);
        message.error("Failed to fetch supplier data.");
      }
    };

    const fetchCategories = async () => {
      try {
        const token = getCookie("supplier-token");
        if (!token) {
          message.error("You must be logged in to fetch categories.");
          return;
        }

        const response = await axios.get(
          process.env.REACT_APP_API_URL +
            "/marketplace/supplier-get-product-categories",
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setCategories(response.data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        const errorMessage =
          error.response?.data?.message || "Failed to load categories";
        message.error(errorMessage);
      }
    };

    fetchCategories();
    fetchSupplierData();
    fetchProducts();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (!value) {
      setFilteredProducts(products);
      return;
    }
    const filtered = products.filter(
      (product) =>
        product.name?.toLowerCase().includes(value.toLowerCase()) ||
        product.sku?.toLowerCase().includes(value.toLowerCase()) ||
        product.product_id?.toLowerCase().includes(value.toLowerCase()) ||
        product.category_id?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const showDrawer = (product) => {
    setSelectedProduct(product);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedProduct(null);
  };

  const handleDelete = (productId) => {
    confirm({
      title: "Are you sure you want to delete this product?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const token = getCookie("supplier-token");
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/marketplace/supplier-delete-product/${productId}`,
            { headers: { Authorization: token } }
          );
          setProducts((prevProducts) =>
            prevProducts.filter((p) => p.product_id !== productId)
          );
          setFilteredProducts((prevFilteredProducts) =>
            prevFilteredProducts.filter((p) => p.product_id !== productId)
          );
          message.success("Product deleted successfully");
          fetchProducts();
        } catch (error) {
          console.error("Error deleting product", error);
          message.error("Failed to delete product");
        }
      },
    });
  };

  // Function to set a default image
  const handleSetDefaultImage = async (productId, imageUrl) => {
    try {
      const token = getCookie("supplier-token");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-set-default-image`,
        { productId, imageUrl },
        { headers: { Authorization: token } }
      );
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.product_id === productId
            ? { ...product, defaultImage: imageUrl }
            : product
        )
      );
      if (selectedProduct && selectedProduct.product_id === productId) {
        setSelectedProduct({ ...selectedProduct, defaultImage: imageUrl });
      }
      message.success("Default image set successfully");
    } catch (error) {
      console.error("Error setting default image", error);
      message.error("Failed to set default image");
    }
  };
  const handlePublish = async (productId, status) => {
    try {
      const token = getCookie("supplier-token");
      await axios.put(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-publish-product/${productId}`,
        {
          status,
        },
        { headers: { Authorization: token } }
      );
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.product_id === productId
            ? { ...product, published: true }
            : product
        )
      );
      message.success("Product published successfully");
    } catch (error) {
      console.error("Error publishing product", error);
      message.error("Failed to publish product");
    }
  };

  const columns = [
    {
      title: (
        <input
          type="checkbox"
          checked={
            selectedProducts.length === filteredProducts.length &&
            filteredProducts.length > 0
          }
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedProducts(
                filteredProducts.map((product) => product.product_id)
              );
            } else {
              setSelectedProducts([]);
            }
          }}
        />
      ),
      dataIndex: "selected",
      key: "selected",
      render: (selected, record) => (
        <input
          type="checkbox"
          checked={selectedProducts.includes(record.product_id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedProducts([...selectedProducts, record.product_id]);
            } else {
              setSelectedProducts(
                selectedProducts.filter((id) => id !== record.product_id)
              );
            }
          }}
        />
      ),
    }, //checkbox
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      render: (images, record) =>
        images && images.length > 0 ? (
          <Image
            src={(record.default_image || images[0])?.replace(
              ".s3.eu-west-1.amazonaws.com",
              ""
            )}
            alt="Product Image"
            width={50}
            height={50}
            style={{ objectFit: "cover" }}
          />
        ) : (
          <div
            style={{
              width: 50,
              height: 50,
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#888",
            }}
          >
            No Image
          </div>
        ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      sorter: (a, b) => a.sku.localeCompare(b.sku),
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "#1890ff",
            cursor: "pointer",
          }}
          onClick={() => showDrawer(record)}
        >
          {text}
        </button>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
      render: (price) => `${price}`,
    },
    {
      title: "Date Added",
      dataIndex: "date_added",
      key: "date_added",
      sorter: (a, b) => a.date_added.localeCompare(b.date_added),
      render: (date_added) =>
        moment(date_added).format("DD/MM/YYYY") +
        " " +
        moment(date_added).diff(moment(), "hours") +
        " hours ago",
    },
    {
      title: "Category",
      dataIndex: "category_id",
      key: "category_id",
      sorter: (a, b) => a.category_id - b.category_id,
      render: (category_id) => {
        const category = categories.find(
          (category) => category.category_id === category_id
        );
        return category ? category.name : "N/A";
      },
    },
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",
      sorter: (a, b) => a.product_id - b.product_id,
      render: (product_id) => product_id?.split("-")[0],
    },

    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/supplier/product-edit/${record.product_id}`}>
            <Button type="link">Edit</Button>
          </Link>
          <Button
            type="link"
            danger
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(record.product_id);
            }}
          >
            <DeleteOutlined />
            {/* if deleted shpow deldted */}
            {record.published_status === "deleted" ? "Deleted" : ""}
          </Button>
          {/* publish */}
          <Button
            type="link"
            className={"publish-button " + record.published_status}
            onClick={(e) => {
              e.stopPropagation();
              console.log("publish");
              handlePublish(
                record.product_id,
                record.published_status === "published"
                  ? "unpublished"
                  : "published"
              );
            }} // handlePublish(record.product_id)
          >
            {record.published_status === "published" ? "Unpublish" : "Publish"}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div className="productlist-options">
          <Title level={3}>Your Products - {supplierData?.business_name}</Title>
          <Button
            type="primary"
            style={{ marginBottom: "20px" }}
            onClick={() => navigate("/supplier/product-create")}
            className="orangebg"
          >
            Sell a Product
          </Button>
          {selectedProducts.length > 0 && ( // if selected products are more than 0
            <Select // dropdown for bulk actions
              placeholder="Bulk Actions"
              style={{ width: 200, margin: "20px 10px" }}
              onSelect={(value) => {
                if (value === "delete") {
                  confirm({
                    title: "Are you sure you want to delete these products?",
                    content: (
                      <>
                        {selectedProducts.filter(
                          // if any of the selected products are published
                          (productId) =>
                            products.find(
                              (product) => product.product_id === productId
                            ).published_status === "published"
                        ).length > 0 ? ( // show warning
                          <span style={{ color: "red" }}>
                            Warning: Some of the selected products are
                            published.
                            <br />
                          </span>
                        ) : (
                          ""
                        )}
                        {selectedProducts.filter(
                          // if any of the selected products are deleted
                          (productId) =>
                            products.find(
                              (product) => product.product_id === productId
                            ).published_status === "deleted"
                        ).length > 0 ? ( // show warning
                          <span style={{ color: "orange" }}>
                            Some of the selected products are deleted.
                            <br />
                          </span>
                        ) : (
                          ""
                        )}
                        <br />
                        <p>
                          You are about to delete {selectedProducts.length}{" "}
                          product(s).
                        </p>
                        <br />
                        <b>Warning:</b> This action cannot be undone.
                      </>
                    ),
                    okText: "Yes, delete all of them",
                    okType: "danger",
                    cancelText: "No",
                    onOk: async () => {
                      try {
                        const token = getCookie("supplier-token");
                        await axios.post(
                          `${process.env.REACT_APP_API_URL}/marketplace/supplier-bulkdelete-products`,
                          { productIds: selectedProducts },
                          { headers: { Authorization: token } }
                        );
                        setProducts((prevProducts) =>
                          prevProducts.filter(
                            (p) => !selectedProducts.includes(p.product_id)
                          )
                        );
                        setFilteredProducts((prevFilteredProducts) =>
                          prevFilteredProducts.filter(
                            (p) => !selectedProducts.includes(p.product_id)
                          )
                        );
                        setSelectedProducts([]);
                        message.success("Products deleted successfully");
                      } catch (error) {
                        console.error("Error deleting products", error);
                        message.error("Failed to delete products");
                      }
                    },
                  });
                } else if (value === "publish") {
                  confirm({
                    title: "Are you sure you want to publish these products?",
                    content: (
                      <>
                        {selectedProducts.filter(
                          (productId) =>
                            products.find(
                              (product) => product.product_id === productId
                            ).published_status === "published"
                        ).length > 0 ? (
                          <b style={{ color: "green" }}>
                            Some of the selected products are already published.
                            <br />
                          </b>
                        ) : (
                          ""
                        )}
                        <br />
                        {selectedProducts.filter(
                          // if any of the selected products are deleted
                          (productId) =>
                            products.find(
                              (product) => product.product_id === productId
                            ).published_status === "deleted"
                        ).length > 0 ? ( // show warning
                          <b style={{ color: "red" }}>
                            Some of the selected products are deleted.
                            <br />
                          </b>
                        ) : (
                          ""
                        )}
                        <br />
                        <p>
                          You are about to publish {selectedProducts.length}{" "}
                          product(s).
                        </p>
                        <br />
                        <b>Warning:</b> This action cannot be undone.
                      </>
                    ),
                    okText: "Yes",
                    okType: "primary",
                    cancelText: "No",
                    onOk: async () => {
                      try {
                        const token = getCookie("supplier-token");
                        await axios.post(
                          `${process.env.REACT_APP_API_URL}/marketplace/supplier-bulkpublish-products`,
                          { productIds: selectedProducts },
                          { headers: { Authorization: token } }
                        );
                        setProducts((prevProducts) =>
                          prevProducts.map((product) =>
                            selectedProducts.includes(product.product_id)
                              ? { ...product, published_status: "published" }
                              : product
                          )
                        );
                        setSelectedProducts([]);
                        message.success("Products published successfully");
                      } catch (error) {
                        console.error("Error publishing products", error);
                        message.error("Failed to publish products");
                      }
                    },
                  });
                } else if (value === "unpublish") {
                  confirm({
                    title: "Are you sure you want to unpublish these products?",
                    content: (
                      <>
                        <p>
                          You are about to unpublish {selectedProducts.length}{" "}
                          product(s).
                        </p>
                        <br />
                        <b>Warning:</b> This action cannot be undone.
                      </>
                    ),
                    okText: "Yes",
                    okType: "primary",
                    cancelText: "No",
                    onOk: async () => {
                      try {
                        const token = getCookie("supplier-token");
                        await axios.post(
                          `${process.env.REACT_APP_API_URL}/marketplace/supplier-bulkunpublish-products`,
                          { productIds: selectedProducts },
                          { headers: { Authorization: token } }
                        );
                        setProducts((prevProducts) =>
                          prevProducts.map((product) =>
                            selectedProducts.includes(product.product_id)
                              ? { ...product, published_status: "unpublished" }
                              : product
                          )
                        );
                        setSelectedProducts([]);
                        message.success("Products unpublished successfully");
                      } catch (error) {
                        console.error("Error unpublishing products", error);
                        message.error("Failed to unpublish products");
                      }
                    },
                  });
                }
              }}
            >
              <Select.Option value="delete">Delete</Select.Option>
              <Select.Option value="publish">Publish</Select.Option>
              <Select.Option value="unpublish">Unpublish</Select.Option>
            </Select>
          )}
          <Button
            style={{
              marginRight: "20px",
              marginLeft: "20px",
            }}
            onClick={() => navigate("/supplier/product-bulkupload")}
            className="floatright"
          >
            <OrderedListOutlined />
            Bulk Upload Products
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            onClick={() => navigate("/supplier/image-bulkupload")}
            className="floatright"
          >
            <OrderedListOutlined />
            Bulk Image Upload
          </Button>
          <Input
            placeholder="Search products"
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={handleSearch}
            style={{ width: "300px", margin: "20px 0" }}
          />
          {/* a filter  for the data */}
          <Select
            placeholder="Filter by Category"
            style={{ width: 200, margin: "20px 0" }}
            onChange={(value) => {
              if (value === "all") {
                setFilteredProducts(products);
              } else {
                const filtered = products.filter(
                  (product) => product.category_id === value
                );
                setFilteredProducts(filtered);
              }
            }}
          >
            <Select.Option value="all">All</Select.Option>
            {categories.map((category) => (
              <Select.Option
                key={category.category_id}
                value={category.category_id}
              >
                {category.name} -{" "}
                {
                  products.filter(
                    (product) => product.category_id === category.category_id
                  ).length
                }
              </Select.Option>
            ))}
          </Select>
          {/* filter by published_status */}
          <Select
            placeholder="Filter by Published Status"
            style={{ width: 200, margin: "20px 0" }}
            onChange={(value) => {
              if (value === "all") {
                setFilteredProducts(products);
              } else {
                const filtered = products.filter(
                  (product) => product.published_status === value
                );
                setFilteredProducts(filtered);
              }
            }}
          >
            <Select.Option value="all">All</Select.Option>
            {[
              ...new Set(products.map((product) => product.published_status)),
            ].map((status) => (
              <Select.Option key={status} value={status}>
                {status} -{" "}
                {
                  products.filter(
                    (product) => product.published_status === status
                  ).length
                }
              </Select.Option>
            ))}
          </Select>
        </div>
        <Table
          columns={columns}
          dataSource={filteredProducts}
          rowKey={(record, index) => `${record.product_id}-${index}`}
          pagination={{ pageSize: 30 }}
          locale={{
            emptyText: loading ? <Spin size="large" /> : <p>No products</p>,
          }}
        />
      </div>

      {/* Drawer for Product Details */}
      <Drawer
        title={selectedProduct ? selectedProduct.name : "Product Details"}
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible} // Updated prop
        width={"60%"}
      >
        {selectedProduct ? (
          <div>
            <Descriptions bordered column={1}>
              {selectedProduct.safety_stock_level !== undefined && (
                <Descriptions.Item label="Safety Stock Level">
                  <Link
                    to={`/product/?productId=${selectedProduct.product_id}`}
                  >
                    Product Link
                  </Link>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Description">
                {selectedProduct.description}
              </Descriptions.Item>
              <Descriptions.Item label="SKU">
                {selectedProduct.sku}
              </Descriptions.Item>
              <Descriptions.Item label="Price">
                {selectedProduct.currency} {selectedProduct.price}
              </Descriptions.Item>
              {selectedProduct.sale_price && (
                <Descriptions.Item label="Sale Price">
                  {selectedProduct.currency} {selectedProduct.sale_price}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Currency">
                {selectedProduct.currency}
              </Descriptions.Item>
              <Descriptions.Item label="Stock">
                {selectedProduct.stock}
              </Descriptions.Item>
              {selectedProduct.reorder_point !== undefined && (
                <Descriptions.Item label="Reorder Point">
                  {selectedProduct.reorder_point}
                </Descriptions.Item>
              )}
              {selectedProduct.safety_stock_level !== undefined && (
                <Descriptions.Item label="Safety Stock Level">
                  {selectedProduct.safety_stock_level}
                </Descriptions.Item>
              )}
              {selectedProduct.lead_time !== undefined && (
                <Descriptions.Item label="Lead Time">
                  {selectedProduct.lead_time} days
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Stock Status">
                {selectedProduct.stock_status}
              </Descriptions.Item>
              {selectedProduct.warehouse_id && (
                <Descriptions.Item label="Warehouse ID">
                  {selectedProduct.warehouse_id}
                </Descriptions.Item>
              )}
              {selectedProduct.max_delivery_radius !== undefined && (
                <Descriptions.Item label="Max Delivery Radius">
                  {selectedProduct.max_delivery_radius} km
                </Descriptions.Item>
              )}
              {selectedProduct.handling_time !== undefined && (
                <Descriptions.Item label="Handling Time">
                  {selectedProduct.handling_time} days
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Local Availability">
                {selectedProduct.local_availability ? "Yes" : "No"}
              </Descriptions.Item>
              {selectedProduct.latitude && selectedProduct.longitude && (
                <Descriptions.Item label="Location">
                  Address: {selectedProduct.address}, Latitude:{" "}
                  {selectedProduct.latitude}, Longitude:{" "}
                  {selectedProduct.longitude}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Category ID">
                {selectedProduct.category_id}
              </Descriptions.Item>
              {selectedProduct.tags && Array.isArray(selectedProduct.tags) && (
                <Descriptions.Item label="Tags">
                  {selectedProduct.tags.join(", ")}
                </Descriptions.Item>
              )}
              {selectedProduct.dimensions && (
                <Descriptions.Item label="Dimensions (cm)">
                  {selectedProduct.dimensions.height}cm (H) ×{" "}
                  {selectedProduct.dimensions.width}cm (W) ×{" "}
                  {selectedProduct.dimensions.length}cm (D)
                </Descriptions.Item>
              )}
              {selectedProduct.weight && (
                <Descriptions.Item label="Weight (Kg)">
                  {selectedProduct.weight} kgs
                </Descriptions.Item>
              )}
              {/* {selectedProduct.attributes && (
                <Descriptions.Item label="Attributes">
                  {selectedProduct.attributes}
                </Descriptions.Item>
              )} */}
              {selectedProduct.bulk_discount && (
                <Descriptions.Item label="Bulk Discount">
                  Quantity: {selectedProduct.bulk_discount.quantity}, Discount:{" "}
                  {selectedProduct.bulk_discount.discount}%
                </Descriptions.Item>
              )}
              {selectedProduct.frequently_bought_with &&
                Array.isArray(selectedProduct.frequently_bought_with) && (
                  <Descriptions.Item label="Frequently Bought With">
                    {selectedProduct.frequently_bought_with.join(", ")}
                  </Descriptions.Item>
                )}
              {/* {selectedProduct.meta && (
                <Descriptions.Item label="Meta">
                  {selectedProduct.meta}
                </Descriptions.Item>
              )} */}
              {selectedProduct.social_links && (
                <Descriptions.Item label="Social Links">
                  {selectedProduct.social_links}
                </Descriptions.Item>
              )}
              {selectedProduct.regulatory_certifications && (
                <Descriptions.Item label="Regulatory Certifications">
                  {selectedProduct.regulatory_certifications}
                </Descriptions.Item>
              )}
              {selectedProduct.promo_start && selectedProduct.promo_end && (
                <Descriptions.Item label="Promotion Period">
                  {new Date(selectedProduct.promo_start).toLocaleDateString()} -{" "}
                  {new Date(selectedProduct.promo_end).toLocaleDateString()}
                </Descriptions.Item>
              )}
            </Descriptions>

            {/* Image Gallery with Default Image Selection */}
            <div style={{ marginTop: "20px" }}>
              <Title level={4}>Product Images</Title>
              {selectedProduct.images && selectedProduct.images.length > 0 ? (
                <List
                  grid={{ gutter: 16, column: 3 }}
                  dataSource={selectedProduct.images}
                  renderItem={(imageUrl, index) => (
                    <List.Item key={index}>
                      <Space
                        direction="vertical"
                        align="center"
                        style={{ width: "100%" }}
                      >
                        <Image
                          src={imageUrl?.replace(
                            ".s3.eu-west-1.amazonaws.com",
                            ""
                          )}
                          alt={`Product Image ${index + 1}`}
                          width={100}
                          height={100}
                          style={{ objectFit: "cover" }}
                        />
                        <Button
                          type={
                            selectedProduct.defaultImage === imageUrl
                              ? "primary"
                              : "default"
                          }
                          onClick={() =>
                            handleSetDefaultImage(
                              selectedProduct.product_id,
                              imageUrl
                            )
                          }
                        >
                          {selectedProduct.defaultImage === imageUrl
                            ? "Default Image"
                            : "Set as Default"}
                        </Button>
                      </Space>
                    </List.Item>
                  )}
                />
              ) : (
                <p>No images available for this product.</p>
              )}
            </div>

            {/* Edit Button */}
            <div style={{ textAlign: "right", marginTop: "20px" }}>
              <Link to={`/supplier/product-edit/${selectedProduct.product_id}`}>
                <Button type="primary">Edit Product</Button>
              </Link>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </Drawer>
    </div>
  );
};

export default SupplierDashboard;
